import React from 'react';
import { AutoForm } from '../../../../shared';
import { required, maxCharLength } from '../../../../shared/form/validators';
import * as RuleApi from '../../../../../api/rule';
import { AddRuleGroupApiModel } from '../../../../../api/rule/api-models';
import ApiHelper from '../../../../../helpers/api-helper';
import ToastHelper from '../../../../../helpers/toast-helper';
import { useToasts } from 'react-toast-notifications';
import { RuleGroup } from '../../../../../types/rule-types';
import { toStackedForm } from '../../../../shared/form/auto-form/auto-form-helper';
import { OptionModel } from '../../../../../types/shared-types';
import { AxiosResponse } from 'axios';
import { AutoFormPropertyDefinition } from '../../../../shared/form/auto-form/auto-form-types';

interface AddRuleGroupFormProps {
  onSubmitSuccess: (newRuleGroup: RuleGroup) => void
  allRuleGroups: RuleGroup[]
  onBack: () => void
  hideHeader?: boolean
}

const AddRuleGroupForm: React.FC<AddRuleGroupFormProps> = (props) => {

  const { allRuleGroups, onSubmitSuccess, hideHeader, onBack } = props;

  const toastHelper = new ToastHelper(useToasts());
  const apiHelper = new ApiHelper(toastHelper);

  const getParentOptions = () => {
    return allRuleGroups
      .map(e => {
        const option: OptionModel<number> = { label: e.name, value: e.id };
        return option;
      });
  }

  const fields: AutoFormPropertyDefinition<AddRuleGroupApiModel>[] = [
    { name: 'name', display: 'Name', type: 'TextInput', options: { validators: [required(), maxCharLength(255)] } },
    { name: 'description', display: 'Description', type: 'TextInput', options: { validators: [maxCharLength(255)] } },
    { name: 'parentId', display: 'Parent', type: 'Select', options: { options: getParentOptions() } },
  ];

  return (
    <AutoForm<AddRuleGroupApiModel, AxiosResponse<RuleGroup>>
      onBack={onBack}
      onSubmitPromise={(values) => apiHelper.makeCall(() => RuleApi.addRuleGroup(values), {
        overridedErrorMessageFunc: (e) => e.response?.data?.message ?? 'An error occured'
      })}
      onSubmitSuccess={(r) => onSubmitSuccess(r.data)}
      rows={toStackedForm(fields)}
      header={hideHeader ? undefined : 'Add Rule Group'}
      submitButtonText='Create'
    />
  );
}

export default AddRuleGroupForm;
