import React from 'react';
import { RouteComponentProps, Route } from 'react-router';
import MainPage from './main';
import DetailPage from './detail';

interface AssessmentProps extends RouteComponentProps {
}

const Assessment: React.FC<AssessmentProps> = (props) => {

  const { match: { path } } = props;

  return (
    <React.Fragment>
      <Route path={path} exact component={MainPage} />
      <Route path={`${path}/edit/:id`} component={DetailPage} />
    </React.Fragment>
  );
};


export default Assessment;
