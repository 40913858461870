import React, { useState, useEffect } from 'react';
import { FormikProps } from 'formik';

import { AddBankingWordCategorisationInstanceCommand } from '../../../../../../../api/bank-categorisation/api-models';
import { WordCategorisationModel } from '../../../../../../../types/bank-types';
import WordList from './word-list';
import UpdateModal from './update-modal';
import { nameOf } from '../../../../../../../helpers/object-helper';
import { FormError } from '../../../../../../shared';
import { shouldShowError, safeGetError } from '../../../../../../shared/form/formik/formik-helper';

export interface FormikWordsOrPhrasesProps {
  formikProps: FormikProps<AddBankingWordCategorisationInstanceCommand>
  className?: string
  onModalOpenChange?: (open: boolean) => void
  disabled?: boolean
}

interface FormikWordsOrPhrasesModalState {
  open: boolean
  model?: WordCategorisationModel
}

interface FormikWordsOrPhrasesState {
  modal: FormikWordsOrPhrasesModalState
}

const FormikWordsOrPhrases: React.FC<FormikWordsOrPhrasesProps> = ({ formikProps, className, onModalOpenChange, disabled }) => {

  const fieldName = nameOf<AddBankingWordCategorisationInstanceCommand>('wordsOrPhrases');

  const [state, setState] = useState<FormikWordsOrPhrasesState>({
    modal: { open: false }
  });

  useEffect(() => {
    if (onModalOpenChange) onModalOpenChange(state.modal.open);
  }, [state.modal.open]);

  const handleDelete = (id: string) => {
    const newValue = !formikProps.values.wordsOrPhrases ? [] : [...formikProps.values.wordsOrPhrases];
    const index = newValue.findIndex(p => p.id === id);
    if (index >= 0) newValue.splice(index, 1);
    formikProps.setFieldValue(fieldName, newValue);
  };

  const handleSubmit = (model: WordCategorisationModel) => {
    const newValue = !formikProps.values.wordsOrPhrases ? [] : [...formikProps.values.wordsOrPhrases];
    const index = newValue.findIndex(p => p.id === model.id);
    if (index >= 0) {
      newValue[index] = model;
    } else {
      newValue.push(model);
    }
    formikProps.setFieldValue(fieldName, newValue);
    setState(ps => ({ ...ps, modal: { ...ps.modal, open: false } }));
  };

  return (
    <div className={className}>
      <label>Words Or Phrases</label>
      <WordList
        disabled={disabled || state.modal.open}
        onAdd={() => setState((ps) => ({ ...ps, modal: { open: true, model: undefined } }))}
        onUpdate={(m) => setState((ps) => ({ ...ps, modal: { open: true, model: m } }))}
        model={formikProps.values.wordsOrPhrases ?? []}
        onDelete={handleDelete}
      />
      {state.modal.open && (
        <UpdateModal
          open
          data={state.modal.model}
          onSubmit={handleSubmit}
          onCancel={() => setState(ps => ({ ...ps, modal: { ...ps.modal, open: false } }))}
        />
      )}
      <FormError
        show={shouldShowError(formikProps, fieldName)}
        errorMessage={safeGetError(formikProps, fieldName)}
      />
    </div>
  );
};

export default FormikWordsOrPhrases;
