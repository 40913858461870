import { Dictionary } from '../../types/shared-types';
import { Form } from "../../types/form-types";
import { CentrelinkAnalysisResult } from "../../types/centrelink-types";

export type CentrelinkServiceType = 'Proviso' | 'Basiq' | 'Test' | 'BasiqTest';
export const CentrelinkServiceTypes = ['Proviso', 'Basiq', 'Test', 'BasiqTest'];

export interface LoginToCentrelinkRequest {
  type?: CentrelinkServiceType
  reference?: string
  formValues: Dictionary<string>
  token?: string
  providerId?: number
}

export interface LoginToCentrelinkResponse {
  serviceProvider: CentrelinkServiceType
  token: string
  mfa?: Form
  reference?: string
  providerId?: number
}

export interface DownloadStatementRequest {
    data: string,
    providerId?: number
    token: string
    accounts: number[]
    numberOfDays: number
    reference: string
}

export interface GenerateCisPdfCommand {
  data: CentrelinkAnalysisResult
  accountHolder: string
}