import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';

import WithAdminUser, { withAdminUserProps } from '../../hoc/with-admin-user';
import AddModal from './add-rebuild-job/add-rebuild-job-modal';
import { RebuildProjectionJob } from '../../../types/projection-types';
import DashboardPage from '../../hoc/dashboard-page';
import { addSpacesOnCaps } from '../../../helpers/string-helper';
import WithApiHelper, { WithApiHelperProps } from '../../hoc/with-api-helper';
import Table from './projection-rebuild-job-table';
import * as ProjectionApi from '../../../api/projection';

interface ProjectionManagementProps extends RouteComponentProps, withAdminUserProps, WithApiHelperProps {
}

interface ModalState {
  type: 'add'
  open: boolean
}

interface State {
  reloading?: boolean
  loadingMessage?: string
  jobs: RebuildProjectionJob[]
  modal: ModalState

}

const ProjectionManagement: React.FC<ProjectionManagementProps> = ({ apiHelper, toastHelper }) => {



  const [state, setState] = useState<State>({
    jobs: [],
    reloading: true,
    loadingMessage: undefined,
    modal: { type: 'add', open: false }
  });

  apiHelper.setDefaultOnErrorAction(() => setState(ps => ({ ...ps, loadingMessage: undefined, reloading: false })));

  const reload = () => {
    setState((ps) => ({ ...ps, reloading: true }));
    apiHelper
      .makeCall(() => ProjectionApi.list())
      .then(r => setState((ps) => ({ ...ps, reloading: false, jobs: r.data.results })));
  }

  //On Start
  useEffect(() => reload(), []);

  const handleAddSuccess = (job: RebuildProjectionJob) => {
    toastHelper.success(`${addSpacesOnCaps(job.definition.type)} Rebuild Job Added `);
    setState(ps => {
      const newState = { ...ps };
      newState.modal.open = false;
      newState.jobs.push(job);
      return newState;
    });
  }


  return (
    <React.Fragment>
      <Table
        jobs={state.jobs}
        onAdd={() => setState(ps => ({ ...ps, modal: { open: true, type: 'add' } }))}
        onReload={reload}
        blockingMessage={state.loadingMessage}
        reloading={state.reloading}
      />
      <AddModal
        open={state.modal.type === 'add' && state.modal.open}
        onSubmitSuccess={handleAddSuccess}
        onClose={() => setState((ps) => ({ ...ps, modal: { ...ps.modal, open: false } }))}
      />
    </React.Fragment>
  );
}

export default WithAdminUser(DashboardPage(WithApiHelper(ProjectionManagement)));
