import React from "react";
import { UserRole } from "../../../types/user-types";
import { ChipList, Chip, CardSimple, List, ModalSimple } from "../../shared";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import RoleEditForm from "./role-edit-form";

interface RoleEditListProps {
  roles: UserRole[]
  editMode: boolean
  editingRole?: UserRole
  onReload?: () => void
  loading?: boolean
  submitting?: boolean
  onEditClick: (role: UserRole) => void
  onUpdateSuccess: (value: UserRole) => void
  onCancelEdit: () => void
}

const RoleEditList: React.FC<RoleEditListProps> = (props) => {

  const resolveValueDisplay = (role: UserRole) => {
    if (!role || !role.principals || !role.principals.length) return <Chip label='No Value Set' />;
    return <ChipList chips={role.principals.map(p => ({ label: p }))} />;
  }

  const { roles, submitting, loading, editingRole, editMode, onEditClick, onUpdateSuccess, onCancelEdit, onReload } = props;

  return (
    <div className='role-edit-list'>
      <CardSimple header='Assign Roles'>
        <List
          listItemBorderLeft
          divider
          items={roles}
          onReload={onReload}
          loadingSkeleton={loading}
          loading={submitting}
          noItemsMessage='No Roles Found...'
          primaryTextFunc={(role: UserRole) => role.name}
          valueFunc={resolveValueDisplay}
          listItemsActionsFunc={(role: UserRole) => ([
            { icon: faEdit, toolTip: 'Update', onClick: () => onEditClick(role) }
          ])}
        />
      </CardSimple>
      {editingRole && (
        <ModalSimple open={editMode} onClose={onCancelEdit} header={`${editingRole.name} Users`}>
          <RoleEditForm role={editingRole} onSubmitSuccess={onUpdateSuccess} onBack={onCancelEdit} />
        </ModalSimple>
      )}
    </div>
  )
};

export default RoleEditList;