import axios from 'axios';
import getSettings from '../../settings';
import { AssessmentDetail } from '../../types/assessment-types';
import { AssessmentUpdateModel } from './api-models';

const settings = getSettings();

export const relativeUrls = {
	start: `/start`,
	updateApplicationData: (id: string) => `/${id}/application-data`,
	get: (id: string) => `/${id}`
};

const root = `${settings.baseApiUrl}/assessment`;

export const start = () => {
	return axios.post<string>(`${root}${relativeUrls.start}`);
};

export const updateApplicationData = (id: string, model: AssessmentUpdateModel) => {
	return axios.put<void>(`${root}${relativeUrls.updateApplicationData(id)}`, model);
};

export const get = (id: string) => {
	return axios.get<AssessmentDetail>(`${root}${relativeUrls.get(id)}`);
};