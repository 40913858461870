import React, { useState } from 'react';
import { Formik, FormikProps } from 'formik';

import { BankingTransactionPredicateTypes, BankingTransactionPredicate, BankingTransactionPredicateType } from '../../../../../../../../types/bank-types';
import { FormValidator, FormikSelect, Button, FormikSideEffects, FormikInput, FormikTagInput, Form } from '../../../../../../../shared';
import { required } from '../../../../../../../shared/form/validators';
import { toOptionModels, nameOf } from '../../../../../../../../helpers/object-helper';
import { addSpacesOnCaps } from '../../../../../../../../helpers/string-helper';

interface AddPredicateFormProps {
  onSubmit: (instance: BankingTransactionPredicate) => void
  onCancel: () => void
  className?: string
}

interface FormValues {
  type: BankingTransactionPredicateType
  stringValue1?: string
  stringValue2?: string
  stringArrayValue1?: string[]
  stringArrayValue2?: string[]
  numberValue1?: number
  numberValue2?: number
  dateValue1?: string
  dateValue2?: string
}

interface FormControlState {
  key: keyof FormValues
  label: string
  notHidden?: boolean
  required?: boolean
}

interface AddPredicateFormState {
  formControlState: FormControlState[]
}

const AddPredicateForm: React.FC<AddPredicateFormProps> = (props) => {

  const { onSubmit, onCancel, className } = props;

  const [state, setState] = useState<AddPredicateFormState>({
    formControlState: []
  });

  const optionsValueRequired = (key: keyof FormValues) => {
    return (value: any, values: FormValues, props: any, passedInState: AddPredicateFormState) => {
      const controlState = passedInState.formControlState.find(e => e.key === key);
      return !controlState || !controlState.required ? null : required()(value);
    }
  };


  const validator = new FormValidator<FormValues, AddPredicateFormProps, AddPredicateFormState>({
    fields: [
      { name: 'type', validators: [required()] },
      { name: 'stringValue1', validators: [optionsValueRequired('stringValue1')] },
      { name: 'stringValue2', validators: [optionsValueRequired('stringValue2')] },
      { name: 'stringArrayValue1', validators: [optionsValueRequired('stringArrayValue1')] },
      { name: 'stringArrayValue2', validators: [optionsValueRequired('stringArrayValue2')] },
      { name: 'numberValue1', validators: [optionsValueRequired('numberValue1')] },
      { name: 'numberValue2', validators: [optionsValueRequired('numberValue2')] },
      { name: 'dateValue1', validators: [optionsValueRequired('dateValue1')] },
      { name: 'dateValue2', validators: [optionsValueRequired('dateValue2')] },
    ]
  });

  const handleSubmit = (values: FormValues) => {
    const { type, ...rest } = values;
    onSubmit({ type: type, options: { ...rest } });
  }

  const shouldShow = (key: keyof FormValues) => {
    const formControlState = state.formControlState.find(e => e.key === key);
    return formControlState?.notHidden;
  }

  const getLabel = (key: keyof FormValues) => {
    const formControlState = state.formControlState.find(e => e.key === key);
    return formControlState?.label ?? addSpacesOnCaps(key, true);
  }

  const handleTypeChange = (newType: BankingTransactionPredicateType, formikProps: FormikProps<FormValues>) => {
    if (!newType) return;
    setState((ps) => {
      const newState: AddPredicateFormState = { ...ps, formControlState: [] };
      switch (newType) {
        case 'DescriptionRegex':
          newState.formControlState.push({ key: 'stringValue1', label: 'Expression', notHidden: true, required: true });
          break;
        case 'DescriptionContains':
        case 'DescriptionDoesNotContain':
          newState.formControlState.push({ key: 'stringValue1', label: 'Phrase', notHidden: true, required: true });
          break;
        case 'DescriptionDoesNotContainArray':
        case 'DescriptionContainsArray':
          newState.formControlState.push({ key: 'stringArrayValue1', label: 'Phrases', notHidden: true, required: true });
          break;
        case 'AmountEquals':
        case 'AmountLessThan':
        case 'AmountGreaterThan':
        case 'AmountLessThanOrEqual':
        case 'AmountGreaterThanOrEqual':
          newState.formControlState.push({ key: 'numberValue1', label: 'Value', notHidden: true, required: true });
          break
        case 'AmountBetween':
          newState.formControlState.push({ key: 'numberValue1', label: 'Value 1', notHidden: true, required: true });
          newState.formControlState.push({ key: 'numberValue2', label: 'Value 2', notHidden: true, required: true });
          break;
      }
      return newState;
    });


    //Clear all values and set type as the newType
    const newValues = { ...formikProps.values };
    Object.keys(newValues).forEach(key => {
      if (key === nameOf<FormValues>('type')) {
        (newValues as any)[key] = newType;
      } else {
        (newValues as any)[key] = undefined;
      }
    });

    formikProps.setValues(newValues);
  };

  return (
    <Formik<FormValues>
      initialValues={validator.getInitial()}
      validate={(values) => validator.validate(values, props, state)}
      onSubmit={handleSubmit}
      render={(formikProps) => {
        return (
          <Form className={className} onSubmit={formikProps.handleSubmit}>
            <FormikSelect<FormValues>
              name='type'
              label='Type'
              options={toOptionModels(BankingTransactionPredicateTypes, true)}
              includeEmptyOption
              formikProps={formikProps}
              onChange={(e) => handleTypeChange(e.currentTarget.value as BankingTransactionPredicateType, formikProps)}
            />
            {shouldShow('stringValue1') && (
              <FormikInput<FormValues> name='stringValue1' formikProps={formikProps} label={getLabel('stringValue1')} />
            )}
            {shouldShow('stringValue2') && (
              <FormikInput<FormValues> name='stringValue2' formikProps={formikProps} label={getLabel('stringValue2')} />
            )}
            {shouldShow('stringArrayValue1') && (
              <FormikTagInput<FormValues> name='stringArrayValue1' formikProps={formikProps} label={getLabel('stringArrayValue1')} />
            )}
            {shouldShow('stringArrayValue2') && (
              <FormikTagInput<FormValues> name='stringArrayValue2' formikProps={formikProps} label={getLabel('stringArrayValue2')} />
            )}
            {shouldShow('numberValue1') && (
              <FormikInput<FormValues> type='number' name='numberValue1' formikProps={formikProps} label={getLabel('numberValue1')} />
            )}
            {shouldShow('numberValue2') && (
              <FormikInput<FormValues> type='number' name='numberValue2' formikProps={formikProps} label={getLabel('numberValue2')} />
            )}
            {shouldShow('dateValue1') && (
              <FormikInput<FormValues> type='number' name='dateValue1' formikProps={formikProps} label={getLabel('dateValue1')} />
            )}
            {shouldShow('dateValue2') && (
              <FormikInput<FormValues> type='number' name='dateValue2' formikProps={formikProps} label={getLabel('dateValue2')} />
            )}
            <div>
              <Button className='w-100 mb-2' type='submit' bsVariant='primary' text='Add' />
              <Button className='w-100' bsVariant='secondary' onClick={onCancel} text='Cancel' />
            </div>
            <FormikSideEffects focusInputOnSubmitFail />
          </Form>
        )
      }}
    />
  );
}

export default AddPredicateForm;
