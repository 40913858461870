import React, { useState, useEffect } from 'react';
import { FormikProps } from 'formik';

import { AddBankingWordCategorisationInstanceCommand } from '../../../../../../../api/bank-categorisation/api-models';
import { BankingTransactionPredicateModel } from '../../../../../../../types/bank-types';
import PredicateList from './predicate-list';
import UpdateModal from './update-modal';
import { nameOf } from '../../../../../../../helpers/object-helper';

export interface FormikPredicateBuilderProps {
  formikProps: FormikProps<AddBankingWordCategorisationInstanceCommand>
  className?: string
  disabled?: boolean
  onModalOpenChange?: (open: boolean) => void
}

interface ModalState {
  open: boolean
  predicate?: BankingTransactionPredicateModel
}

export interface FormikPredicateBuilderState {
  modal: ModalState
}

const FormikPredicateBuilder: React.FC<FormikPredicateBuilderProps> = ({ formikProps, className, onModalOpenChange, disabled }) => {

  const [state, setState] = useState<FormikPredicateBuilderState>({
    modal: { open: false }
  });

  useEffect(() => {
    if (onModalOpenChange) onModalOpenChange(state.modal.open);
  }, [state.modal.open]);

  const handleDelete = (id: string) => {
    const newValue = [...formikProps.values.shouldCategorisePredicateGroups];
    const index = newValue.findIndex(p => p.id === id);
    if (index >= 0) {
      newValue.splice(index, 1);
      updateFormikValue(newValue);
    }
  };

  const handleSave = (model: BankingTransactionPredicateModel) => {
    const newValue = [...formikProps.values.shouldCategorisePredicateGroups];
    const index = newValue.findIndex(p => p.id === model.id);
    if (index >= 0) newValue[index] = model;
    else newValue.push(model);
    updateFormikValue(newValue);
    setState((ps) => ({ ...ps, modal: { ...ps.modal, open: false } }));
  };

  const updateFormikValue = (value: BankingTransactionPredicateModel[]) => {
    formikProps.setFieldValue(nameOf<AddBankingWordCategorisationInstanceCommand>('shouldCategorisePredicateGroups'), value);
  }

  return (
    <div className={className}>
      <label>Should Recategorise Logic</label>
      <PredicateList
        onAdd={() => setState((ps) => ({ ...ps, modal: { open: true, predicate: undefined } }))}
        onUpdate={(p) => setState((ps) => ({ ...ps, modal: { open: true, predicate: p } }))}
        model={formikProps.values.shouldCategorisePredicateGroups}
        disabled={disabled}
        onDelete={handleDelete}
      />
      {state.modal.open && (
        <UpdateModal
          open
          data={state.modal.predicate}
          onSave={handleSave}
          onCancel={() => setState(ps => ({ ...ps, modal: { ...ps.modal, open: false } }))}
        />
      )}
    </div>
  );
};

export default FormikPredicateBuilder;
