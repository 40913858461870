import { CurrentUser, UserRoleDefinition } from "../types/user-types";

export const isAdmin = (user?: CurrentUser) => {
  if (!user || !user.roles) return false;
  return user.roles.includes('Administrator') || user.roles.includes('System Administrator');
}

export const isInRole = (user: CurrentUser, roles?: UserRoleDefinition[]) => {
  if (!user) return false; //If no user then false straight away
  if (!roles || !roles.length) return true; //If no roles provided then true because the user can have no roles
  if (!user.roles || !user.roles.length) return false; //false if user has no roles
  return user.roles.some(a => roles.includes(a));
}