import React from 'react';

import BankAccountForm from './bank-account-form';
import { CardSimple } from '../../../../shared';
import { AssessmentBankAccountUpdateModel } from '../../../../../api/assessment-consumer/api-models';


interface AssessmentBankAccountProps {
  onSubmit: (model: AssessmentBankAccountUpdateModel) => void
  className?: string
}

const AssessmentBankAccount: React.FC<AssessmentBankAccountProps> = ({ className, onSubmit }) => {

  return (
    <CardSimple className={className} header='Bank Account Info'>
      <BankAccountForm onSubmit={onSubmit} />
    </CardSimple>
  )
}

export default AssessmentBankAccount;