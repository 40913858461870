import { GetBankLoginFormResponse } from "../../../../../api/bank-consumer/api-models";
import { FormValidation } from "../../../../../types/form-types";
import {
  required,
  maxCharLength,
  minCharLength
} from "../../../../shared/form/validators";
import { capitaliseWords } from "../../../../../helpers/string-helper";
import {
  AutoFormRow,
  AutoFormColumn,
  AutoFormValidator
} from "../../../../shared/form/auto-form/auto-form-types";

export const getAutoFormRows = (formResponse: GetBankLoginFormResponse) => {
  const rows: AutoFormRow[] = [];
  if (formResponse.form.rows) {
    formResponse.form.rows.forEach(r => {
      if (r.columns) {
        const tempColumns: AutoFormColumn[] = [];
        r.columns.forEach(c => {
          if (c.field) {
            tempColumns.push({
              extraSmallSize: c.extraSmallSize,
              smallSize: c.smallSize,
              mediumSize: c.mediumSize,
              largeSize: c.largeSize,
              extraLargeSize: c.extraLargeSize,
              field: {
                name: c.field.name,
                display: capitaliseWords(c.field.label!),
                type: c.field.type as any,
                options: {
                  validators: toValidators(c.field.validations),
                  options: c.field.options,
                  imageData: c.field.imageData
                }
              }
            });
          }
        });
        rows.push({ columns: tempColumns });
      }
    });
  }
  return rows;
};

const toValidators = (bankValidators?: FormValidation[]) => {
  const validators: AutoFormValidator[] = [];
  if (!bankValidators) return validators;
  bankValidators!.forEach(bv => {
    switch (bv.type) {
      case "Required":
        validators.push(required(bv.overriddedErrorMessage));
        break;
      case "MaxCharLength":
        if (bv.numberParam1)
          validators.push(
            maxCharLength(bv.numberParam1, bv.overriddedErrorMessage)
          );
        break;
      case "MinCharLength":
        if (bv.numberParam1)
          validators.push(
            minCharLength(bv.numberParam1, bv.overriddedErrorMessage)
          );
        break;
    }
  });

  return validators;
};
