import React from 'react';

import { Button } from '../../../../../shared';
import { IncomeExpenditureClassificationMaps, IncomeAndExpenditureCategoryType } from '../../../../../../types/bank-types'
import { capitaliseWords } from '../../../../../../helpers/string-helper';

interface IncomeExpenditureClassificationChooserProps {
  onSelect: (categories: IncomeAndExpenditureCategoryType[]) => void
  className?: string
  disabled?: boolean
}

const IncomeExpenditureClassificationChooser: React.FC<IncomeExpenditureClassificationChooserProps> = ({ onSelect, className, disabled }) => {

  return (
    <div className={className}>
      {Object.keys(IncomeExpenditureClassificationMaps).map(key => (
        <Button
          disabled={disabled}
          key={key}
          className='me-2'
          small
          text={capitaliseWords(key)}
          bsVariant='primary'
          onClick={() => onSelect((IncomeExpenditureClassificationMaps as any)[key])}
        />
      ))}
    </div>
  );
}

export default IncomeExpenditureClassificationChooser;