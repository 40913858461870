import React from 'react';

import { AutoForm } from '../../../../shared';
import { required } from '../../../../shared/form/validators';
import { BankProvider } from '../../../../../types/bank-types';
import { AddBankApiModel } from '../../../../../api/bank/api-models';
import * as BankApi from '../../../../../api/bank';

interface LinkToNewBankFormProps {
  provider: BankProvider
  onSubmitSuccess: () => void
  onBack?: () => void
  hideBack?: boolean
}

const LinkToNewBankForm: React.FC<LinkToNewBankFormProps> = (props) => {

  const { provider, onBack, onSubmitSuccess } = props;
  const initialValues: Partial<AddBankApiModel> = {
    providerId: provider.id,
    name: provider.name
  };

  return (
    <AutoForm<AddBankApiModel, any>
      onBack={() => { if (onBack) onBack(); }}
      onSubmitPromise={(value) => BankApi.add(value)}
      onSubmitSuccess={() => onSubmitSuccess()}
      toastOnError
      initialValues={initialValues as AddBankApiModel}
      hiddenFields={[{ name: 'providerId' }]}
      rows={[
        { columns: [{ field: { name: 'name', display: 'Name', type: 'TextInput', options: { validators: [required()] } } }] },
        { columns: [{ field: { name: 'aliases', display: 'Aliases', type: 'TagInput' } }] }
      ]}
    />
  );
}

export default LinkToNewBankForm;
