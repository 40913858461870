import React, { useState } from 'react';
import { RouteComponentProps, Route, Redirect } from 'react-router';
import { faUserTie, faSignOutAlt, faPiggyBank, faCheckCircle, faUserCog, faProjectDiagram, faClipboard, faChalkboardTeacher } from '@fortawesome/free-solid-svg-icons';
import Header from './layout/header';
import Footer from './layout/footer';
import { SideDrawer, ListItem } from '../shared';
import { useAppContext } from '../app';
import NavBar from './dashboard-nav-bar';
import getSettings from '../../settings';
import { SideBarLinkWithComponentProps } from '../shared/side-bar/side-nav-link';

import BankManagementPage from './bank-management';
import UserManagementPage from './user-management';
import RuleManagementPage from './rule-management';
import ProjectionManagementPage from './projection-management';
import AssessmentPage from './assessment';
import ConsumerUiPage from './consumer-ui';

export interface DashboardProps extends RouteComponentProps { }

const Dashboard: React.FC<DashboardProps> = (props) => {
  const [state, setState] = useState({
    navBarOpen: true,
    mobileNavBarOpen: false,
    rightSideBarOpen: false
  });

  const { match: { path }, location: { pathname } } = props;

  const toggleNavBar = () => setState((ps) => ({ ...ps, navBarOpen: !ps.navBarOpen, mobileNavBarOpen: !ps.mobileNavBarOpen }));
  const toggleRightSideBar = () => setState((ps) => ({ ...ps, rightSideBarOpen: !ps.rightSideBarOpen }));

  const { logout, user } = useAppContext();

  if (pathname === '/' || pathname === '/dashboard') return <Redirect to='/dashboard/bank-management' />;

  const settings = getSettings();

  const navLinks: SideBarLinkWithComponentProps[] = [
    { text: 'Bank Management', icon: faPiggyBank, target: `${path}/bank-management`, component: BankManagementPage },
    { text: 'Rule Management', icon: faCheckCircle, target: `${path}/rule-management`, component: RuleManagementPage },
    { text: 'User Management', icon: faUserCog, target: `${path}/user-management`, component: UserManagementPage },
    { text: 'Projections', icon: faProjectDiagram, target: `${path}/projection-management`, component: ProjectionManagementPage },
    { text: 'Assessments', icon: faClipboard, target: `${path}/assessments`, component: AssessmentPage },
  ];

  if (settings.enableConsumerUiTests) {
    navLinks.push({ text: 'UI Tests', icon: faChalkboardTeacher, target: `${path}/consumer-ui`, component: ConsumerUiPage });
  }

  return (
    <React.Fragment>
      <SideDrawer
        position='right'
        open={state.rightSideBarOpen}
        heading='User Settings'
        topContent={(
          <div className='user-info p-3'>
            <ListItem icon={faUserTie} primaryText={!user ? 'Guest' : user.displayName} secondaryText={!user ? undefined : user.email} />
          </div>
        )}
        links={[
          { onClick: logout, text: 'Logout', icon: faSignOutAlt }
        ]}
        onClose={() => setState((ps) => ({ ...ps, rightSideBarOpen: false }))}
      />
      <NavBar
        links={navLinks}
        path={path}
        mobileOpen={state.mobileNavBarOpen}
        open={state.navBarOpen}
        onToggle={toggleNavBar}
      />
      <div className='main-content'>
        <Header
          navBarOpen={state.navBarOpen}
          brandText='Dashies'
          onToggleLeftSideBar={toggleNavBar}
          onToggleRightSideBar={toggleRightSideBar}
        />
        {navLinks.map((route, i) => <Route key={i} path={route.target!} component={route.component} />)}
        <Footer />
      </div>
    </React.Fragment>
  );
}

export default Dashboard;
