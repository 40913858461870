import React from 'react';
import { AutoForm, ModalSimple } from '../../../../shared';
import { BankingWordCategorisationInstanceGroup } from '../../../../../types/bank-types';
import * as BankCategorisationApi from '../../../../../api/bank-categorisation';
import { AddBankingWordCategorisationInstanceGroupCommand } from '../../../../../api/bank-categorisation/api-models';
import { AxiosResponse } from 'axios';
import { required, maxCharLength, numberGreaterThanOrEqual, numberLessThanOrEqual } from '../../../../shared/form/validators';

interface InstanceGroupUpdateModalProps {
  group?: BankingWordCategorisationInstanceGroup
  onSubmitSuccess: (group: BankingWordCategorisationInstanceGroup) => void
  onClose: () => void
  open: boolean
}

const InstanceGroupUpdateModal: React.FC<InstanceGroupUpdateModalProps> = (props) => {

  const { open, group, onSubmitSuccess, onClose } = props;

  if (!open) return null;

  const isAdd = !group;

  const resolveApiCall = (values: AddBankingWordCategorisationInstanceGroupCommand) => {
    return isAdd ? BankCategorisationApi.addWordInstanceGroup(values) :
      BankCategorisationApi.updateWordInstanceGroup(group!.id, values)
  }

  const data: AddBankingWordCategorisationInstanceGroupCommand = {
    name: group?.name ?? '',
    order: group?.order ?? 0
  }

  return (
    <ModalSimple
      open
      onClose={onClose}
      header={!group ? 'Add New Word Category Instance Group' : `Update ${group.name}`}
    >
      <AutoForm<AddBankingWordCategorisationInstanceGroupCommand, AxiosResponse<BankingWordCategorisationInstanceGroup>>
        initialValues={data}
        onSubmitPromise={resolveApiCall}
        onSubmitSuccess={(r) => onSubmitSuccess(r.data)}
        submitButtonText={isAdd ? 'Add' : 'Update'}
        submittingMessage={isAdd ? 'Adding Group...' : 'Updatiung group...'}
        onBack={onClose}
        rows={[
          { columns: [{ field: { type: 'TextInput', name: 'name', display: 'Name', options: { validators: [required(), maxCharLength(255)] } } }] },
          { columns: [{ field: { type: 'NumberInput', name: 'order', display: 'Order', options: { validators: [required(), numberGreaterThanOrEqual(0), numberLessThanOrEqual(100)] } } }] }
        ]}
      />
    </ModalSimple>
  );
}

export default InstanceGroupUpdateModal;
