import React from 'react';
import { List } from '../../../../../../shared'
import { ListProps } from '../../../../../../shared/list/list'

import { BankingTransactionPredicateModel } from '../../../../../../../types/bank-types';
import * as Helper from '../../categorisation-word-helper';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

export interface PredicateListProps {
  model: BankingTransactionPredicateModel[]
  onAdd: () => void
  onUpdate: (predicate: BankingTransactionPredicateModel) => void
  onDelete: (id: string) => void
  disabled?: boolean
}

const PredicateList: React.FC<PredicateListProps> = (props) => {

  const { model, onAdd, onDelete, onUpdate, disabled } = props;


  let sharedListProps: Partial<ListProps<any>> = {
    listItemBorderLeft: true,
    divider: true,
    onAdd: onAdd,
    addTooltip: 'Add New Logic Group',
    disabled: disabled
  };

  //The default if there is no predicate is for the predicate to just run as true
  if (!model.length) {
    return (
      <List
        {...sharedListProps}
        items={['True'] as any}
        primaryTextFunc={m => m}
        secondaryTextFunc={() => 'No logic currently'}
      />
    )
  }

  return (
    <List
      {...sharedListProps}
      listItemKey='id'
      items={model}
      primaryTextFunc={(m, i) => {
        let predicateString = Helper.predicatesToString([m]);
        const isLast = i === model.length - 1;
        if (!isLast) predicateString = `${predicateString} ${m.after}`;
        return predicateString;
      }}
      listItemsActionsFunc={(m) => [
        { icon: faEdit, toolTip: 'Update', onClick: () => onUpdate(m), variant: 'transparent', small: true },
        { icon: faTrash, toolTip: 'Delete', onClick: () => onDelete(m.id), variant: 'transparent', small: true }
      ]}
    />
  );
};

export default PredicateList;
