import React from 'react';
import { FormSkeleton } from '../../../../shared';

interface LoginFormLoadingProps { }

const LoginFormLoading: React.FC<LoginFormLoadingProps> = () => {
  return (
    <FormSkeleton
      hasHeader
      wrapInCard
      rows={[
        { columns: [{ extraSmallSize: 12 }] },
        { columns: [{ extraSmallSize: 12 }] }
      ]}
      footerButtonCount={2}
    />
  );
}

export default LoginFormLoading;
