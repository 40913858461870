import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { faSync, faEdit, faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';
import DashboardPage from '../../../hoc/dashboard-page';
import { RuleInstance, RuleInstanceOptions } from '../../../../types/rule-types';
import * as RuleApi from '../../../../api/rule';
import { DataTableAction } from '../../../shared/table/data-table-actions';
import { DataTable } from '../../../shared';
import AddRuleInstanceModal from './add-rule-instance/add-rule-instance-modal'
import UpdateRuleInstanceModal from './update-rule-instance/update-rule-instance-modal'
import WithApiHelper, { WithApiHelperProps } from '../../../hoc/with-api-helper';

interface RuleInstancePageModalProps {
  type: 'update' | 'delete' | 'add'
  open: boolean
  ruleInstance?: RuleInstance
}

interface RuleInstanceState {
  ruleInstances: RuleInstance[]
  ruleInstanceOptions: RuleInstanceOptions
  pageLoaded: boolean
  loadingMessage?: string
  modal: RuleInstancePageModalProps
}

interface RuleInstanceProps extends RouteComponentProps, WithApiHelperProps {
}

const RuleInstances: React.FC<RuleInstanceProps> = ({ apiHelper, toastHelper }) => {

  const reloadMessage = 'Loading Rule Groups...';

  const [state, setState] = useState<RuleInstanceState>({
    ruleInstances: [],
    ruleInstanceOptions: { ruleDefinitions: [], ruleGroups: [] },
    pageLoaded: false,
    loadingMessage: reloadMessage,
    modal: { type: 'add', open: false }
  });

  apiHelper.setDefaultOnErrorAction(() => setState((ps) => ({ ...ps, loadingMessage: undefined })));

  //Reload actions
  const reload = () => {
    return apiHelper.makeCall(() => RuleApi.listRuleInstances(), { skipDefaultOnErrorAction: true })
      .then(response => Promise.resolve(response.data));
  }

  const reloadAndSetState = () => {
    setState((ps) => ({ ...ps, loadingMessage: reloadMessage }));
    reload().then(ruleInstances => setState((ps) => ({ ...ps, loadingMessage: undefined, ruleInstances: ruleInstances.results })))
  }

  //On Start
  useEffect(() => {

    async function init() {
      try {
        const ruleInstancesResponse = await reload();
        const ruleInstanceOptionsResponse = await apiHelper.makeCall(() => RuleApi.getRuleInsanceOptions(), { skipDefaultOnErrorAction: true });
        setState((ps) => ({ ...ps, pageLoaded: true, loadingMessage: undefined, ruleInstanceOptions: ruleInstanceOptionsResponse.data, ruleInstances: ruleInstancesResponse.results }));
      }
      catch {
        setState((ps) => ({ ...ps, pageLoaded: true, loadingMessage: undefined }));
      }
    };

    init();
  }, []);

  const getRowActions = () => {
    const actions: DataTableAction<RuleInstance>[] =
      [{
        icon: faEdit,
        variant: 'transparent',
        onClick: (d) => setState((ps) => ({ ...ps, modal: { open: true, ruleInstance: d, type: 'update' } })),
        tooltip: 'Update'
      },
      {
        icon: faTrash,
        variant: 'transparent',
        onClick: (d) => handleDelete(d.id),
        tooltip: 'Delete',
        options: { isConfirm: true, modalHeader: 'Are you Sure You Want to Delete?', modalNoText: 'Cancel', modalYesText: 'Delete' }
      }];

    return actions;
  }

  const handleAddRuleInstanceClick = () => {
    setState((ps) => ({ ...ps, modal: { open: true, type: 'add' } }));
  }

  const handleAddSuccess = (addedRuleInstance: RuleInstance) => {
    toastHelper.success(`${addedRuleInstance.name} successfully created`);
    setState(ps => {
      const newState = { ...ps };
      newState.modal.open = false;
      newState.ruleInstances.push(addedRuleInstance);
      return newState;
    });
  }

  const handleDelete = (id: number) => {
    setState((ps) => ({ ...ps, loadingMessage: 'Deleting Rule Instance...' }))
    apiHelper.makeCall(() => RuleApi.deleteRuleInstance(id))
      .then(r => {
        toastHelper.success('Rule Instance Deleted!');
        setState(ps => {
          const newState: RuleInstanceState = { ...ps, loadingMessage: undefined };
          newState.modal.open = false;
          const index = newState.ruleInstances.findIndex(e => e.id === id);
          if (index >= 0) newState.ruleInstances.splice(index, 1);
          return newState;
        });
      });
  }

  const handleUpdateSuccess = (updatedRuleInstance: RuleInstance) => {
    toastHelper.success(`${updatedRuleInstance.name} successfully updated`);
    setState(ps => {
      const newState = { ...ps };
      newState.modal.open = false;
      const index = newState.ruleInstances.findIndex(e => e.id === updatedRuleInstance.id);
      if (index >= 0) newState.ruleInstances[index] = updatedRuleInstance;
      return newState;
    });
  }

  return (
    <React.Fragment>
      <DataTable
        loading={state.loadingMessage !== undefined}
        pageOptions={{ itemsPerPage: 10 }}
        toolbarProps={{
          actionButtons: [
            { key: 'reload', icon: faSync, toolTip: 'Reload', onClick: () => reloadAndSetState() },
            { key: 'add', icon: faPlus, toolTip: 'Add Rule Instance', onClick: () => handleAddRuleInstanceClick() }
          ],
        }}
        data={!state.ruleInstances.length ? [] : [...state.ruleInstances]}
        dataIdProperty='id'
        columns={[
          { key: 'id', header: 'Id' },
          { key: 'name', header: 'Name', searchable: true },
          { key: 'description', header: 'Description', searchable: true },
          { key: 'ruleGroupId', header: 'Rule Group', renderCell: (r) => r.ruleGroup.name, searchable: true, compareValue: (r) => r.ruleGroup.name }
        ]}
        actionsFunc={getRowActions}
      />
      <AddRuleInstanceModal
        options={state.ruleInstanceOptions}
        open={state.modal.type === 'add' && state.modal.open}
        onSubmitSuccess={handleAddSuccess}
        onClose={() => setState((ps) => ({ ...ps, modal: { ...ps.modal, open: false } }))}
      />
      <UpdateRuleInstanceModal
        open={state.modal.type === 'update' && state.modal.open}
        onSubmitSuccess={handleUpdateSuccess}
        ruleInstance={state.modal.ruleInstance!}
        options={state.ruleInstanceOptions}
        onClose={() => setState((ps) => ({ ...ps, modal: { ...ps.modal, open: false } }))}
      />

    </React.Fragment>
  );
}

export default DashboardPage(WithApiHelper(RuleInstances));
