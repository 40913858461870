import React from 'react';
import { BaseFormField } from "../../../../types/shared-types";
import { RuleDefinitionType, RuleDefinitionData } from "../../../../types/rule-types";
import { UpdateRuleInstanceApiModel } from "../../../../api/rule/api-models";
import { FormikProps } from "formik";
import { FormikInput } from "../../../shared";
import { addSpacesOnCaps } from "../../../../helpers/string-helper";

export const mapParamsToInitialValues = (initialValues: UpdateRuleInstanceApiModel) => {
  if(!initialValues.parameters) return;
  const jsonParams = JSON.parse(initialValues.parameters);
  //Dump Parameters onto the api Model to make editable
  Object.keys(jsonParams).forEach(key => (initialValues as any)[key] = jsonParams[key]);
}

export const setNewParamsForRuleInstance = (values: UpdateRuleInstanceApiModel, formFields: BaseFormField[]) => {
  if(!formFields || !formFields.length) return;
  const newParams: any = {};
  formFields.forEach(f => newParams[f.name] = (values as any)[f.name]);
  values.parameters = JSON.stringify(newParams);
}

//The key of the dictionary should be the RuleDefinitionType
export const getFormFieldsForRuleDefinition = (type: RuleDefinitionType, ruleDefinitions: RuleDefinitionData[]) => {
  const data = ruleDefinitions.find(e => e.type === type);
  return !data ? [] : data.formFields || [];
}

export const renderParamFormField = (formField: BaseFormField, formikProps: FormikProps<any>) => {
  const sharedProps = {
    key: formField.name,
    formikProps: formikProps,
    name: formField.name,
    label: formField.label || addSpacesOnCaps(formField.name)
  };

  // TODO investigate support for more types. ie date
  switch(formField.type) {
    case 'NumberInput': return <FormikInput {...sharedProps} type='number' />;
    default: return <FormikInput {...sharedProps} />
  };
};