import React from 'react';
import { RouteComponentProps } from 'react-router';
import DashboardPage from '../../hoc/dashboard-page';
import AssessmentTable from './assessment-table';
import { AssessmentSummary } from '../../../types/assessment-types';

interface AssessmentMainPageProps extends RouteComponentProps {
}

const AssessmentMainPage: React.FC<AssessmentMainPageProps> = ({ history, match: { path } }) => {

  const handleEdit = (assessment: AssessmentSummary) => {
    history.push(`${path}/edit/${assessment.assessmentId}`);
  }

  return (
    <AssessmentTable onEdit={handleEdit} />
  );
}

export default DashboardPage(AssessmentMainPage);
