import React from 'react';
import { Row, Col } from 'reactstrap';
import { FormTagDisplay } from '../../../../../shared';
import { TestBankTransactionResult } from '../../../../../../api/bank-categorisation/api-models';
import { money } from '../../../../../../helpers/display-helper';
import { BankRecategoriseAction } from '../../../../../../types/bank-types';
import { addSpacesOnCaps } from '../../../../../../helpers/string-helper';
import { faClipboardList, faMoneyBill, faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons';

interface TestTransactionResultViewProps {
  result: TestBankTransactionResult
  className?: string
}

const TestTransactionResultView: React.FC<TestTransactionResultViewProps> = ({ result, ...rest }) => {

  const hasRecategoriseActions = result.reCategoriseActions?.length ? true : false;

  const renderRecategoriseAction = (action: BankRecategoriseAction, border: boolean, key?: number) => {

    const hasInnerRecategoriseActions = action.otherMatches?.length ? true : false;
    return (
      <div className={border ? 'border-left-primary' : 'ms-3'} key={key}>
        <Row>
          <Col xs={6}><FormTagDisplay className='mb-2' label='From' tags={[{ label: addSpacesOnCaps(action.from) }]} /> </Col>
          <Col xs={6}><FormTagDisplay className='mb-2' label='To' tags={[{ label: addSpacesOnCaps(action.to), variant: 'success' }]} /> </Col>
        </Row>
        <Row>
          <Col xs={6}><FormTagDisplay className='mb-2' label='Engine' tags={[{ label: action.engineName }]} /> </Col>
          <Col xs={6}><FormTagDisplay className='mb-2' label='Score' tags={[{ label: action.score?.toString() }]} /> </Col>
        </Row>
        {hasInnerRecategoriseActions && (
          <Row className='mt-2'>
            <Col xs={12}>
              <h5>Unsuccessful Matches</h5>
              <hr />
              {action.otherMatches.map(((om, i) => (
                <React.Fragment>
                  {renderRecategoriseAction(om, false, i)}
                  {(i !== result.reCategoriseActions.length - 1) && <hr />}
                </React.Fragment>
              )))}
            </Col>
          </Row>
        )}
      </div>
    )
  }

  return (
    <div {...rest}>
      <Row>
        <Col xs={12}><FormTagDisplay className='mb-2' label='New Category' tags={[{ label: result.category, variant: 'success' }]} /></Col>
      </Row>
      <Row>
        <Col xs={12}><FormTagDisplay className='mb-2' label='Transaction' tags={[
          { icon: faClipboardList, label: result.description, variant: 'info' },
          { icon: faMoneyBill, label: money(result.amount) },
          { icon: result.transactionType === 'Credit' ? faPlusCircle : faMinusCircle, label: result.transactionType, variant: result.transactionType === 'Credit' ? 'success' : 'danger' },
        ]} />
        </Col>
      </Row>
      {hasRecategoriseActions && (
        <Row className='mt-2'>
          <Col xs={12}>
            <h5>Matched On</h5>
            {result.reCategoriseActions.map((a, i) => (
              <React.Fragment>
                {renderRecategoriseAction(a, true, i)}
                {(i !== result.reCategoriseActions.length - 1) && <hr />}
              </React.Fragment>
            ))}
          </Col>
        </Row>
      )}
    </div>
  );
}

export default TestTransactionResultView;
