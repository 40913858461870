import { get } from 'js-cookie';

export interface AppSettingsProps {
  appUrl: string
  baseApiUrl: string
  authClientId: string
  authAuthority: string
  graphAuthority: string
  authScopeUrl: string
  enableConsumerUiTests: boolean
  localCypressTestEnabled: boolean
  enableFortunaV2: string
}

export const cypressTestCookieName = 'Dashies-Cypress-Test';

const getAppSettings = () => {
  let appSettings: AppSettingsProps = {
    localCypressTestEnabled: get(cypressTestCookieName) ? true : false,
    graphAuthority: 'https://graph.microsoft.com',
    enableConsumerUiTests: true,
    ...{} as any
  };
  if (process.env.NODE_ENV === 'development') {
    const devAppUrl = appSettings.localCypressTestEnabled ? 'http://localhost:3000' : 'http://localhost:5000';
    appSettings.appUrl = devAppUrl;
    appSettings.baseApiUrl = `${devAppUrl}/api`;
    appSettings.authClientId = '1d1d1ff8-421c-41f9-a063-c6c5b1b7fe5a';
    appSettings.authAuthority = 'https://login.microsoftonline.com/eb2e9916-19a4-4566-84f3-5a6da1f55beb';
    appSettings.authScopeUrl = 'https://ccdev.onmicrosoft.com/fortuna-dev-api-ase/user_impersonation';
    appSettings.enableConsumerUiTests = true;
    appSettings.enableFortunaV2 = 'true';
  }
  else {
    appSettings.appUrl = '#{AppUrl}';
    appSettings.baseApiUrl = '#{BaseApiUrl}';
    appSettings.authClientId = '#{AuthClientId}';
    appSettings.authAuthority = '#{AuthAuthority}';
    appSettings.authScopeUrl = '#{AuthScopeUrl}';
    appSettings.enableFortunaV2 = '#{EnableFortunaV2}';
  }
  return appSettings;
}


export default getAppSettings;