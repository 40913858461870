import { BankingTransactionPredicateModel, BankingTransactionPredicateJoiner, BankingTransactionPredicate, WordCategorisationModel, BankingWordCategorisationInstance, BankingWordCategorisationInstanceGroup, BankingCategorisationDataSnapshot } from '../../../../../types/bank-types';
import * as StringHelper from '../../../../../helpers/string-helper';
import * as ObjectHelper from '../../../../../helpers/object-helper';
import { OptionModel } from '../../../../../types/shared-types';
import { AddBankingWordCategorisationInstanceCommand, AddBankingWordCategorisationInstanceGroupCommand } from '../../../../../api/bank-categorisation/api-models';

interface PredicateStringModel {
  string: string
  after: BankingTransactionPredicateJoiner
}

//Converts the structured predicate models into a readable string
export const predicatesToString = (models: BankingTransactionPredicateModel[]) => {
  if (!models?.length) return 'True';

  //Convert each individual predicate model into the string model DTO
  const stringModels = models
    .map(predicateModeltoStringModel)
    .filter(e => e) as PredicateStringModel[];

  //Build up the joined string models into an array of strings
  const parts: string[] = [];
  stringModels.forEach((stringModel, i) => {
    parts.push(stringModel.string);
    const isLast = i === stringModels.length - 1;
    if (!isLast) parts.push(stringModel.after);
  });

  //Join them all together
  return parts.join(' ');
}

const predicateModeltoStringModel = (model: BankingTransactionPredicateModel) => {
  if (!model?.predicates?.length) return null;
  const parts: string[] = [];
  model.predicates.forEach((p, i) => {
    const predicateString = predicateToString(p);
    if (predicateString) {
      parts.push(predicateString);
      const isLast = i === model.predicates.length - 1;
      if (!isLast) parts.push('AND');
    }
  });
  let stringValue = parts.join(' ');
  stringValue = model.predicates.length > 1 ? `(${stringValue})` : stringValue;
  const predicateStringModel: PredicateStringModel = {
    string: stringValue,
    after: model.after
  };

  return predicateStringModel;
}

export const predicateToString = (predicate: BankingTransactionPredicate) => {
  switch (predicate.type) {
    case 'DescriptionRegex': return `[Description] REGEX(${predicate.options.stringValue1})`;
    case 'DescriptionContains': return `[Description] CONTAINS ${predicate.options.stringValue1}`;
    case 'DescriptionDoesNotContain': return `[Description] DOESN'T CONTAIN ${predicate.options.stringValue1}`;
    case 'DescriptionDoesNotContainArray': return `[Description] DOESN'T CONTAIN ARRAY (${(predicate.options.stringArrayValue1 ?? []).join(', ')})`;
    case 'DescriptionContainsArray': return `[Description] CONTAINS ARRAY (${(predicate.options.stringArrayValue1 ?? []).join(', ')})`;
    case 'AmountEquals': return `[Amount] == (${ObjectHelper.isEmpty(predicate.options?.numberValue1) ? '' : StringHelper.toCurrency(predicate.options?.numberValue1!)})`;
    case 'AmountLessThan': return `[Amount] < (${ObjectHelper.isEmpty(predicate.options?.numberValue1) ? '' : StringHelper.toCurrency(predicate.options?.numberValue1!)})`;
    case 'AmountGreaterThan': return `[Amount] > (${ObjectHelper.isEmpty(predicate.options?.numberValue1) ? '' : StringHelper.toCurrency(predicate.options?.numberValue1!)})`;
    case 'AmountLessThanOrEqual': return `[Amount] <= (${ObjectHelper.isEmpty(predicate.options?.numberValue1) ? '' : StringHelper.toCurrency(predicate.options?.numberValue1!)})`;
    case 'AmountGreaterThanOrEqual': return `[Amount] >= (${ObjectHelper.isEmpty(predicate.options?.numberValue1) ? '' : StringHelper.toCurrency(predicate.options?.numberValue1!)})`;
    case 'AmountBetween': {
      const number1 = ObjectHelper.isEmpty(predicate.options?.numberValue1) ? '' : StringHelper.toCurrency(predicate.options?.numberValue1!);
      const number2 = ObjectHelper.isEmpty(predicate.options?.numberValue2) ? '' : StringHelper.toCurrency(predicate.options?.numberValue2!);
      return `[Amount] BETWEEN ${number1} & ${number2}`;
    }
    default: return StringHelper.addSpacesOnCaps(predicate.type, true);
  }
}

export const wordModelToString = (model: WordCategorisationModel, showScore: boolean = false) => {
  if (!model) return '';
  const parts: OptionModel<string>[] = [];
  if (model.include?.length) parts.push({ label: 'Include', value: model.include.join(', ') });
  if (model.exclude?.length) parts.push({ label: 'Exclude', value: model.exclude.join(', ') });
  parts.push({ label: 'Ignore Numbers', value: !model.ignoreMatchIfHasNumbers ? 'False' : 'True' });
  parts.push({ label: 'Individual Words', value: !model.matchOnIndividualWords ? 'False' : 'True' });
  if (showScore) parts.push({ label: 'Score', value: (model.score ?? 0).toString() });
  return parts.map(p => `${p.label}: ${p.value}`).join(' | ');
}

export const toBankingCategorisationDataSnapshot = (instances: BankingWordCategorisationInstance[], groups: BankingWordCategorisationInstanceGroup[]) => {
  const snapshot: BankingCategorisationDataSnapshot = {
    instances: instances.map<AddBankingWordCategorisationInstanceCommand>(i => ({
      groupName: i.group?.name ?? '',
      name: i.name,
      recategoriseTo: i.recategoriseTo,
      shouldCategorisePredicateGroups: i.shouldCategorisePredicateGroups,
      categoriesChecked: i.categoriesChecked,
      transactionType: i.transactionType,
      wordsOrPhrases: i.wordsOrPhrases,
    })),
    groups: groups.map<AddBankingWordCategorisationInstanceGroupCommand>(g => ({
      name: g.name,
      order: g.order
    }))
  };

  return snapshot;
} 