import React from 'react';
import { Formik, FormikHelpers } from 'formik';

import { BlockUi, FormikSideEffects, FormValidator, Button, Form } from '../../../../shared';
import { Bank } from '../../../../../types/bank-types';
import { UpdateBankWeightingsApiModel } from '../../../../../api/bank/api-models';
import * as BankApi from '../../../../../api/bank';
import FormFields, { getValidators } from './bank-weighting-form-fields';
import { FormValidatorField } from '../../../../shared/form/form-validator';
import WithApiHelper, { WithApiHelperProps } from '../../../../hoc/with-api-helper';


interface UpdateBankWeightingsFormProps extends WithApiHelperProps {
  bank: Bank
  onSubmitSuccess: (bank: Bank) => void
  onBack: () => void
  hideHeader?: boolean
}

const UpdateBankWeightingsForm: React.FC<UpdateBankWeightingsFormProps> = (props) => {

  const { bank, onSubmitSuccess, hideHeader, onBack, apiHelper } = props;

  const handleSubmit = (values: UpdateBankWeightingsApiModel, formikHelpers: FormikHelpers<any>) => {
    return apiHelper.makeCall(() => BankApi.updateProviderWeightings(bank.id, values))
      .then((r) => onSubmitSuccess(r.data))
      .finally(() => formikHelpers.setSubmitting(false));
  }

  //Build out weightings form based on number of providers and initital values
  const initialValues: UpdateBankWeightingsApiModel = { weightings: [] };
  let fields: FormValidatorField<any, any>[] = [];
  bank.providers.forEach((e, i) => {
    fields = [...fields, ...getValidators(i)];
    initialValues.weightings.push({ type: e.type, weighting: e.weighting });
  });
  const validator = new FormValidator({ fields: fields });

  return (
    <Formik<UpdateBankWeightingsApiModel>
      initialValues={validator.getInitial(initialValues)}
      onSubmit={handleSubmit}
      validate={(v) => validator.validate(v, props)}
      render={(formikProps) => {
        return (
          <React.Fragment>
            {!hideHeader && <h4>{`Update ${bank.name} Weightings`}</h4>}
            <Form className='update-bank-provider-weightings-form' onSubmit={formikProps.handleSubmit}>
              <BlockUi blocking={formikProps.isSubmitting} text='Submitting...'>
                {bank.providers.map((e, i) => <FormFields key={i} index={i} formikProps={formikProps} />)}
                <div>
                  <Button className='w-100 mb-2' type='submit' bsVariant='primary'>Update</Button>
                  <Button className='w-100' type='button' bsVariant='secondary' onClick={onBack}>Back</Button>
                </div>
              </BlockUi>
              <FormikSideEffects focusInputOnSubmitFail />
            </Form>
          </React.Fragment>
        )
      }}
    />
  );
}

export default WithApiHelper(UpdateBankWeightingsForm);
