import React, { useEffect } from "react";
import { CurrentUser } from "../../types/user-types";
import * as UserService from "../../services/user-service";
import { useAppContext } from "../app";
import { goToErrorPage } from "../error";
import { useHistory } from "react-router-dom";

export interface withAdminUserProps {
  user: CurrentUser;
}

function WithAdminUser<P extends withAdminUserProps>(
  Component: React.ComponentType<P>
) {
  return function WithUserComponent(
    props: Pick<P, Exclude<keyof P, keyof withAdminUserProps>>
  ) {
    const { user } = useAppContext();
    const routeHistory = useHistory();

    useEffect(() => {
      if (!UserService.isAdmin(user)) {
        goToErrorPage(routeHistory, "Oops", "Unauthorised Access");
      }
    }, []);

    return <Component user={user!} {...(props as P)} />;
  };
}

export default WithAdminUser;
