import React, { useState, useEffect } from 'react';
import { useToasts } from 'react-toast-notifications';
import { AxiosResponse } from 'axios';

import * as BankConsumerApi from '../../../../../api/bank-consumer';
import { LoginToBankRequest, GetBankLoginFormResponse, LoginToBankResponse } from '../../../../../api/bank-consumer/api-models';
import { ConsumerBank } from '../../../../../types/bank-types';
import { Alert, AutoForm } from '../../../../shared';
import ApiHelper from '../../../../../helpers/api-helper';
import ToastHelper from '../../../../../helpers/toast-helper';
import { Dictionary, ResultWrapper } from '../../../../../types/shared-types';

import * as Helpers from './login-form-helpers';
import LoginFormError from './login-form-error';
import LoginFormLoading from './login-form-loading';

interface LoginFormProps {
  bank: ConsumerBank
  reference: string
  onBack: () => void
  onSuccess: (response: LoginToBankResponse) => void
}

interface FormData {
  formResponse?: GetBankLoginFormResponse
  error?: string
  success?: boolean
}

const LoginForm: React.FC<LoginFormProps> = ({ bank, reference, onBack, onSuccess }) => {

  const [formData, setFormData] = useState<FormData>({ formResponse: undefined, success: true });

  const toastHelper = new ToastHelper(useToasts());
  const apiHelper = new ApiHelper(toastHelper);

  const reload = () => {
    apiHelper.makeCall(() => BankConsumerApi.getLoginForm(bank.id, reference))
      .then(r => setFormData({ formResponse: r.data }))
      .catch(r => setFormData({ error: 'An error occured loading form' }));
  }
  useEffect(() => {
    reload();
  }, []);


  const handleSubmit = (formValues: any) => {
    const mappedFormValues: Dictionary<string> = {};
    Object.keys(formValues).forEach(key => {
      mappedFormValues[key] = formValues[key];
    });

    const request: LoginToBankRequest = {
      formValues: mappedFormValues,
      bankProviderId: formData.formResponse!.bankProviderId,
      token: formData.formResponse!.form.token,
      reference: reference
    };

    return BankConsumerApi.login(bank.id, request);
  }

  const handleSuccess = (response: AxiosResponse<ResultWrapper<LoginToBankResponse>>) => {
    if (response.data.success) {
      setFormData((ps) => ({ ...ps, success: true, error: undefined }));
      onSuccess(response.data.data!);
    } else {
      toastHelper.error(response.data.error || 'An error occured.  Please try again');
    }
  }

  if (formData.error) return <LoginFormError error={formData.error} onReload={reload} onBack={onBack} />;
  if (!formData.error && !formData.formResponse) return <LoginFormLoading />;
  if (formData.success) return <Alert variant='success' center message='Login Successful!' />

  return (
    <AutoForm
      variant='inside-card'
      submittingMessage='Logging in...'
      formId='bank-login'
      onSubmitPromise={handleSubmit}
      onSubmitSuccess={handleSuccess}
      onSubmitError={() => toastHelper.error('An error occured.  Please try again')}
      submitButtonText='Login'
      rows={Helpers.getAutoFormRows(formData.formResponse!)}
      headerCentre
      header={bank.name}
      onBack={onBack}
    />
  );
}

export default LoginForm;
