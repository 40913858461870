import React, { useState } from 'react';
import { faEdit, faTrash, faDownload, faVial } from '@fortawesome/free-solid-svg-icons';

import { DataTable, Button } from '../../../../shared';
import { BankingWordCategorisationInstance } from '../../../../../types/bank-types';
import ViewMoreDetailsModal, { ViewMoreDetailsType } from './view-more-details-modal';
import { addSpacesOnCaps } from '../../../../../helpers/string-helper';

interface InstanceTableProps {
  loading: boolean
  blockingMessage?: string
  instances: BankingWordCategorisationInstance[]
  onAdd: () => void
  onDelete: (id: number) => void
  onDownloadJson: () => void
  onTestTransaction: () => void
  onEdit: (instance: BankingWordCategorisationInstance) => void
  onReload: () => void
}

interface ModalState {
  open: boolean
  type: ViewMoreDetailsType
  data?: BankingWordCategorisationInstance
}

interface State {
  modal: ModalState
}

const InstanceTable: React.FC<InstanceTableProps> = (props) => {

  const { loading, blockingMessage, instances, onAdd, onDelete, onDownloadJson, onEdit, onReload, onTestTransaction } = props;

  const [state, setState] = useState<State>({
    modal: { open: false, type: 'WordsOrPhrases' }
  });

  const renderWordsOrPhrasesCell = (instance: BankingWordCategorisationInstance) => {
    if (!instance.wordsOrPhrases?.length) {
      return 'No Words Or Phrases';
    }
    return (
      <div className='d-flex justify-content-center'>
        <Button
          text='View'
          small
          bsVariant='primary'
          onClick={() => setState(ps => ({ ...ps, modal: { open: true, type: 'WordsOrPhrases', data: instance } }))}
        />
      </div>
    );
  }

  const renderCategoriesChecked = (instance: BankingWordCategorisationInstance) => {
    if (!instance.categoriesChecked?.length) {
      return 'All Categories';
    }
    return (
      <div className='d-flex justify-content-center'>
        <Button
          text='View'
          small
          bsVariant='primary'
          onClick={() => setState(ps => ({ ...ps, modal: { open: true, type: 'CategoriesChecked', data: instance } }))}
        />
      </div>
    );
  }

  return (
    <React.Fragment>
      <DataTable
        small
        loading={loading}
        blocking={blockingMessage !== undefined}
        blockingMessage={blockingMessage}
        toolbarProps={{
          onAdd: onAdd,
          onReload: onReload,
          actionButtons: [
            { key: 'download-json', icon: faDownload, toolTip: 'Download As JSON File', onClick: onDownloadJson },
            { key: 'test-transaction', icon: faVial, toolTip: 'Test Transaction', onClick: onTestTransaction },
          ],
        }}
        data={!instances.length ? [] : [...instances]}
        dataIdProperty='id'
        columns={[
          { key: 'name', header: 'Name', searchable: true, filters: [{ type: 'StringContains' }] },
          { key: 'groupId', header: 'Group', searchable: true, filters: [{ type: 'StringContains' }], compareValue: (r) => r?.group?.name, renderCell: (r) => r?.group?.name },
          { key: 'recategoriseTo', header: 'Recategorise To', searchable: true, compareValue: (r) => addSpacesOnCaps(r.recategoriseTo), renderCell: (r) => addSpacesOnCaps(r.recategoriseTo), filters: [{ type: 'StringContains' }] },
          { key: 'transactionType', header: 'Debit / Credit', filters: [{ type: 'StringContains' }] },
          { key: 'categoriesChecked', header: 'Categories Checked', filters: [{ type: 'StringContains' }], compareValue: (r) => r.categoriesChecked?.join(' '), renderCell: renderCategoriesChecked },
          { key: 'wordsOrPhrases', header: 'Words Or Phrases', renderCell: renderWordsOrPhrasesCell },
        ]}
        actions={[
          {
            icon: faEdit,
            variant: 'transparent',
            onClick: onEdit,
            tooltip: 'Update'
          },
          {
            icon: faTrash,
            variant: 'transparent',
            onClick: (i) => onDelete(i.id),
            tooltip: 'Delete',
            options: { isConfirm: true, modalHeader: 'Are You Sure?', modalBodyText: 'Are you sure you want to delete this instance?', modalYesText: 'Delete', modalNoText: 'Cancel' }
          }
        ]}
      />
      <ViewMoreDetailsModal
        type={state.modal.type}
        open={state.modal.open}
        instance={state.modal.data}
        onClose={() => setState(ps => ({ ...ps, modal: { ...ps.modal, open: false } }))}
      />
    </React.Fragment>
  );
}

export default InstanceTable;
