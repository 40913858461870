import React, { useState } from 'react';
import { FormikSideEffects, FormikInput, FormValidator, FormikSelect, BlockUi, Button, Form } from '../../../../shared';
import { toOptionModels, toOptionModelsWithPredicate } from '../../../../../helpers/object-helper';
import { required, number, maxCharLength } from '../../../../shared/form/validators';
import * as RuleApi from '../../../../../api/rule';
import { UpdateRuleInstanceApiModel } from '../../../../../api/rule/api-models';
import ApiHelper from '../../../../../helpers/api-helper';
import ToastHelper from '../../../../../helpers/toast-helper';
import { useToasts } from 'react-toast-notifications';
import { RuleInstance, RuleInstanceOptions, RuleDefinitionTypes, RuleStatuses, RuleDefinitionType } from '../../../../../types/rule-types';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { BaseFormField } from '../../../../../types/shared-types';
import * as Helper from '../rule-instance-helper';
import { FormValidatorField } from '../../../../shared/form/form-validator';

interface UpdateRuleInstanceFormProps {
  ruleInstance: RuleInstance
  options: RuleInstanceOptions
  onSubmitSuccess: (newRuleInstance: RuleInstance) => void
  onBack: () => void
  hideHeader?: boolean
}

interface RuleDefinitionState {
  paramFormFields: BaseFormField[]
}

//Custom validator for rule definition params
const requiredIfDefinitionSelected = (definition: RuleDefinitionType) => {
  return (value: any, values: UpdateRuleInstanceApiModel): string | null => {
    if (values.ruleDefinitionType !== definition) return null;
    return required()(value);
  };
}

const UpdateRuleInstanceForm: React.FC<UpdateRuleInstanceFormProps> = (props) => {

  const { ruleInstance, options: { ruleGroups, ruleDefinitions }, onSubmitSuccess, hideHeader, onBack } = props;

  const [state, setState] = useState<RuleDefinitionState>({
    paramFormFields: Helper.getFormFieldsForRuleDefinition(ruleInstance.ruleDefinitionType, ruleDefinitions),
  });

  const toastHelper = new ToastHelper(useToasts());
  const apiHelper = new ApiHelper(toastHelper);

  const validator = new FormValidator<UpdateRuleInstanceApiModel, UpdateRuleInstanceFormProps>({
    fields: [
      { name: 'name', validators: [required(), maxCharLength(255)] },
      { name: 'description', validators: [maxCharLength(255)] },
      { name: 'passedOutcomeMessageFormat', validators: [required(), maxCharLength(255)] },
      { name: 'failedOutcomeMessageFormat', validators: [required(), maxCharLength(255)] },
      { name: 'ruleDefinitionType', validators: [required()] },
      { name: 'status', validators: [required()] },
      { name: 'ruleGroupId', validators: [required()] },
    ]
  });


  //Put every param option as a field in the validator so formik knows how to validate if chosen
  ruleDefinitions.forEach(data => {
    if (data.formFields) {
      data.formFields.forEach((formField) => {
        const validatorField: FormValidatorField<UpdateRuleInstanceApiModel> = {
          name: formField.name as any,
          validators: [requiredIfDefinitionSelected(data.type)]
        };
        if (formField.type === 'NumberInput') validatorField.validators?.push(number());
        validator.fields.push(validatorField);
      });
    }
  });

  let initialValues: UpdateRuleInstanceApiModel = {
    name: ruleInstance.name,
    description: ruleInstance.description,
    passedOutcomeMessageFormat: ruleInstance.passedOutcomeMessageFormat,
    failedOutcomeMessageFormat: ruleInstance.failedOutcomeMessageFormat,
    ruleDefinitionType: ruleInstance.ruleDefinitionType,
    parameters: ruleInstance.parameters,
    status: ruleInstance.status,
    ruleGroupId: ruleInstance.ruleGroupId
  };

  Helper.mapParamsToInitialValues(initialValues);

  const handleSubmit = (values: UpdateRuleInstanceApiModel, formikHelpers: FormikHelpers<any>) => {
    Helper.setNewParamsForRuleInstance(values, state.paramFormFields);

    return apiHelper.makeCall(() => RuleApi.updateRuleInstance(ruleInstance.id, values), {
      overridedErrorMessageFunc: (e) => e.response?.data?.message ?? 'An error occured'
    })
      .then((r) => onSubmitSuccess(r.data))
      .finally(() => formikHelpers.setSubmitting(false));
  }

  const handleRuleDefinitionChange = (event: React.ChangeEvent<HTMLInputElement>, formikProps: FormikProps<any>) => {
    setState((ps: RuleDefinitionState) => {
      const newState: RuleDefinitionState = { ...ps };
      newState.paramFormFields = Helper.getFormFieldsForRuleDefinition(event.target.value as RuleDefinitionType, ruleDefinitions);

      //Set the new params values to undefined
      newState.paramFormFields.forEach(e => formikProps.setFieldValue(e.name, undefined));

      return newState;
    });
  }

  return (
    <Formik<UpdateRuleInstanceApiModel>
      initialValues={validator.getInitial(initialValues)}
      onSubmit={handleSubmit}
      validate={(v) => validator.validate(v, props)}
      render={(formikProps) => {
        return (
          <React.Fragment>
            {!hideHeader && <h4>Add Rule Instance</h4>}
            <Form className='update-rule-instance-form' onSubmit={formikProps.handleSubmit}>
              <BlockUi blocking={formikProps.isSubmitting} text='Submitting...'>
                <FormikInput<UpdateRuleInstanceApiModel>
                  label='Name'
                  name='name'
                  formikProps={formikProps}
                />
                <FormikInput<UpdateRuleInstanceApiModel>
                  label='Description'
                  name='description'
                  formikProps={formikProps}
                />
                <FormikInput<UpdateRuleInstanceApiModel>
                  label='Success Message'
                  name='passedOutcomeMessageFormat'
                  formikProps={formikProps}
                />
                <FormikInput<UpdateRuleInstanceApiModel>
                  label='Failed Message'
                  name='failedOutcomeMessageFormat'
                  formikProps={formikProps}
                />
                <FormikSelect<UpdateRuleInstanceApiModel>
                  label='Rule Type'
                  name='ruleDefinitionType'
                  formikProps={formikProps}
                  options={toOptionModels(RuleDefinitionTypes, true)}
                  onChange={(e) => handleRuleDefinitionChange(e, formikProps)}
                />
                {state.paramFormFields.map((p) => Helper.renderParamFormField(p, formikProps))}
                <FormikSelect<UpdateRuleInstanceApiModel>
                  label='Rule Group'
                  name='ruleGroupId'
                  formikProps={formikProps}
                  options={toOptionModelsWithPredicate(ruleGroups, (rg) => ({ label: rg.name, value: rg.id }))}
                />
                <FormikSelect<UpdateRuleInstanceApiModel>
                  label='Status'
                  name='status'
                  formikProps={formikProps}
                  options={toOptionModels(RuleStatuses)}
                />
                <div>
                  <Button className='w-100 mb-2' type='submit' bsVariant='primary'>Update</Button>
                  <Button className='w-100' bsVariant='secondary' onClick={onBack}>Back</Button>
                </div>
              </BlockUi>
              <FormikSideEffects focusInputOnSubmitFail />
            </Form>
          </React.Fragment>
        )
      }}
    />
  );
}

export default UpdateRuleInstanceForm;
