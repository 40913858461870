import { MsalAuthProvider, LoginType } from 'react-aad-msal';
import getSettings from '../settings';
import { Configuration, AuthenticationParameters } from 'msal';

const settings = getSettings();

const config: Configuration = {
  auth: {
    authority: settings.authAuthority,
    clientId: settings.authClientId,
    validateAuthority: false,
    redirectUri: settings.appUrl,
    postLogoutRedirectUri: settings.appUrl,
    navigateToLoginRequestUrl: false
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
  framework: {
    isAngular: false
  }
};

export const authParams: AuthenticationParameters = {
  scopes: [
    settings.authScopeUrl,
  ],
  extraScopesToConsent: [
    'https://graph.microsoft.com/User.Read',
    'https://graph.microsoft.com/User.ReadBasic.All',
  ],
  authority: settings.authAuthority
};

export const authProvider = new MsalAuthProvider(config, authParams, {
  loginType: LoginType.Redirect,
  tokenRefreshUri: settings.appUrl
});