import React, { useState } from 'react';
import { v4 } from 'uuid';
import { BankingTransactionPredicateModel, BankingTransactionPredicate, BankingTransactionPredicateJoiners } from '../../../../../../../../types/bank-types';
import { List, Button, ModalSimple, Alert, FormSelect } from '../../../../../../../shared';
import AddPredicateForm from './add-predicate-form';
import * as Helper from '../../../categorisation-word-helper';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { toOptionModels } from '../../../../../../../../helpers/object-helper';

interface PredicateBuilderUpdateModalProps {
  onSave: (instance: BankingTransactionPredicateModel) => void
  onCancel: () => void
  data?: BankingTransactionPredicateModel
  open: boolean
}

interface FormikKeywordInstancePredicateBuilderUpdateState {
  model: BankingTransactionPredicateModel
  uiState: 'View' | 'Add'
}
const PredicateBuilderUpdateModal: React.FC<PredicateBuilderUpdateModalProps> = (props) => {

  const { data, onSave, onCancel, open } = props;

  const [state, setState] = useState<FormikKeywordInstancePredicateBuilderUpdateState>({
    model: {
      id: data?.id ?? v4(),
      predicates: data?.predicates ?? [],
      after: data?.after ?? 'AND'
    },
    uiState: 'View'
  });

  const handleAdd = (predicate: BankingTransactionPredicate) => {

    setState((ps) => {
      const newState: FormikKeywordInstancePredicateBuilderUpdateState = { ...ps, uiState: 'View' };
      newState.model.predicates.push(predicate);
      return newState;
    });
  }

  const handleDelete = (predicate: BankingTransactionPredicate) => {
    setState((ps) => {
      const newState: FormikKeywordInstancePredicateBuilderUpdateState = { ...ps };
      const index = newState.model.predicates.findIndex(e => e.type === predicate.type);
      if (index >= 0) {
        newState.model.predicates.splice(index, 1);
      }
      return newState;
    });
  }

  const handleAddClick = () => setState(ps => ({ ...ps, uiState: 'Add' }));

  const resolveHeader = () => {
    if (state.uiState === 'Add') return 'Add Logic Rule';
    return !data ? 'Add New Logic Group' : 'Update Logic Group';
  };

  if (!open) return null;
  const hasPredicates = state.model.predicates?.length ? true : false;

  return (
    <ModalSimple
      onClose={onCancel}
      open
      header={resolveHeader()}
    >
      {state.uiState === 'View' && (
        <React.Fragment>
          {hasPredicates && (
            <React.Fragment>
              <List
                className='mb-2'
                divider
                listItemBorderLeft
                addTooltip='Add Logic Rule'
                onAdd={handleAddClick}
                items={state.model.predicates}
                primaryTextFunc={p => Helper.predicateToString(p)}
                listItemsActionsFunc={(p) => [
                  { icon: faTrash, toolTip: 'Delete', onClick: () => handleDelete(p), variant: 'transparent', small: true }
                ]}
              />
              <FormSelect
                label='Logic After Joiner'
                className='mt-4'
                name='predicateAfter'
                value={state.model.after}
                options={toOptionModels(BankingTransactionPredicateJoiners)}
                onChange={(e) => setState(ps => ({ ...ps, model: { ...ps.model, after: e.currentTarget.value as any } }))}
              />
              <Button className='w-100 mb-2' text='Save' bsVariant='primary' onClick={() => onSave(state.model)} />
            </React.Fragment>
          )}
          {!hasPredicates && (
            <React.Fragment>
              <Alert variant='info' center message='No Rules Currently' />
              <Button className='w-100 mb-2' text='Add Logic Rule' bsVariant='primary' onClick={handleAddClick} />
            </React.Fragment>
          )}
          <Button className='w-100' text='Cancel' bsVariant='secondary' onClick={onCancel} />
        </React.Fragment>

      )}
      {state.uiState === 'Add' && (
        <AddPredicateForm
          onSubmit={handleAdd}
          onCancel={() => setState((ps) => ({ ...ps, uiState: 'View' }))}
        />
      )}
    </ModalSimple>

  );
}

export default PredicateBuilderUpdateModal;
