import React from 'react';
import { AutoForm } from '../../../../shared';
import { required, maxCharLength } from '../../../../shared/form/validators';
import * as RuleApi from '../../../../../api/rule';
import { UpdateRuleGroupApiModel } from '../../../../../api/rule/api-models';
import ApiHelper from '../../../../../helpers/api-helper';
import ToastHelper from '../../../../../helpers/toast-helper';
import { useToasts } from 'react-toast-notifications';
import { RuleGroup } from '../../../../../types/rule-types';
import { OptionModel } from '../../../../../types/shared-types';
import { AxiosResponse } from 'axios';
import { toStackedForm } from '../../../../shared/form/auto-form/auto-form-helper';
import { AutoFormPropertyDefinition } from '../../../../shared/form/auto-form/auto-form-types';

interface UpdateRuleGroupFormProps {
  ruleGroup: RuleGroup
  allRuleGroups: RuleGroup[]
  onSubmitSuccess: (updateRuleGroup: RuleGroup) => void
  onBack: () => void
  hideHeader?: boolean
}

const UpdateRuleGroupForm: React.FC<UpdateRuleGroupFormProps> = (props) => {

  const { ruleGroup, allRuleGroups, onSubmitSuccess, hideHeader, onBack } = props;

  const toastHelper = new ToastHelper(useToasts());
  const apiHelper = new ApiHelper(toastHelper);

  const getParentOptions = () => {
    return allRuleGroups
      .filter(e => e.id !== ruleGroup.id)
      .filter(e => e.parentId !== ruleGroup.id)
      .map(e => {
        const option: OptionModel<number> = { label: e.name, value: e.id };
        return option;
      });
  }

  const fields: AutoFormPropertyDefinition<UpdateRuleGroupApiModel>[] = [
    { name: 'name', display: 'Name', type: 'TextInput', options: { validators: [required(), maxCharLength(255)] } },
    { name: 'description', display: 'Description', type: 'TextInput', options: { validators: [maxCharLength(255)] } },
    { name: 'parentId', display: 'Parent', type: 'Select', options: { options: getParentOptions(), defaultValue: () => ruleGroup.parentId } },
  ];

  return (
    <AutoForm<UpdateRuleGroupApiModel, AxiosResponse<RuleGroup>>
      onBack={onBack}
      onSubmitPromise={(values) => apiHelper.makeCall(() => RuleApi.updateRuleGroup(ruleGroup.id, values), {
        overridedErrorMessageFunc: (e) => e.response?.data?.message ?? 'An error occured'
      })}
      onSubmitSuccess={(r) => onSubmitSuccess(r.data)}
      rows={toStackedForm(fields)}
      initialValues={ruleGroup}
      header={hideHeader ? undefined : 'Update Rule Group'}
      submitButtonText='Update'
    />
  );
}

export default UpdateRuleGroupForm;
