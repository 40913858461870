import React from 'react';

import { ModalSimple } from '../../../../shared';
import { BankProvider } from '../../../../../types/bank-types';
import LinkToBankForm from './link-to-bank-container';

interface LinkToBankModalProps {
  provider: BankProvider
  onSubmitSuccess: () => void
  open: boolean
  onClose: () => void
}

const LinkToBankModal: React.FC<LinkToBankModalProps> = (props) => {

  const { open, onClose, ...rest } = props;

  if (!open) return null;

  return (
    <ModalSimple
      open
      onClose={onClose}
      header={`Link Provider to Bank`}
    >
      <LinkToBankForm
        hideHeader
        {...rest}
        onBack={onClose}
      />
    </ModalSimple>
  );
}

export default LinkToBankModal;
