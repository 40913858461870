import React, { useState } from 'react';
import { ModalSimple } from '../../../../../shared';
import { TestBankTransactionResult } from '../../../../../../api/bank-categorisation/api-models';
import { } from '../../../../../../api/bank-categorisation/api-models';
import TestTransactionForm from './test-transaction-form';
import ResultsView from './test-transaction-results-view';

interface TestTransactionModalProps {
  open: boolean
  onClose: () => void
}

interface State {
  results: TestBankTransactionResult[]
}

const TestTransactionModal: React.FC<TestTransactionModalProps> = (props) => {

  const { open, onClose, ...rest } = props;

  const [state, setState] = useState<State>({
    results: []
  });

  if (!open) return null;

  const hasResults = state.results.length ? true : false;

  return (
    <ModalSimple
      {...rest}
      open
      onClose={onClose}
      header='Test Transaction'
    >
      {!hasResults && (
        <TestTransactionForm
          onBack={onClose}
          onSubmitSuccess={(r) => setState((ps) => ({ ...ps, results: r }))}
        />
      )}
      {hasResults && (
        <ResultsView
          results={state.results}
          onBack={() => setState(ps => ({ ...ps, results: [] }))}
        />
      )}
    </ModalSimple>
  );
}

export default TestTransactionModal;
