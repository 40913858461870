import axios from 'axios';
import getSettings from '../../settings'
import { AddRuleGroupApiModel, UpdateRuleGroupApiModel, ListRuleGroupsFilter, AddRuleInstanceApiModel, UpdateRuleInstanceApiModel, ListRuleInstancesFilter } from './api-models';
import { RuleGroup, RuleInstance, RuleInstanceOptions } from '../../types/rule-types';
import { ResultSet } from '../../types/shared-types';

const settings = getSettings();

export const relativeUrls = {
  addRuleGroup: '/group',
  updateRuleGroup: (id: number) => `/group/${id}`,
  listRuleGroup: '/group',
  deleteRuleGroup: (id: number) => `/group/${id}`,
  addRuleInstance: '/instance',
  updateRuleInstance: (id: number) => `/instance/${id}`,
  listRuleInstance: '/instance',
  getRuleInsanceOptions: '/instance/options',
  deleteRuleInstance: (id: number) => `/instance/${id}`
}

const root = `${settings.baseApiUrl}/admin/rule`;

export const addRuleGroup = (model: AddRuleGroupApiModel) => {
  return axios.post<RuleGroup>(`${root}${relativeUrls.addRuleGroup}`, model);
};

export const updateRuleGroup = (id: number, model: UpdateRuleGroupApiModel) => {
  return axios.put<RuleGroup>(`${root}${relativeUrls.updateRuleGroup(id)}`, model);
};

export const listRuleGroups = (filter?: ListRuleGroupsFilter) => {
  return axios.get<ResultSet<RuleGroup>>(`${root}${relativeUrls.listRuleGroup}`, { params: filter });
};

export const deleteRuleGroup = (id: number) => {
  return axios.delete<void>(`${root}${relativeUrls.deleteRuleGroup(id)}`, { params: id });
};

export const addRuleInstance = (model: AddRuleInstanceApiModel) => {
  return axios.post<RuleInstance>(`${root}${relativeUrls.addRuleInstance}`, model);
};

export const updateRuleInstance = (id: number, model: UpdateRuleInstanceApiModel) => {
  return axios.put<RuleInstance>(`${root}${relativeUrls.updateRuleInstance(id)}`, model);
};

export const listRuleInstances = (filter?: ListRuleInstancesFilter) => {
  return axios.get<ResultSet<RuleInstance>>(`${root}${relativeUrls.listRuleInstance}`, { params: filter });
};

export const deleteRuleInstance = (id: number) => {
  return axios.delete<void>(`${root}${relativeUrls.deleteRuleInstance(id)}`, { params: id });
};

export const getRuleInsanceOptions = () => {
  return axios.get<RuleInstanceOptions>(`${root}${relativeUrls.getRuleInsanceOptions}`);
};