import React from 'react';
import { ModalSimple, Alert } from '../../../../../shared';
import UpdateKeywordCategorisationForm from './update-form'
import { BankingWordCategorisationInstance, BankingWordCategorisationInstanceOptions } from '../../../../../../types/bank-types';

interface UpdateKewordCategorisationModalProps {
  onSubmitSuccess: (instance: BankingWordCategorisationInstance) => void
  instance?: BankingWordCategorisationInstance
  options: BankingWordCategorisationInstanceOptions
  open: boolean
  onClose: () => void
}

const UpdateKewordCategorisationModal: React.FC<UpdateKewordCategorisationModalProps> = (props) => {

  const { instance, open, onClose, options, ...rest } = props;

  if (!open) return null;

  const hasRuleGroups = options?.groups?.length ? true : false;
  return (
    <ModalSimple
      open
      onClose={onClose}
      header={!instance ? 'Add New Word Category Instance' : `Update ${instance.name}`}
    >
      {!hasRuleGroups && (
        <Alert variant='warning' message='No Instance Groups found.  Add Some groups before adding/updating instances' />
      )}
      {hasRuleGroups && (
        <UpdateKeywordCategorisationForm
          instance={instance}
          groups={options.groups}
          hideHeader
          {...rest}
          onBack={onClose}
        />
      )}

    </ModalSimple>
  );
}

export default UpdateKewordCategorisationModal;
