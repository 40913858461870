import React from "react";
import { AxiosResponse } from "axios";

import { UserRole } from "../../../types/user-types";
import { AutoForm } from "../../shared";
import * as RoleApi from "../../../api/role";
import { AdSearchResult } from "../../shared/form/form-ad-people-picker";

interface RoleEditFormProps {
  onSubmitSuccess: (value: UserRole) => void
  onBack: () => void
  role: UserRole
}

interface RoleEditFormValues {
  principals: AdSearchResult[]
}

const RoleEditForm: React.FC<RoleEditFormProps> = (props) => {

  const { onBack, onSubmitSuccess, role } = props;

  const initial: RoleEditFormValues = {
    principals: !role.principals ? [] : role.principals.map(e => {
      const search: AdSearchResult = { displayName: '', identifier: e, type: 'user' };
      return search;
    })
  };

  return (
    <AutoForm<RoleEditFormValues, AxiosResponse<UserRole>>
      initialValues={initial}
      onSubmitPromise={(v) => RoleApi.update(role.name, { principals: !v.principals ? [] : v.principals.map(e => e.identifier) })}
      onSubmitSuccess={(r) => onSubmitSuccess(r.data)}
      rows={[
        { columns: [{ field: { type: 'AdPeoplePicker', name: 'principals', display: 'Users' } }] }
      ]}
      onBack={onBack}
    />
  );
};

export default RoleEditForm;