import React from 'react';
import { AutoForm } from '../../../../../shared';
import { BankTransactionTypes } from '../../../../../../types/bank-types';
import { TestBankTransactionRequest, TestBankTransactionResult } from '../../../../../../api/bank-categorisation/api-models';
import * as BankCategorisationApi from '../../../../../../api/bank-categorisation';
import { } from '../../../../../../api/bank-categorisation/api-models';
import { AxiosResponse } from 'axios';
import { numberGreaterThan, required } from '../../../../../shared/form/validators';
import { toOptionModels } from '../../../../../../helpers/object-helper';

interface TestTransactionFormProps {
  onSubmitSuccess: (results: TestBankTransactionResult[]) => void
  onBack: () => void
}

const TestTransactionForm: React.FC<TestTransactionFormProps> = (props) => {

  const { onBack, onSubmitSuccess, ...rest } = props;

  return (
    <AutoForm<TestBankTransactionRequest, AxiosResponse<TestBankTransactionResult[]>>
      {...rest}
      submitButtonText='Run Test'
      submittingMessage='Running Test...'
      onSubmitPromise={(r) => BankCategorisationApi.testTransaction(r)}
      onSubmitSuccess={(r) => onSubmitSuccess(r.data)}
      initialValues={{
        amount: 100,
        description: '',
        transactionType: 'Debit'
      }}
      rows={[
        { columns: [{ field: { name: 'description', type: 'TextInput', display: 'Description', options: { validators: [required()] } } }] },
        { columns: [{ field: { name: 'amount', type: 'NumberInput', display: 'Amount', options: { validators: [required(), numberGreaterThan(0)] } } }] },
        { columns: [{ field: { name: 'transactionType', type: 'Select', display: 'Debit / Credit', options: { options: toOptionModels(BankTransactionTypes), validators: [required(), numberGreaterThan(0)] } } }] },
      ]}
      onBack={onBack}
    />
  );
}

export default TestTransactionForm;
