import React from 'react';

import { LineGraph } from '../../../shared';
import { BankActivityStatistics, BankStatisticDateGroup } from '../../../../types/bank-types';
import * as Service from './bank-statistics-service';

interface BankActivityDateGraphProps {
  stats: BankStatisticDateGroup[]
  dateType: Service.DateRangeType
}

interface State {
  loading: boolean
  rawStats: BankActivityStatistics
  stats: BankStatisticDateGroup[]
}

const BankActivityDateGraph: React.FC<BankActivityDateGraphProps> = ({ stats, dateType }) => {

  return (
    <LineGraph
      series={Service.getSeriesData(stats, dateType)}
    />
  );
}

export default BankActivityDateGraph;
