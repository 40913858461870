import React from 'react';
import { ModalSimple } from '../../../../shared';
import { RuleInstance, RuleInstanceOptions } from '../../../../../types/rule-types';
import AddRuleInstanceForm from './add-rule-instance-form';

interface AddRuleInstanceModalProps {
  options: RuleInstanceOptions
  onSubmitSuccess: (newRuleInstance: RuleInstance) => void
  open: boolean
  onClose: () => void
}

const AddRuleInstanceModal: React.FC<AddRuleInstanceModalProps> = (props) => {

  const { open, onClose, ...rest } = props;

  if (!open) return null;

  return (
    <ModalSimple
      open
      onClose={onClose}
      header={`Add Rule Instance`}
    >
      <AddRuleInstanceForm
        hideHeader
        {...rest}
        onBack={onClose}
      />
    </ModalSimple>
  );
}

export default AddRuleInstanceModal;
