import React from 'react';
import classNames from 'classnames';

import * as Service from './bank-statistics-service';
import { toOptionModels } from '../../../../helpers/object-helper';
import { FormSelect, Button } from '../../../shared';
import { faSync } from '@fortawesome/free-solid-svg-icons';

interface GraphTypeFormProps {
  className?: string
  onTypeChange: (type: Service.BankActivityType) => void
  onDateRangeChange: (type: Service.DateRangeType) => void
  onRefresh: () => void
  type: Service.BankActivityType
  dateRange: Service.DateRangeType
}

const GraphTypeForm: React.FC<GraphTypeFormProps> = ({ className, type, dateRange, onTypeChange, onDateRangeChange, onRefresh }) => {

  const classes = classNames({
    'd-flex align-items-center': true,
    [className!]: className !== undefined
  });

  return (
    <div className={classes}>
      <FormSelect
        className='me-2 mb-0'
        name='graph-type'
        value={type}
        options={toOptionModels(Service.BankActivityTypes, true)}
        onChange={(e) => onTypeChange(e.currentTarget.value as any)}
      />
      <FormSelect
        name='date-range'
        className='me-2 mb-0'
        value={dateRange}
        options={toOptionModels(Service.DateRangeTypes, true)}
        onChange={(e) => onDateRangeChange(e.currentTarget.value as any)}
      />
      <Button icon={faSync} onClick={onRefresh} />
    </div>
  );
}

export default GraphTypeForm;
