import React from 'react';

import { AutoForm } from '../../../../shared';
import { required } from '../../../../shared/form/validators';
import { AssessmentBankAccountUpdateModel } from '../../../../../api/assessment-consumer/api-models';

interface AssessmentBankAccountFormProps {
  onSubmit: (model: AssessmentBankAccountUpdateModel) => void
}

const AssessmentBankAccountForm: React.FC<AssessmentBankAccountFormProps> = ({ onSubmit }) => {

  return (
    <AutoForm<AssessmentBankAccountUpdateModel>
      hideBack
      rows={[
        {
          columns: [
            { mediumSize: 6, field: { name: 'name', display: 'Name', type: 'TextInput', options: { validators: [required()] } } },
            { mediumSize: 6, field: { name: 'containsSrss', display: 'Contains SRSS', type: 'Checkbox' } }
          ]
        },
        {
          columns: [
            { mediumSize: 6, field: { name: 'hasNinetyDaysOfBanking', display: '90 Days Banking?', type: 'Checkbox' } },
            { mediumSize: 6, field: { name: 'nameOnBankAccountIsMasked', display: 'Name is Masked on Bank Account?', type: 'Checkbox' } }
          ]
        },
      ]}
      onSubmitPromise={(v) => Promise.resolve(v)}
      onSubmitSuccess={onSubmit}
    />
  )
}

export default AssessmentBankAccountForm;