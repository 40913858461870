import { BankActivityModel, BankStatisticDateGroup, BankActivityStatistics } from "../../../../types/bank-types";
import moment from "moment";
import { LineSeriesData } from "../../../shared/graph/shared/graph-types";
import { MomentDateRange } from "../../../../types/shared-types";
import * as DateHelper from "../../../../helpers/date-helper";

const dateFormat = 'MMM Do';

export type BankActivityType = 'LoginFormRetrieved' | 'LoggedIn' | 'LoginError' | 'MfaRequested' | 'StatementDownloaded' | 'StatementDownloadFailed';
export const BankActivityTypes = ['LoginFormRetrieved', 'LoggedIn', 'LoginError', 'MfaRequested', 'StatementDownloaded', 'StatementDownloadFailed'];

export type DateRangeType = 'LastWeek' | 'LastMonth'
export const DateRangeTypes = ['LastWeek', 'LastMonth'];

export const getdailyGroupedStats = (activityModels: BankActivityModel[]) => {
  const groupedData: BankStatisticDateGroup[] = [];
  if (!activityModels) return groupedData;
  activityModels.forEach(activity => {

    //Ensure this bank exists in grouped data
    let groupedItem = groupedData.find(e => e.bank === activity.data.bankName);
    if (!groupedItem) {
      groupedData.push({ bank: activity.data.bankName, data: [] });
      groupedItem = groupedData[groupedData.length - 1];
    }

    const momentDate = moment(activity.data.date);
    const date = momentDate.format(dateFormat);

    //Ensure Date Item exists in data
    let dateItem = groupedItem.data.find(e => e.date === date);
    if (!dateItem) {
      groupedItem.data.push({ amount: 0, date: date, moments: [] });
      dateItem = groupedItem.data[groupedItem.data.length - 1];
    }

    dateItem.moments.push(momentDate);
    dateItem.amount++;
  });
  return groupedData;
}

export const getSeriesData = (groupedStats: BankStatisticDateGroup[], type: DateRangeType) => {
  const range = toMomentDateRange(type);

  const seriesData: LineSeriesData[] = [];

  let workingDay = moment(range.min);
  while (workingDay.isSameOrBefore(range.max)) {
    const dateString = workingDay.format(dateFormat);
    groupedStats.forEach(bankItem => {
      let seriesBankData = seriesData.find(e => e.id === bankItem.bank);
      if (!seriesBankData) {
        seriesData.push({ id: bankItem.bank, data: [] });
        seriesBankData = seriesData[seriesData.length - 1];
      }

      const count = bankItem.data?.find(e => e.date === dateString)?.amount ?? 0;
      seriesBankData.data.push({ x: dateString, y: count });
    });
    workingDay = workingDay.add(1, 'day');
  }
  return seriesData;
}

export const toMomentDateRange = (type: DateRangeType) => {
  const range: MomentDateRange = {
    min: moment(DateHelper.getToday()),
    max: moment(DateHelper.getToday()).add(1, 'day')
  };

  switch (type) {
    case 'LastWeek':
      range.min = moment(range.min).subtract(7, 'days');
      break;
    case 'LastMonth':
      range.min = moment(range.min).subtract(1, 'month');
      break;
  }

  return range;
}

export const getStatisticGroup = (stats: BankActivityStatistics, type: BankActivityType) => {
  switch (type) {
    case 'LoggedIn': return stats.loggedIn;
    case 'LoginError': return stats.loginFailed;
    case 'LoginFormRetrieved': return stats.bankRetrieved;
    case 'MfaRequested': return stats.mfaRequested;
    case 'StatementDownloadFailed': return stats.statementDownloadFailed;
    case 'StatementDownloaded': return stats.statementDownloaded;
  }
}