import React from 'react';
import { ModalSimple, ChipList, Alert } from '../../../../shared';
import { BankingWordCategorisationInstance } from '../../../../../types/bank-types';
import { toChipProps } from '../../../../shared/chips/chip';
import { addSpacesOnCaps } from '../../../../../helpers/string-helper';
import WordListItemDisplay from './update-modal/formik-words-or-phrases/word-list/word-list-item-display';

export type ViewMoreDetailsType = 'CategoriesChecked' | 'WordsOrPhrases';

interface ViewMoreDetailsModalProps {
  instance?: BankingWordCategorisationInstance
  type: ViewMoreDetailsType
  open: boolean
  onClose: () => void
}

const ViewMoreDetailsModal: React.FC<ViewMoreDetailsModalProps> = ({ instance, open, onClose, type, ...rest }) => {

  if (!open || !instance) return null;

  const getHeader = () => {
    switch (type) {
      case 'CategoriesChecked': return `${instance.name} - Catgeories Checked`;
      case 'WordsOrPhrases': return `${instance.name} - Words Or Phrases`;
    }
  }

  const hasWordsOrPhrases = instance.wordsOrPhrases?.length ? true : false;

  return (
    <ModalSimple
      {...rest}
      open={open}
      onClose={onClose}
      header={getHeader()}
    >
      {type === 'CategoriesChecked' && (
        <React.Fragment>
          <ChipList chips={toChipProps(instance.categoriesChecked?.sort() ?? ['All'], (d) => addSpacesOnCaps(d))} />
        </React.Fragment>
      )}
      {type === 'WordsOrPhrases' && (
        <React.Fragment>
          {!hasWordsOrPhrases && <Alert variant='info' message='No Words or Phrases Set' />}
          {hasWordsOrPhrases && instance.wordsOrPhrases!.map((model, i) => (
            <React.Fragment>
              {i !== 0 && i !== instance.wordsOrPhrases?.length && <hr />}
              <WordListItemDisplay item={model} />
            </React.Fragment>
          ))}
        </React.Fragment>
      )}
    </ModalSimple>
  );
}

export default ViewMoreDetailsModal;
