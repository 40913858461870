import axios from 'axios';
import getSettings from '../../settings';
import {ConsumerBank} from '../../types/bank-types';
import {ListBanksFilter} from '../bank/api-models';
import {ResultSet, ResultWrapper} from '../../types/shared-types';
import {
    LoginToBankRequest,
    LoginToBankResponse,
    GetBankLoginFormResponse,
    DownloadBankStatementsRequest,
    DownloadBankStatementsResponse,
    CategorisedBankStatement,
    CategoriseAndAnalyseTransactionsApiCommand,
    CreateObUserRequest,
    CreateObUserApiResponse,
    GetObConsentUrlResponse,
    GetObBankAccountsResponse
} from './api-models';

const settings = getSettings();

export const relativeUrls = {
    list: '',
    createObUser: () => '/create-user',
    getObConsentUrl: (fortunaUserId: number, bankId: string) => `/${fortunaUserId}/consent-url?&institutionId=${bankId}`,
    getObAccounts: (fortunaUserId: number) => `/${fortunaUserId}/accounts`,
    get: (id: number) => `/${id}`,
    getLoginForm: (id: number, reference: string) => `/${id}/login-form?reference=${reference}`,
    login: (id: number) => `/${id}/login`,
    mfa: (id: number) => `/${id}/mfa`,
    downloadStatements: (id: number) => `/${id}/download-statements`,
    analyseBankStatements: '/statement/analyse',
};

const root = `${settings.baseApiUrl}/bank`;
const obRoot = `${settings.baseApiUrl}/openbanking`;

export const createUser = (request: CreateObUserRequest) => {
    return axios.post<ResultWrapper<CreateObUserApiResponse>>(`${obRoot}${relativeUrls.createObUser()}`, request);
}

export const getObConsentUrl = (fortunaUserId: number, bankId: string) => {
    return axios.get<ResultWrapper<GetObConsentUrlResponse>>(`${obRoot}${relativeUrls.getObConsentUrl(fortunaUserId, bankId)}`);
}

export const getObAccounts = (fortunaUserId: number) => {
    return axios.get<ResultWrapper<GetObBankAccountsResponse>>(`${obRoot}${relativeUrls.getObAccounts(fortunaUserId)}`);
}

export const list = (filter?: ListBanksFilter) => {
    return axios.get<ResultSet<ConsumerBank>>(`${root}${relativeUrls.list}`, {params: filter});
};

export const get = (id: number) => {
    return axios.get<ConsumerBank>(`${root}${relativeUrls.get(id)}`);
};

export const getLoginForm = (id: number, reference: string) => {
    return axios.get<GetBankLoginFormResponse>(`${root}${relativeUrls.getLoginForm(id, reference)}`);
};

export const login = (id: number, request: LoginToBankRequest) => {
    return axios.post<ResultWrapper<LoginToBankResponse>>(`${root}${relativeUrls.login(id)}`, request);
};

export const mfa = (id: number, request: LoginToBankRequest) => {
    return axios.post<ResultWrapper<LoginToBankResponse>>(`${root}${relativeUrls.mfa(id)}`, request);
};

export const downloadStatements = (id: number, request: DownloadBankStatementsRequest) => {
    return axios.post<ResultWrapper<DownloadBankStatementsResponse>>(`${root}${relativeUrls.downloadStatements(id)}`, request);
};


export const analyseBankStatements = (request: CategoriseAndAnalyseTransactionsApiCommand) => {

    //Sanitise request.  Remove category as if the enum doesnt match on the backend it throws an exception
    if (request.bankAccounts) request.bankAccounts.forEach(bankAccount => {
        if (bankAccount.transactions) bankAccount.transactions.forEach(transaction => {
            if (!transaction.rawCategory && transaction.category) transaction.rawCategory = transaction.category;
            transaction.category = undefined;
        });
    });

    return axios.post<CategorisedBankStatement>(`${root}${relativeUrls.analyseBankStatements}`, request);
};