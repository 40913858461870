import React from 'react';
import { ListItem } from '../../../shared';
import { AssessmentRuleOutcome } from '../../../../types/assessment-types';
import { addSpacesOnCaps } from '../../../../helpers/string-helper';

interface AssessmentRuleOutcomeListItemProps {
  ruleOutcome: AssessmentRuleOutcome
}
const AssessmentRuleOutcomeListItem: React.FC<AssessmentRuleOutcomeListItemProps> = ({ ruleOutcome }) => {

  return (
    <ListItem
      className='mb-2'
      borderLeft
      primaryText={addSpacesOnCaps(ruleOutcome.ruleDefinitionType, true)}
      secondaryText={addSpacesOnCaps(ruleOutcome.result)}
      value={ruleOutcome.message ?? ruleOutcome.exceptionMessage}
    >
    </ListItem>
  );
}

export default AssessmentRuleOutcomeListItem;
