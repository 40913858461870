import axios from 'axios';
import getSettings from '../../settings'
import { Bank, BankActivityStatistics, BankProvider, OpenBankingProviderInstitution } from '../../types/bank-types';
import {
    AddBankApiModel,
    BankToggleActiveApiModel,
    ListBankProvidersFilter,
    ListBanksFilter,
    MergeBanksApiModel,
    UpdateBankApiModel,
    UpdateBankWeightingsApiModel
} from './api-models';

const settings = getSettings();

export const relativeUrls = {
    list: '',
    listObProviders: '/obprovider',
    populateObProviders: '/obprovider/populate-from-client',
    populateObProvidersAndSync: '/obprovider/populate-from-client-and-sync',
    obSync: '/obprovider/sync',
    toggleObActive: (id: number) => `/${id}/toggle-ob-active`,
    listProviders: '/provider',
    populateProviders: '/provider/populate-from-client',
    populateProvidersAndSync: '/provider/populate-from-client-and-sync',
    sync: '/provider/sync',
    toggleActive: (id: number) => `/${id}/toggle-active`,
    add: '',
    addOb: '/obprovider',
    update: (id: number) => `/${id}`,
    updateProviderWeightings: (id: number) => `/${id}/provider/weightings`,
    merge: `/merge`,
    assignProvider: (bankId: number, providerId: number) => `/${bankId}/assign-provider/${providerId}`,
    assignOpenBankingProvider: (bankId: number, providerId: number) => `/${bankId}/assign-open-banking-provider/${providerId}`,
    getStatistics: '/statistics'
}

const root = `${settings.baseApiUrl}/admin/bank`;

export const list = (filter?: ListBanksFilter) => {
    return axios.get<Bank[]>(`${root}${relativeUrls.list}`, { params: filter });
};

export const add = (model: AddBankApiModel) => {
    return axios.post<Bank>(`${root}${relativeUrls.add}`, model);
};

export const addOb = (model: AddBankApiModel) => {
    return axios.post<Bank>(`${root}${relativeUrls.addOb}`, model);
};

export const update = (id: number, model: UpdateBankApiModel) => {
    return axios.put<Bank>(`${root}${relativeUrls.update(id)}`, model);
};

export const updateProviderWeightings = (id: number, model: UpdateBankWeightingsApiModel) => {
    return axios.put<Bank>(`${root}${relativeUrls.updateProviderWeightings(id)}`, model);
};

export const toggleActive = (id: number, active: boolean) => {
    const model: BankToggleActiveApiModel = { active };
    return axios.put<Bank>(`${root}${relativeUrls.toggleActive(id)}`, model);
};

export const toggleObActive = (id: number, active: boolean) => {
    const model: BankToggleActiveApiModel = { active };
    return axios.put<Bank>(`${root}${relativeUrls.toggleObActive(id)}`, model);
};

export const merge = (model: MergeBanksApiModel) => {
    return axios.put<Bank>(`${root}${relativeUrls.merge}`, model);
};

export const assignProvider = (bankId: number, providerId: number) => {
    return axios.put<Bank>(`${root}${relativeUrls.assignProvider(bankId, providerId)}`);
};

export const assignOpenBankingProvider = (bankId: number, providerId: number) => {
    return axios.put<Bank>(`${root}${relativeUrls.assignOpenBankingProvider(bankId, providerId)}`);
};

export const listProviders = (filter: ListBankProvidersFilter) => {
    return axios.get<BankProvider[]>(`${root}${relativeUrls.listProviders}`, { params: filter });
};

export const listObProviders = (filter: ListBankProvidersFilter) => {
    return axios.get<OpenBankingProviderInstitution[]>(`${root}${relativeUrls.listObProviders}`, { params: filter });
};

export const populateProviders = () => {
    return axios.post(`${root}${relativeUrls.populateProviders}`);
};

export const populatObProviders = () => {
    return axios.post(`${root}${relativeUrls.populateObProviders}`);
};

export const populateProvidersAndSync = () => {
    return axios.post(`${root}${relativeUrls.populateProvidersAndSync}`);
};

export const populateObProvidersAndSync = () => {
    return axios.post(`${root}${relativeUrls.populateObProvidersAndSync}`);
};

export const sync = () => {
    return axios.post(`${root}${relativeUrls.sync}`);
};

export const obSync = () => {
    return axios.post(`${root}${relativeUrls.obSync}`);
};

export const getStatistics = () => {
    return axios.get<BankActivityStatistics>(`${root}${relativeUrls.getStatistics}`);
};