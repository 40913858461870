import React, { useState } from 'react';
import LoginForm from './login-form';
import MfaForm from './mfa-form';
import * as CentrelinkConsumerApi from '../../../../../api/centrelink-consumer';
import { Form } from "../../../../../types/form-types";
import WithApiHelper, { WithApiHelperProps } from '../../../../hoc/with-api-helper';
import BlockUi from '../../../../shared/block-ui';
import DashboardPage from '../../../../hoc/dashboard-page';
import { CardSimple } from '../../../../shared';
import { LoginToCentrelinkRequest, LoginToCentrelinkResponse, CentrelinkServiceType} from '../../../../../api/centrelink-consumer/api-models';
import { getConfigFileParsingDiagnostics } from 'typescript';

interface CentrelinkLoginProps extends WithApiHelperProps { }

interface State {
  type?: CentrelinkServiceType
  loadingMessage?: string
  mfaForm?: Form
  mfaFormKeys: string[]
  userToken?: string
  reference?: string
  providerId?: number
}

const CentrelinkLogin: React.FC<CentrelinkLoginProps> = ({ apiHelper }) => {

  const [state, setState] = useState<State>({
    mfaFormKeys: [],
  });

  apiHelper.setDefaultOnErrorAction(() => setState((ps) => ({ ...ps, loadingMessage: undefined })));

  const handleBack = () => setState((ps) => ({ ...ps, type: undefined, mfaFormKeys: [], userToken: undefined, mfaForm: undefined, providerId: undefined }));

  const handleLoginSuccess = (r: LoginToCentrelinkResponse) => {
    setState((ps) => ({
      ...ps,
      type: r.serviceProvider,
      mfaFormKeys: getFormKeys(r.mfa!),
      userToken: r.token,
      mfaForm: r.mfa,
      reference: r.reference,
      providerId: r.providerId
    }));
  }



  const getFormKeys = (form: Form) => {
    const keys: string[] = [];
    if (!form?.rows) return keys;
    form.rows.forEach((r) => {
      if (r.columns) {
        r.columns.forEach(c => {
          if (c?.field) {
            if (c.field.name && c.field.name.length > 0 && c.field.type !== 'Display') {
              keys.push(c.field.name);
            }
            if (c.field.subElements) {
              c.field.subElements.forEach((sf) => {
                keys.push(sf.name);
              });
            }
          }
        });
      }
    });
    return keys;
  }

  const shouldRenderLogin = () => state.mfaForm === undefined;
  const shouldRenderMfa = () => state.mfaForm !== undefined;

    const request: LoginToCentrelinkRequest = {
        type: state.type,
        reference: state.reference,
        formValues: {},
        token: state.userToken,
        providerId: state.providerId
    };

    const handleMFASuccess = (r: LoginToCentrelinkResponse) => {
        setState((ps) => ({
            ...ps,
            data: CentrelinkConsumerApi.downloadStatement(request),
        }));
    }

  return (
    <CardSimple container className='centrelink-login-container' extraPadding>
      <BlockUi blocking={state.loadingMessage ? true : false} text={state.loadingMessage}>
        {shouldRenderLogin() && <LoginForm onSuccess={handleLoginSuccess} />}
        {shouldRenderMfa() && (
          <React.Fragment>
            <hr />
            <MfaForm
              type={state.type}
              formKeys={state.mfaFormKeys}
              token={state.userToken!}
              form={state.mfaForm!}
              onBack={handleBack}
              onSuccess={handleMFASuccess}
              reference={state.reference}
              providerId={state.providerId}
            />
          </React.Fragment>
        )}
      </BlockUi>
    </CardSimple>
  );
}

export default DashboardPage(WithApiHelper(CentrelinkLogin));