import React from 'react';
import { RouteComponentProps } from 'react-router';

import { TabPages } from '../../../shared';
import InstanceGroups from './groups';
import Instances from './instances';

interface WordCategorisationProps extends RouteComponentProps {
}

const WordCategorisation: React.FC<WordCategorisationProps> = ({ match: { path } }) => {

  return (
    <TabPages
      redirectFromBase
      tabs={[
        { label: 'Instance Groups', link: `${path}/instance-groups`, component: InstanceGroups, exact: true },
        { label: 'Instances', link: `${path}/instances`, component: Instances, exact: true },
      ]}
    />
  );
}

export default WordCategorisation;
