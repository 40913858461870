import React from 'react';
import { AxiosResponse } from 'axios';
import { AutoForm } from '../../../../shared';
import { Bank } from '../../../../../types/bank-types';
import { OptionModel } from '../../../../../types/shared-types';
import { UpdateBankApiModel } from '../../../../../api/bank/api-models';
import { required } from '../../../../shared/form/validators';
import * as BankApi from '../../../../../api/bank';

interface UpdateBankFormProps {
  bank: Bank
  onSubmitSuccess: (bank: Bank) => void
  onBack: () => void
  hideHeader?: boolean
}

export interface UpdateBankFormValues extends UpdateBankApiModel {
  bankId: number
}


const UpdateBankForm: React.FC<UpdateBankFormProps> = (props) => {

  const { bank, onSubmitSuccess, hideHeader, onBack } = props;

  const providerOptions: OptionModel<string>[] = !bank.providers ? [] : bank.providers.map(e => ({ label: `[${e.type}] - ${e.name}`, value: e.type }));

  const handleSubmit = (values: UpdateBankFormValues) => {
    const model: UpdateBankApiModel = {
      name: values.name,
      aliases: values.aliases,
      removeProviders: values.removeProviders
    };

    return BankApi.update(values.bankId, model);
  }

  return (
    <AutoForm<UpdateBankFormValues, AxiosResponse<Bank>>
      header={hideHeader ? undefined : `Update ${bank.name}`}
      hiddenFields={[{ name: 'bankId' }]}
      onSubmitPromise={handleSubmit}
      onBack={onBack}
      onSubmitSuccess={(r) => onSubmitSuccess(r.data)}
      rows={[
        { columns: [{ field: { name: 'name', type: 'TextInput', display: 'Name', options: { validators: [required()] } } }] },
        { columns: [{ field: { name: 'aliases', type: 'TagInput', display: 'Aliases' } }] },
        { columns: [{ field: { name: 'removeProviders', type: 'MultiSelect', display: 'Remove Provider/s', options: { options: providerOptions } } }] },
      ]}
      initialValues={{ bankId: bank.id, removeProviders: [], aliases: bank.aliases, name: bank.name }}
      submitButtonText='Update'
      toastOnError
    />
  );
}

export default UpdateBankForm;
