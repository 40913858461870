import React from 'react';
import { Row, Col } from 'reactstrap';
import { TestBankTransactionResult } from '../../../../../../api/bank-categorisation/api-models';
import ResultView from './test-transaction-result-view';
import { Button } from '../../../../../shared';

interface TestTransactionResultsViewProps {
  results: TestBankTransactionResult[]
  onBack: () => void
}

const TestTransactionResultsView: React.FC<TestTransactionResultsViewProps> = ({ results, onBack, ...rest }) => {

  return (
    <div {...rest}>
      {results.map((r, i) => (
        <React.Fragment>
          <ResultView result={r} key={i} />
          {i !== results.length - 1 && <hr />}
        </React.Fragment>
      ))}
      <hr />
      <Row>
        <Col xs={12}> <Button text='Run Another Test' onClick={onBack} /> </Col>
      </Row>
    </div>
  );
}

export default TestTransactionResultsView;
