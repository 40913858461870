import axios from 'axios';
import getSettings from '../../settings'
import { CurrentUser } from '../../types/user-types';

const settings = getSettings();

export const relativeUrls = {
  me: '/me',
}

const root = `${settings.baseApiUrl}`;

export const me = () => {
  return axios.get<CurrentUser>(`${root}${relativeUrls.me}`);
};