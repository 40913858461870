import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { BankingWordCategorisationInstance, BankingWordCategorisationInstanceOptions } from '../../../../../types/bank-types';
import * as BankCategorisationApi from '../../../../../api/bank-categorisation';
import DashboardPage from '../../../../hoc/dashboard-page';
import WithApiHelper, { WithApiHelperProps } from '../../../../hoc/with-api-helper';
import UpdateKeywordCategorisationModal from './update-modal';
import TestTransactionModal from './test-transaction-modal';
import InstanceTable from './instance-table';
import * as bankCategoryApi from '../../../../../api/bank-categorisation';
import * as FileHelper from '../../../../../helpers/file-helper';
import { toBankingCategorisationDataSnapshot } from './categorisation-word-helper';

interface BankingCategorisationProps extends WithApiHelperProps, RouteComponentProps { }

interface ModalStateData {
  open: boolean
  type: 'addOrUpdate' | 'TestTransaction'
  instance?: BankingWordCategorisationInstance
}

interface BankingCategorisationState {
  reloading: boolean
  blockingMessage?: string
  instances: BankingWordCategorisationInstance[]
  options: BankingWordCategorisationInstanceOptions
  modal: ModalStateData
}

const BankingCategorisation: React.FC<BankingCategorisationProps> = ({ apiHelper, toastHelper }) => {

  const [state, setState] = useState<BankingCategorisationState>({
    reloading: false,
    blockingMessage: undefined,
    instances: [],
    options: {} as any,
    modal: { open: false, type: 'addOrUpdate' }
  });

  apiHelper.setDefaultOnErrorAction(() => setState(ps => ({ ...ps, reloading: false, blockingMessage: undefined })));

  const reload = () => {
    setState((ps) => ({ ...ps, reloading: true }));
    apiHelper.makeCall(() => BankCategorisationApi.listWordInstances())
      .then(listResponse => {
        apiHelper.makeCall(() => BankCategorisationApi.getWordInstanceOptions())
          .then(optionsResponse => setState((ps) => ({ ...ps, reloading: false, instances: listResponse.data.results, options: optionsResponse.data })))
      })
  };

  //On Init
  useEffect(() => reload(), []);

  const handleAddOrUpdateSuccess = (instance: BankingWordCategorisationInstance) => {
    const isUpdate = state.modal.instance ? true : false;
    toastHelper.success(isUpdate ? 'Keyword Instance Updated!' : 'Keyword Instance Added!');
    setState(ps => {
      const newState: BankingCategorisationState = { ...ps };
      newState.modal.open = false;
      if (!isUpdate) {
        newState.instances.push(instance);
      } else {
        const index = newState.instances.findIndex(e => e.id === instance.id);
        if (index >= 0) newState.instances[index] = instance;
      }
      return newState;
    });
  };

  const handleDelete = (id: number) => {
    setState(ps => ({ ...ps, blockingMessage: 'Deleting Keyword Instance...' }));
    apiHelper.makeCall(() => bankCategoryApi.deleteWordInstance(id))
      .then(() => {
        toastHelper.success('Keyword Instance Deleted');
        setState((ps) => {
          const newState: BankingCategorisationState = { ...ps, blockingMessage: undefined };
          const index = newState.instances.findIndex(e => e.id === id);
          if (index >= 0) newState.instances.splice(index, 1);
          return newState;
        });
      });
  };

  const handleDownloadJson = () => {
    const jsonData = toBankingCategorisationDataSnapshot(state.instances, state.options.groups);
    setState(ps => ({ ...ps, blockingMessage: 'Downloading JSON File...' }));
    FileHelper.downloadJson(jsonData, 'Banking-Categorisation-Data');
    setState(ps => ({ ...ps, blockingMessage: undefined }));
  }

  return (
    <React.Fragment>
      <InstanceTable
        instances={state.instances}
        loading={state.reloading}
        blockingMessage={state.blockingMessage}
        onTestTransaction={() => setState(ps => ({ ...ps, modal: { open: true, type: 'TestTransaction', instance: undefined } }))}
        onAdd={() => setState(ps => ({ ...ps, modal: { open: true, type: 'addOrUpdate', instance: undefined } }))}
        onDelete={handleDelete}
        onDownloadJson={handleDownloadJson}
        onEdit={(i) => setState((ps) => ({ ...ps, modal: { type: 'addOrUpdate', instance: i, open: true } }))}
        onReload={reload}
      />
      {state.modal.type === 'TestTransaction' && (
        <TestTransactionModal
          onClose={() => setState(ps => ({ ...ps, modal: { ...ps.modal, open: false } }))}
          open={state.modal.open}
        />
      )}
      {state.modal.type === 'addOrUpdate' && (
        <UpdateKeywordCategorisationModal
          options={state.options}
          instance={state.modal.instance}
          onSubmitSuccess={handleAddOrUpdateSuccess}
          onClose={() => setState(ps => ({ ...ps, modal: { ...ps.modal, open: false } }))}
          open={state.modal.open}
        />
      )}
    </React.Fragment>
  );
}

export default DashboardPage(WithApiHelper(BankingCategorisation));
