import React from 'react';
import { RouteComponentProps } from 'react-router';

import { TabPages } from '../../../shared';
import Login from './login';
import GenerateCisPdf from './generate-cis-pdf';

interface CentrelinkProps extends RouteComponentProps {
}

const Centrelink: React.FC<CentrelinkProps> = ({ match: { path } }) => {

  return (
    <TabPages
      redirectFromBase
      tabs={[
        { label: 'Login', link: `${path}/login`, component: Login },
        { label: 'Generate CIS PDF', link: `${path}/generate-cis-pdf`, component: GenerateCisPdf },
      ]}
    />
  );
}

export default Centrelink;
