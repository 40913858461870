import React from 'react';

import { FormTagDisplay } from '../../../../../../../shared'
import { WordCategorisationModel } from '../../../../../../../../types/bank-types';
import { toOptionModels } from '../../../../../../../../helpers/object-helper';
import { ChipProps } from '../../../../../../../shared/chips/chip';

export interface WordListItemDisplayProps {
  item: WordCategorisationModel
}

const WordListItemDisplay: React.FC<WordListItemDisplayProps> = ({ item }) => {

  const hasExclude = item.exclude?.length ? true : false;

  const getMatchOptionChipProps = () => {
    const options: string[] = [];
    if (item.matchOnIndividualWords) {
      options.push('Individual Word');
    } else {
      options.push('Phrase');
    }
    if (item.ignoreMatchIfHasNumbers) options.push('Ignore if has Numbers');
    if (item.allIncludeWordsMustMatch) options.push('All Words Must Match');

    options.push(`Score: ${item.score}`);

    return options.map<ChipProps>((o) => ({
      label: o,
      small: true,
      variant: 'info'
    }));
  }

  return (
    <React.Fragment>
      <FormTagDisplay className='mb-3' smallOverride label='Match Options' tags={getMatchOptionChipProps()} />
      <FormTagDisplay className='mb-3' smallOverride label='Words' tags={toOptionModels(item.include)} />
      {hasExclude && <FormTagDisplay className='mb-3' smallOverride label='Excluded Words' tags={toOptionModels(item.exclude)} />}
    </React.Fragment>
  );
};

export default WordListItemDisplay;
