import React, { useEffect, useState } from 'react';

import * as AssessmenetApi from '../../../api/assessment';
import { DataTable } from '../../shared';
import { AssessmentSummary } from '../../../types/assessment-types';
import WithApiHelper, { WithApiHelperProps } from '../../hoc/with-api-helper';
import * as DateHelper from '../../../helpers/date-helper';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

interface AssessmentTableProps extends WithApiHelperProps {
  onEdit: (asessment: AssessmentSummary) => void
}

interface AssessmentsState {
  assessments: AssessmentSummary[]
  loading: boolean
  blockingMessage?: string
}

const AssessmentTable: React.FC<AssessmentTableProps> = ({ apiHelper, onEdit }) => {

  const [state, setState] = useState<AssessmentsState>({
    assessments: [],
    loading: true,
  });

  apiHelper.setDefaultOnErrorAction(() => setState(ps => ({ ...ps, blockingMessage: undefined, loading: false })));

  const reload = () => {
    setState((ps) => ({ ...ps, loading: true }));
    apiHelper.makeCall(() => AssessmenetApi.list())
      .then((r) => setState((ps) => ({ ...ps, loading: false, assessments: r.data.results })));
  }

  //On Start
  useEffect(() => reload(), []);

  return (
    <DataTable
      small
      toolbarProps={{ onReload: reload }}
      loading={state.loading}
      dataIdProperty='id'
      data={state.assessments}
      columns={[
        { key: 'assessmentId', header: 'Id', searchable: true },
        { key: 'status', header: 'Status', filters: [{ type: 'StringContains' }], searchable: true },
        { key: 'overallOutcome', header: 'Overall Outcome', filters: [{ type: 'StringContains' }], searchable: true },
        { key: 'modifiedAt', header: 'Date Modified', compareValue: (e) => DateHelper.tryParseToMoment(e.modifiedAt), renderCell: (e) => DateHelper.formatDate(e.modifiedAt) },
        { key: 'createdAt', header: 'Date Created', compareValue: (e) => DateHelper.tryParseToMoment(e.createdAt), renderCell: (e) => DateHelper.formatDate(e.createdAt) },
      ]}
      actions={[
        { onClick: onEdit, icon: faSearch, tooltip: 'View', variant: 'transparent' }
      ]}
    />
  );
}

export default WithApiHelper(AssessmentTable);
