export interface RebuildProjectionJob {
  id: number
  definition: ProjectionDefinition
  errorMessage?: string
  secondsRuntime?: number
  success?: boolean
  complete?: boolean
  createdAt: string
}

export interface ProjectionDefinition {
  id: number
  type: ProjectionType
  streamPosition: number
  inactive: boolean
}

export type ProjectionType = 'AssessmentSummary' | 'AssessmentDocument' | 'BankingActivityStatistics';
export const ProjectionTypes = ['AssessmentSummary', 'AssessmentDocument', 'BankingActivityStatistics'];
