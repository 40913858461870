import React from 'react';
import { Tabs, Button, Alert, PortletSimple } from '../../../../shared';
import { BankStatementAccount, CategorisedBankAccount } from '../../../../../types/bank-types';
import { TabDefinition } from '../../../../shared/tabs/tabs';
import RawBankStatementTable from './raw-bank-statement-table';
import CategorisedBankStatementTable from './categorised-bank-statement-table';
import { ButtonProps } from '../../../../shared/buttons/button';

interface BankStatementsProps {
  rawAccounts: BankStatementAccount[]
  categorisedAccounts: CategorisedBankAccount[] | null
  loading?: boolean
  onBack?: () => void
  onCategoriseStatements?: (rawAccounts: BankStatementAccount[]) => void
}

const BankStatements: React.FC<BankStatementsProps> = ({ rawAccounts, categorisedAccounts, onCategoriseStatements, loading, onBack }) => {

  const renderActions = () => {
    const buttons: ButtonProps[] = [];
    if (onCategoriseStatements) {
      buttons.push({
        disabled: loading,
        onClick: () => onCategoriseStatements(rawAccounts),
        bsVariant: 'primary',
        text: 'Categorise Transactions',
        className: 'me-2',
        id: 'categorise-transactions-button'
      });
    }

    if (onBack) buttons.push({ disabled: loading, onClick: onBack, text: 'Back', id: 'bank-statements-back-button' });
    if (!buttons.length) return null;

    return buttons.map((b, i) => <Button key={b.text ?? i} {...b} />)
  };

  if (!rawAccounts?.length) {
    return (
      <React.Fragment>
        <Alert variant='info' message='No transactions found for last 90 days for any accounts' />
        {onBack && (
          <React.Fragment>
            <hr />
            {renderActions()}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  const shouldRenderCategorisedAccounts = categorisedAccounts?.length ? true : false;

  return (
    <React.Fragment>
      <PortletSimple hasTable id='raw-bank-statements' headerText='Raw Statements'>
        <Tabs
          tabs={rawAccounts.map<TabDefinition>(a => ({
            component: <RawBankStatementTable account={a} />,
            label: a.name ?? `${a.id} - Bank Account`
          }))}
        />
      </PortletSimple>
      {shouldRenderCategorisedAccounts && (
        <React.Fragment>
          <hr />
          <PortletSimple hasTable id='categorised-bank-statements' headerText='Categorised Statements'>
            <Tabs
              tabs={categorisedAccounts!.map<TabDefinition>(a => ({
                component: <CategorisedBankStatementTable account={a} />,
                label: 'Bank Account'
              }))}
            />
          </PortletSimple>
        </React.Fragment>
      )}
      <hr />
      {renderActions()}
    </React.Fragment>

  );
}

export default BankStatements;
