import React, { useState } from 'react';
import { Container } from 'reactstrap';

import StartAssessmentCard from './start-assessment-card';
import BankAccount from './bank-account';

import WithApiHelper, { WithApiHelperProps } from '../../../hoc/with-api-helper';
import DashboardPage from '../../../hoc/dashboard-page';
import * as AssessmentConsumerApi from '../../../../api/assessment-consumer';
import { BlockUi } from '../../../shared';
import { AssessmentBankAccountUpdateModel } from '../../../../api/assessment-consumer/api-models';


interface AssessmentConsumerPageProps extends WithApiHelperProps {
}

interface SelectedAssessmentData {
  id: string
}

interface AssessmentConsumerPageState {
  selectedAssessment: SelectedAssessmentData | null
  loadingMessage?: string
}

const AssessmentConsumerPage: React.FC<AssessmentConsumerPageProps> = ({ apiHelper, toastHelper }) => {

  const [state, setState] = useState<AssessmentConsumerPageState>({
    selectedAssessment: null
  });

  apiHelper.setDefaultOnErrorAction(() => setState(ps => ({ ...ps, loadingMessage: undefined })));

  const handleStartAssessment = () => {
    setState(ps => ({ ...ps, loadingMessage: 'Starting Assessment...' }));
    apiHelper.makeCall(() => AssessmentConsumerApi.start())
      .then(r => {
        toastHelper.success('Assessment started!');
        setState(ps => ({ ...ps, loadingMessage: undefined, selectedAssessment: { id: r.data } }));
      })
  }

  const handleBankAccountFormSubmit = (values: AssessmentBankAccountUpdateModel) => {
    setState(ps => ({ ...ps, loadingMessage: 'Updating Bank Info...' }));
    apiHelper.makeCall(() => AssessmentConsumerApi.updateApplicationData(state.selectedAssessment?.id!, { bankAccount: values }))
      .then(r => {
        toastHelper.success('Bank account info sent for assessment!');
        setState(ps => ({ ...ps, loadingMessage: undefined }));
      })
  }

  const showBankAccount = state.selectedAssessment?.id ? true : false;
  // const showBankAccount = true;
  return (
    <Container>
      <BlockUi blocking={state.loadingMessage ? true : false} text={state.loadingMessage}>
        <StartAssessmentCard onStartAssessment={handleStartAssessment} assessmentId={state.selectedAssessment?.id} />
        {showBankAccount && (
          <BankAccount className='mt-2' onSubmit={handleBankAccountFormSubmit} />
        )}
      </BlockUi>

    </Container>
  )
}

export default DashboardPage(WithApiHelper(AssessmentConsumerPage));