import React from 'react';
import * as BankConsumerApi from '../../../../api/bank-consumer';
import { FormAsyncTypeahead } from '../../../shared';
import { ConsumerBank } from '../../../../types/bank-types';

interface SearchFormProps {
  searchValue: string
  bank?: ConsumerBank | null
  onSearchValueChange: (value: string) => void
  onSelect: (bank: ConsumerBank | null) => void
  disabled?: boolean
}


const SearchForm: React.FC<SearchFormProps> = ({ onSelect, disabled, searchValue, onSearchValueChange, bank }) => {

  return (
    <FormAsyncTypeahead
      inputValue={searchValue}
      onInputChange={(v) => onSearchValueChange(v)}
      disabled={disabled}
      label='Search'
      name='bank-search'
      placeholder='Westpac, Bankwest, etc...'
      getOptionLabel={(bank: any) => bank.name}
      getOptionValue={(bank: any) => bank.id}
      searchPromise={(search: string) => BankConsumerApi.list({ nameContains: search, take: 5 }).then(r => r.data.results)}
      onChange={(value?: any) => onSelect(value)}
      value={bank}
    />
  );
}

export default SearchForm;
