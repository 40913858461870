import React from 'react';
import { DataTable, Alert } from '../../../../shared';
import { CategorisedBankAccount } from '../../../../../types/bank-types';

interface CategorisedBankStatementTableProps {
  account: CategorisedBankAccount
  className?: string
}

const CategorisedBankStatementTable: React.FC<CategorisedBankStatementTableProps> = ({ account, className }) => {

  if (!account?.transactions?.length) {
    return <Alert variant='info' message='No transactions found for last 90 days' />
  }

  return (
    <DataTable
      dataIdProperty='id'
      columns={[
        { key: 'id', 'header': 'Id' },
        { key: 'date', header: 'Date', searchable: true },
        { key: 'providerCategory', header: 'Before Category', searchable: true, filters: [{ type: 'StringContains' }] },
        { key: 'incomeAndExpenditureCategory', header: 'After Category', searchable: true, filters: [{ type: 'StringContains' }] },
        { key: 'description', header: 'Description', searchable: true, filters: [{ type: 'StringContains' }] },
        { key: 'amount', header: 'Amount', searchable: true },
        { key: 'type', header: 'Type', searchable: true },
      ]}
      data={account.transactions}
      className={className}
      pageOptions={{ itemsPerPage: 30 }}
    />
  );
}

export default CategorisedBankStatementTable;
