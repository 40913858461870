import React from 'react';
import { RouteComponentProps } from 'react-router';
import WithAdminUser, { withAdminUserProps } from '../../hoc/with-admin-user';
import { TabPages } from '../../shared';
import RuleGroups from './rule-groups'
import RuleInstances from './rule-instances'

interface RuleManagementProps extends RouteComponentProps, withAdminUserProps {
}

const RuleManagement: React.FC<RuleManagementProps> = (props) => {

  const { match: { path } } = props;

  return (
    <TabPages
      redirectFromBase
      tabs={[
        { label: 'Rule Groups', link: `${path}/rule-groups`, component: RuleGroups, exact: true },
        { label: 'Rule Instances', link: `${path}/rule-instances`, component: RuleInstances, exact: true },
      ]}
    />
  );
}

export default WithAdminUser(RuleManagement);
