import React from "react";

interface FooterProps {
}

const Footer: React.FC<FooterProps> = () => {

  return (
    <footer> </footer>
  )
}

export default Footer;
