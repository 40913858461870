import React from 'react';
import { ModalSimple } from '../../../../shared';
import { Bank } from '../../../../../types/bank-types';
import MergeBanksForm from './merge-banks-form';

interface MergeBanksModalProps {
  bank1: Bank
  bank2: Bank
  onSubmitSuccess: (mergedBank: Bank, removedBankId: number) => void
  open: boolean
  onClose: () => void
}

const MergeBanksModal: React.FC<MergeBanksModalProps> = (props) => {

  const { open, onClose, ...rest } = props;

  if (!open) return null;

  return (
    <ModalSimple
      open
      onClose={onClose}
      header={`Merge Banks`}
    >
      <MergeBanksForm
        hideHeader
        {...rest}
        onBack={onClose}
      />
    </ModalSimple>
  );
}

export default MergeBanksModal;
