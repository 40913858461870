import React from 'react';
import { DataTable, Alert } from '../../../../shared';
import { BankStatementAccount } from '../../../../../types/bank-types';

interface RawBankStatementTableProps {
  account: BankStatementAccount
  className?: string
}

const RawBankStatementTable: React.FC<RawBankStatementTableProps> = ({ account, className }) => {

  if (!account?.transactions?.length) {
    return <Alert variant='info' message='No transactions found for last 90 days' />
  }

  return (
    <DataTable
      dataIdProperty='id'
      columns={[
        { key: 'accountId', hide: true },
        { key: 'id', 'header': 'Id' },
        { key: 'date', header: 'Date', searchable: true },
        { key: 'category', header: 'Category', searchable: true, filters: [{ type: 'StringContains' }] },
        { key: 'description', header: 'Description', searchable: true, filters: [{ type: 'StringContains' }] },
        { key: 'amount', header: 'Amount', searchable: true },
        { key: 'type', header: 'Type', searchable: true },
      ]}
      data={account.transactions}
      className={className}
      pageOptions={{ itemsPerPage: 30 }}
    />
  );
}

export default RawBankStatementTable;
