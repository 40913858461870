import React, { useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { v4 } from 'uuid';

import { required, maxCharLength } from '../../../../../shared/form/validators';
import * as BankCategoriseApi from '../../../../../../api/bank-categorisation';
import { AddBankingWordCategorisationInstanceCommand } from '../../../../../../api/bank-categorisation/api-models';
import WithApiHelper, { WithApiHelperProps } from '../../../../../hoc/with-api-helper'
import { BankingWordCategorisationInstance, IncomeAndExpenditureCategoryType, IncomeAndExpenditureCategoryTypes, BankTransactionTypes, BankingWordCategorisationInstanceGroup } from '../../../../../../types/bank-types';
import { BlockUi, FormValidator, Button, FormikSideEffects, FormikInput, FormikSelect, FormikMultiSelect, Form } from '../../../../../shared';
import * as ObjectHelper from '../../../../../../helpers/object-helper';
import * as StringHelper from '../../../../../../helpers/string-helper';
import IncomeExpenditureClassificationChooser from './income-expenditure-classification-chooser';
import FormikPredicateBuilder from './formik-predicate-builder';
import FormikWordsOrPhrases from './formik-words-or-phrases';
import { OptionModel } from '../../../../../../types/shared-types';

interface UpdateKeywordCategorisationFormProps extends WithApiHelperProps {
  onSubmitSuccess: (instance: BankingWordCategorisationInstance) => void
  onBack: () => void
  hideHeader?: boolean
  instance?: BankingWordCategorisationInstance
  groups: BankingWordCategorisationInstanceGroup[]
}

interface UpdateKeywordCategorisationFormState {
  predicateBuilderModalOpen: boolean
  wordModelsModalOpen: boolean
}

const UpdateKeywordCategorisationForm: React.FC<UpdateKeywordCategorisationFormProps> = (props) => {

  const { onSubmitSuccess, onBack, hideHeader, instance, apiHelper, groups } = props;

  const [state, setState] = useState<UpdateKeywordCategorisationFormState>({
    predicateBuilderModalOpen: false,
    wordModelsModalOpen: false
  });

  const [submitting, setSubmitting] = useState(false);
  const [shouldAutoUpdateName, setShouldAutoUpdateName] = useState(instance ? false : true);

  apiHelper.setDefaultOnErrorAction(() => setSubmitting(false));

  const handleSubmit = (values: AddBankingWordCategorisationInstanceCommand) => {
    setSubmitting(true);
    const isUpdate = instance?.id ? true : false;
    const apiCall = isUpdate ? () => BankCategoriseApi.updateWordInstance(instance?.id!, values) : () => BankCategoriseApi.addWordInstance(values);
    apiHelper.makeCall(apiCall)
      .then(response => {
        setSubmitting(false);
        onSubmitSuccess(response.data);
      });
  };

  const validator = new FormValidator<AddBankingWordCategorisationInstanceCommand, UpdateKeywordCategorisationFormProps>({
    fields: [
      { name: 'groupName', validators: [required()] },
      { name: 'recategoriseTo', validators: [required()] },
      { name: 'name', validators: [required(), maxCharLength(255)] },
      { name: 'transactionType', validators: [] },
      { name: 'categoriesChecked', validators: [] },
      { name: 'shouldCategorisePredicateGroups', validators: [] },
      { name: 'wordsOrPhrases', validators: [] },
    ]
  });

  const initialValues: AddBankingWordCategorisationInstanceCommand = {
    groupName: instance?.group?.name as any,
    recategoriseTo: instance?.recategoriseTo as any,
    name: instance?.name as any,
    transactionType: instance?.transactionType,
    categoriesChecked: instance?.categoriesChecked,
    shouldCategorisePredicateGroups: !instance?.shouldCategorisePredicateGroups ? [] : instance.shouldCategorisePredicateGroups,
    wordsOrPhrases: !instance?.wordsOrPhrases ? [] : instance.wordsOrPhrases,
  };

  //Ensure Ids are kept for each
  initialValues.wordsOrPhrases?.forEach(e => {
    if (!e.id) e.id = v4();
  });

  //Ensure Ids are kept for each
  initialValues.shouldCategorisePredicateGroups?.forEach(e => {
    if (!e.id) e.id = v4();
  });

  const handleRecategoriseToChange = (event: React.ChangeEvent<HTMLInputElement>, formikProps: FormikProps<AddBankingWordCategorisationInstanceCommand>) => {
    if (shouldAutoUpdateName) {
      formikProps.setFieldValue(ObjectHelper.nameOf<AddBankingWordCategorisationInstanceCommand>('name'), StringHelper.addSpacesOnCaps(event.currentTarget.value))
    }
  }

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.currentTarget.value && !shouldAutoUpdateName) {
      setShouldAutoUpdateName(true);
    } else if (shouldAutoUpdateName) {
      setShouldAutoUpdateName(false);
    }
  }

  const handleSelectIAndEClassificationForCategoriesChecked = (types: IncomeAndExpenditureCategoryType[], formikProps: FormikProps<AddBankingWordCategorisationInstanceCommand>) => {
    if (!types?.length) return;
    const originalValue = !formikProps.values.categoriesChecked?.length ? [] : formikProps.values.categoriesChecked;
    const newValue = [...originalValue]
    types.forEach(t => {
      if (!newValue.some(e => e === t)) newValue.push(t);
    });
    if (newValue.length !== originalValue.length) {
      formikProps.setFieldValue(ObjectHelper.nameOf<AddBankingWordCategorisationInstanceCommand>('categoriesChecked'), newValue);
    }
  };

  return (
    <Formik<AddBankingWordCategorisationInstanceCommand>
      initialValues={validator.getInitial(initialValues)}
      validate={(values) => validator.validate(values, props)}
      onSubmit={handleSubmit}
      render={(formikProps) => {
        return (
          <BlockUi blocking={submitting} text='Submitting...'>
            {!hideHeader && <p>{!instance ? 'Add New Keyword Category Instance' : `Update ${instance.name}`}</p>}
            <Form onSubmit={formikProps.handleSubmit}>
              <FormikSelect<AddBankingWordCategorisationInstanceCommand>
                name='groupName'
                disabled={state.predicateBuilderModalOpen || state.wordModelsModalOpen}
                includeEmptyOption
                formikProps={formikProps}
                label='Group'
                options={groups.map<OptionModel<string>>(g => ({ label: g.name, value: g.name }))}
              />
              <FormikSelect<AddBankingWordCategorisationInstanceCommand>
                name='recategoriseTo'
                disabled={state.predicateBuilderModalOpen || state.wordModelsModalOpen}
                includeEmptyOption={!instance}
                formikProps={formikProps}
                onChange={(e) => handleRecategoriseToChange(e, formikProps)}
                label='Recategorise To'
                options={ObjectHelper.toOptionModels(IncomeAndExpenditureCategoryTypes, true)}
              />
              <FormikInput<AddBankingWordCategorisationInstanceCommand>
                name='name'
                disabled={state.predicateBuilderModalOpen || state.wordModelsModalOpen}
                onChange={handleNameChange}
                formikProps={formikProps}
                label='Name'
              />
              <FormikSelect<AddBankingWordCategorisationInstanceCommand>
                name='transactionType'
                disabled={state.predicateBuilderModalOpen || state.wordModelsModalOpen}
                includeEmptyOption
                formikProps={formikProps}
                label='Check Debit or Credit?'
                options={ObjectHelper.toOptionModels(BankTransactionTypes, true)}
              />
              <FormikMultiSelect<AddBankingWordCategorisationInstanceCommand>
                name='categoriesChecked'
                disabled={state.predicateBuilderModalOpen || state.wordModelsModalOpen}
                formikProps={formikProps}
                label='Categories Checked'
                placeholder='If left empty will check all categories'
                options={ObjectHelper.toOptionModels(IncomeAndExpenditureCategoryTypes, true)}
                afterLabelContent={(
                  <IncomeExpenditureClassificationChooser
                    disabled={state.predicateBuilderModalOpen || state.wordModelsModalOpen}
                    className='mb-1'
                    onSelect={(t) => handleSelectIAndEClassificationForCategoriesChecked(t, formikProps)}
                  />
                )}
              />
              <FormikPredicateBuilder
                formikProps={formikProps}
                disabled={state.wordModelsModalOpen}
                className='mb-4'
                onModalOpenChange={(open) => setState(ps => ({ ...ps, predicateBuilderModalOpen: open }))}
              />
              <FormikWordsOrPhrases
                disabled={state.predicateBuilderModalOpen}
                formikProps={formikProps}
                className='mb-4'
                onModalOpenChange={(open) => setState(ps => ({ ...ps, wordModelsModalOpen: open }))}
              />
              <div>
                <Button
                  className='w-100 mb-2'
                  type='submit'
                  bsVariant='primary'
                  disabled={state.predicateBuilderModalOpen || state.wordModelsModalOpen}
                  text={!instance ? 'Add' : 'Update'}
                />
                <Button
                  className='w-100'
                  bsVariant='secondary'
                  data-test='auto-form-back-button'
                  onClick={onBack}
                  disabled={state.predicateBuilderModalOpen || state.wordModelsModalOpen}
                  text='Back'
                />
              </div>
            </Form>
            <FormikSideEffects focusInputOnSubmitFail />
          </BlockUi>
        )
      }}
    />
  );
}

export default WithApiHelper(UpdateKeywordCategorisationForm);
