import React from 'react';

import { ModalSimple } from '../../../../shared';
import { Bank } from '../../../../../types/bank-types';
import EditBankPrvoiderMappingForm from './update-bank-form';

interface UpdateBankModalProps {
  bank: Bank
  submitting?: boolean
  onSubmitSuccess: (bank: Bank) => void
  open: boolean
  onClose: () => void
}

const UpdateBankModal: React.FC<UpdateBankModalProps> = (props) => {

  const { bank, open, onClose, ...rest } = props;

  if (!open) return null;

  return (
    <ModalSimple
      open
      onClose={onClose}
      header={`Update ${bank.name}`}
    >
      <EditBankPrvoiderMappingForm
        hideHeader
        bank={bank}
        {...rest}
        onBack={onClose}
      />
    </ModalSimple>
  );
}

export default UpdateBankModal;
