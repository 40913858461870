import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { useToasts } from 'react-toast-notifications';

import { DataTable } from '../../../shared';
import { BankProvider } from '../../../../types/bank-types';
import * as BankApi from '../../../../api/bank';
import { faSync, faRandom, faDownload } from '@fortawesome/free-solid-svg-icons';
import ToastHelper from '../../../../helpers/toast-helper';
import ApiHelper from '../../../../helpers/api-helper';
import LinkToBankModal from './link-to-bank/link-to-bank-modal';
import DashboardPage from '../../../hoc/dashboard-page';

interface BanksPageModalProps {
  type: 'link-to-bank'
  open: boolean
  provider?: BankProvider
}

interface UnMappedProvidersState {
  providers: BankProvider[]
  loadingMessage?: string
  modal: BanksPageModalProps
}

interface UnMappedProvidersProps extends RouteComponentProps {
}

const UnMappedProviders: React.FC<UnMappedProvidersProps> = (props) => {

  const reloadMessage = 'Loading Providers...';

  const [state, setState] = useState<UnMappedProvidersState>({
    providers: [],
    loadingMessage: reloadMessage,
    modal: { open: false, type: 'link-to-bank' }
  });

  const toastHelper = new ToastHelper(useToasts());
  const apiHelper = new ApiHelper(toastHelper, { defaultOnErrorAction: () => setState((ps) => ({ ...ps, loadingMessage: undefined })) });

  const reload = (partialState?: Partial<UnMappedProvidersState>) => {
    setState((ps) => {
      let newState = { ...ps, loading: false };
      if (partialState) newState = { ...newState, ...partialState };
      return newState;
    });
    apiHelper.makeCall(() => BankApi.listProviders({ withNoMapping: true }))
      .then(response => setState((ps) => ({ ...ps, loadingMessage: undefined, providers: response.data })))
  }

  const handlePopulate = () => {
    setState((ps) => ({ ...ps, loadingMessage: 'Populating providers from client...' }));
    apiHelper.makeCall(() => BankApi.populateProviders())
      .then(() => reload());
  }

  const handleSync = () => {
    setState((ps) => ({ ...ps, loadingMessage: 'Syncing providers to banks...' }));
    apiHelper.makeCall(() => BankApi.sync())
      .then(() => reload());
  }

  useEffect(() => {
    reload();
  }, []);

  return (
    <React.Fragment>
      <DataTable
        loading={state.loadingMessage !== undefined}
        pageOptions={{
          itemsPerPage: 20,
        }}
        toolbarProps={{
          actionButtons: [
            { key: 'reload', icon: faSync, toolTip: 'Reload', onClick: reload },
            { key: 'popultatee', icon: faDownload, toolTip: 'Populate providers from client', onClick: handlePopulate },
            { key: 'sync', icon: faRandom, toolTip: 'Auto sync to banks', onClick: handleSync },
          ],
        }}
        data={!state.providers.length ? [] : [...state.providers]}
        dataIdProperty='id'
        columns={[
          { key: 'name', header: 'Name', searchable: true },
          { key: 'type', header: 'Provider', searchable: true },
        ]}
        actions={[{
          icon: faRandom,
          variant: 'transparent',
          onClick: (provider) => setState((ps) => ({ ...ps, modal: { type: 'link-to-bank', provider: provider, open: true } })),
          tooltip: 'Link to bank'
        }]}
      />
      <LinkToBankModal
        open={state.modal.type === 'link-to-bank' && state.modal.open}
        onSubmitSuccess={() => reload({ modal: { ...state.modal, open: false } })}
        onClose={() => setState((ps) => ({ ...ps, modal: { ...ps.modal, open: false } }))}
        provider={state.modal.provider!}
      />
    </React.Fragment>
  );
}

export default DashboardPage(UnMappedProviders);
