import React from 'react';
import { AxiosResponse } from 'axios';
import { AddRebuildProjectionJobModel } from '../../../../api/projection/api-models';
import { AutoForm } from '../../../shared';
import * as ProjectionApi from '../../../../api/projection';
import { required } from '../../../shared/form/validators';
import { toOptionModels } from '../../../../helpers/object-helper';
import { ProjectionTypes, RebuildProjectionJob } from '../../../../types/projection-types';

interface AddRebuildJobFormProps {
  onSubmitSuccess: (bank: RebuildProjectionJob) => void
  hideHeader?: boolean
  onBack: () => void
}

const AddRebuildJobForm: React.FC<AddRebuildJobFormProps> = (props) => {

  const { onSubmitSuccess, hideHeader, onBack } = props;

  return (
    <AutoForm<AddRebuildProjectionJobModel, AxiosResponse<RebuildProjectionJob>>
      header={hideHeader ? undefined : 'Add Rebuild Projection Job'}
      onSubmitPromise={v => ProjectionApi.add(v)}
      onBack={onBack}
      onSubmitSuccess={(r) => onSubmitSuccess(r.data)}
      rows={[
        { columns: [{ field: { name: 'type', type: 'Select', display: 'Projection', options: { validators: [required()], options: toOptionModels(ProjectionTypes, true) } } }] },
      ]}
      submitButtonText='Add'
      toastOnError
    />
  );
}

export default AddRebuildJobForm;
