import React from 'react';
import { RouteComponentProps } from 'react-router';

import { TabPages, StandardPage, Button } from '../../shared';
import Banking from './banking';
import Centrelink from './centrelink';
import Assessments from './assessments';
import WithAdminUser, { withAdminUserProps } from '../../hoc/with-admin-user';
import { useAppContext } from '../../app';

interface ConsumerUiProps extends RouteComponentProps, withAdminUserProps {
}

const ConsumerUi: React.FC<ConsumerUiProps> = (props) => {

  const { match: { path }, history } = props;
  const { user } = useAppContext();

  if (!user || !user.currentTenant) {
    return (
      <StandardPage
        image='Oops'
        noHeight
        title={'Consumer UI Tests'}
        description={'User has no tenant assigned.  Ensure the Api has tenant override set'}
      >
        <Button onClick={() => history.push('/')}>Back to Home</Button>
      </StandardPage>
    )
  }

  return (
    <TabPages
      redirectFromBase
      tabs={[
        { label: 'Banking', link: `${path}/banking`, component: Banking },
        { label: 'Centrelink', link: `${path}/centrelink`, component: Centrelink },
        { label: 'Assessments', link: `${path}/assessments`, component: Assessments, exact: true },

      ]}
    />
  );
}

export default WithAdminUser(ConsumerUi);
