import React from 'react';
import { CardSimple, Alert, FormDisplay, Chip } from '../../../shared';
import { AssessmentDetail } from '../../../../types/assessment-types';
import AssessmentRuleOutcomeListItem from './assessment-rule-outcome-list-item';
import { Row, Col } from 'reactstrap';

interface AssessmentCardProps {
  assessment: AssessmentDetail
}
const AssessmentCard: React.FC<AssessmentCardProps> = ({ assessment }) => {

  const hasOutcomes = assessment.ruleOutcomes?.length ? true : false;
  return (
    <CardSimple header={`Assessment: ${assessment.assessmentId}`}>
      <hr />
      <Row>
        <Col sm={6}><FormDisplay label='Status'><div><Chip label={assessment.status} /></div></FormDisplay></Col>
        <Col sm={6}><FormDisplay label='Overall Outcome'><div><Chip label={assessment.overallOutcome} /></div></FormDisplay></Col>
      </Row>
      <Row>
        <Col sm={12}><FormDisplay label='Rule Group'><div><Chip label={assessment.ruleGroup} /></div></FormDisplay></Col>
      </Row>
      <Row>
        <Col>
          <h6>Rules Outcomes</h6>
          {!hasOutcomes && <Alert center variant='info' message='No rule outcomes found' />}
          {hasOutcomes && assessment.ruleOutcomes.map((ro, i) => <AssessmentRuleOutcomeListItem key={i} ruleOutcome={ro} />)}
        </Col>
      </Row>
    </CardSimple>
  );
}

export default AssessmentCard;
