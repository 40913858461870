import axios from 'axios';
import getSettings from '../../settings'
import { AddBankingWordCategorisationInstanceCommand, AddBankingWordCategorisationInstanceGroupCommand, TestBankTransactionRequest, TestBankTransactionResult } from './api-models';
import { BankingWordCategorisationInstance, BankingWordCategorisationInstanceGroup, BankingWordCategorisationInstanceOptions } from '../../types/bank-types';
import { BaseFilterModel, ResultSet } from '../../types/shared-types';

const settings = getSettings();

export const relativeUrls = {
  testTransaction: '/test-transaction',
  listWordInstances: '/word-instance',
  getWordInstanceOptions: '/word-instance/options',
  addWordInstance: '/word-instance',
  updateWordInstance: (id: number) => `/word-instance/${id}`,
  deleteWordInstance: (id: number) => `/word-instance/${id}`,
  listWordInstanceGroups: '/word-instance-group',
  addWordInstanceGroup: '/word-instance-group',
  updateWordInstanceGroup: (id: number) => `/word-instance-group/${id}`,
  deleteWordInstanceGroup: (id: number) => `/word-instance-group/${id}`,
}

const root = `${settings.baseApiUrl}/admin/bank/categorisation`;

export const testTransaction = (request: TestBankTransactionRequest) => {
  return axios.post<TestBankTransactionResult[]>(`${root}${relativeUrls.testTransaction}`, request);
}

export const listWordInstances = (filter?: BaseFilterModel) => {
  return axios.get<ResultSet<BankingWordCategorisationInstance>>(`${root}${relativeUrls.listWordInstances}`, { params: filter });
};

export const getWordInstanceOptions = () => {
  return axios.get<BankingWordCategorisationInstanceOptions>(`${root}${relativeUrls.getWordInstanceOptions}`);
};

export const addWordInstance = (model: AddBankingWordCategorisationInstanceCommand) => {
  return axios.post<BankingWordCategorisationInstance>(`${root}${relativeUrls.addWordInstance}`, model);
};

export const updateWordInstance = (id: number, model: AddBankingWordCategorisationInstanceCommand) => {
  return axios.put<BankingWordCategorisationInstance>(`${root}${relativeUrls.updateWordInstance(id)}`, model);
};

export const deleteWordInstance = (id: number) => {
  return axios.delete(`${root}${relativeUrls.deleteWordInstance(id)}`);
};

export const listWordInstanceGroups = (filter?: BaseFilterModel) => {
  return axios.get<ResultSet<BankingWordCategorisationInstanceGroup>>(`${root}${relativeUrls.listWordInstanceGroups}`, { params: filter });
};

export const addWordInstanceGroup = (model: AddBankingWordCategorisationInstanceGroupCommand) => {
  return axios.post<BankingWordCategorisationInstanceGroup>(`${root}${relativeUrls.addWordInstanceGroup}`, model);
};

export const updateWordInstanceGroup = (id: number, model: AddBankingWordCategorisationInstanceGroupCommand) => {
  return axios.put<BankingWordCategorisationInstanceGroup>(`${root}${relativeUrls.updateWordInstanceGroup(id)}`, model);
};

export const deleteWordInstanceGroup = (id: number) => {
  return axios.delete(`${root}${relativeUrls.deleteWordInstanceGroup(id)}`);
};