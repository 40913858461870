import axios from 'axios';
import getSettings from '../../settings'
import { UpdateRoleModel } from './api-models';
import { BaseFilterModel } from '../../types/shared-types';
import { UserRole } from '../../types/user-types';

const settings = getSettings();

export const relativeUrls = {
  list: '',
  update: (name: string) => `/${name}`,
}

const root = `${settings.baseApiUrl}/admin/role`;

export const list = (filter?: BaseFilterModel) => {
  return axios.get<UserRole[]>(`${root}${relativeUrls.list}`, { params: filter });
};

export const update = (name: string, model: UpdateRoleModel) => {
  return axios.put<UserRole>(`${root}${relativeUrls.update(name)}`, model);
};