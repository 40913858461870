import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import DashboardPage from '../../../hoc/dashboard-page';
import WithApiHelper, { WithApiHelperProps } from '../../../hoc/with-api-helper';
import { BlockUi, StandardPage } from '../../../shared';
import * as AssessmentApi from '../../../../api/assessment';
import AssessmentCard from './assessment-card';
import { Container, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { AssessmentDetail } from '../../../../types/assessment-types';

interface RouteParams { id: string }
interface AssessmentDetailProps extends RouteComponentProps<RouteParams>, WithApiHelperProps {
}

interface AssessmentDetailState {
  assessment?: AssessmentDetail
  loading: boolean
  blockingMessage?: string
}

const AssessmentDetailPage: React.FC<AssessmentDetailProps> = ({ apiHelper, match: { params } }) => {

  const [state, setState] = useState<AssessmentDetailState>({
    assessment: undefined,
    loading: true,
  });

  apiHelper.setDefaultOnErrorAction(() => setState(ps => ({ ...ps, blockingMessage: undefined, loading: false })));

  const reload = () => {
    setState((ps) => ({ ...ps, loading: true }));
    apiHelper.makeCall(() => AssessmentApi.get(params.id))
      .then((r) => setState((ps) => ({ ...ps, loading: false, assessment: r.data })));
  }

  //On Start
  useEffect(() => reload(), []);

  if (state.loading) {
    return <BlockUi blocking text='Loading Assessment...' />;
  }

  if (!state.loading && !state.assessment) {
    return <StandardPage title='Oops!' description='Could not find Assessment.  Please refresh the page' />
  }

  return (
    <React.Fragment>
      <Breadcrumb>
        <BreadcrumbItem><Link to='/dashboard/assessments'>Assessments</Link></BreadcrumbItem>
        <BreadcrumbItem active>{state.assessment?.assessmentId}</BreadcrumbItem>
      </Breadcrumb>
      <Container>
        <AssessmentCard assessment={state.assessment!} />
      </Container>
    </React.Fragment>

  );
}

export default WithApiHelper(DashboardPage(AssessmentDetailPage));
