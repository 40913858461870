import { OptionModel, BaseFormField } from "./shared-types"

export interface RuleGroup {
  id: number
  name: string
  description: string
  parentId?: number
  ruleInstances: Array<RuleInstance>
  createdAt: Date
  createdBy: string
  modifiedAt: Date
  modifiedBy: string
  children: Array<RuleGroup>
}

export interface RuleInstance {
  id: number
  version: number
  ruleDefinitionType: RuleDefinitionType
  name: string
  description: string
  passedOutcomeMessageFormat: string
  failedOutcomeMessageFormat: string
  status: RuleStatus
  parameters: string
  createdAt: Date
  createdBy: string
  modifiedAt: Date
  modifiedBy: string
  ruleGroupId: number
  ruleGroup: RuleGroup
}

export type RuleDefinitionType =
  '' | 'AgeGreaterThanOrEqualToExpectedV1' | 'AgeLessThanOrEqualToExpectedV1' |
  'BankStatementMustNotContainSrssV1' | 'CustomerMustNotBeUnderPart9V1' |
  'CustomerMustNotHaveTerminatedPart9V1' | 'FallbackRuleV1' | 'NameOnBankAccountMustBeValidV1' |
  'WeeklyIncomeGreaterOrEqualToV1' | 'NumberOfCurrentLoansLessThanExpectedV1' |
  'NumberOfActiveCashAdvancesLessThanExpectedV1' | 'NinetyDaysOfBankingRequiredV1' |
  'CustomerMustBeAnAustralianPermanentResidentV1' | 'CentrelinkStatementMustNotContainSpecialPaymentsV1' |
  'CustomerMustNotBeKRatedV1' | 'CustomerMustNotTriggerGamblingHardstopsV1' |
  'CustomerMustNotReceiveCentrelinkIncomeManagementV1' | 'BankStatementMustContainEvidenceOfEmploymentSalaryV1';

export const RuleDefinitionTypes = [
  'AgeGreaterThanOrEqualToExpectedV1', 'AgeLessThanOrEqualToExpectedV1',
  'BankStatementMustNotContainSrssV1', 'CustomerMustNotBeUnderPart9V1',
  'CustomerMustNotHaveTerminatedPart9V1', 'FallbackRuleV1', 'NameOnBankAccountMustBeValidV1',
  'WeeklyIncomeGreaterOrEqualToV1', 'NumberOfCurrentLoansLessThanExpectedV1',
  'NumberOfActiveCashAdvancesLessThanExpectedV1', 'NinetyDaysOfBankingRequiredV1',
  'CustomerMustBeAnAustralianPermanentResidentV1', 'CentrelinkStatementMustNotContainSpecialPaymentsV1',
  'CustomerMustNotBeKRatedV1', 'CustomerMustNotTriggerGamblingHardstopsV1',
  'CustomerMustNotReceiveCentrelinkIncomeManagementV1', 'BankStatementMustContainEvidenceOfEmploymentSalaryV1'
];

export type RuleStatus = '' | 'Enabled' | 'Disabled' | 'Pilot' | 'Deprecated';
export const RuleStatuses = ['Enabled', 'Disabled', 'Pilot', 'Deprecated'];

export interface RuleFormRow {
  columns: RuleFormColumn[]
}

export interface RuleFormColumn {
  extraSmallSize?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  smallSize?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  mediumSize?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  largeSize?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  extraLargeSize?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  field: RuleFormField
}

export type RuleFormFieldType = 'Hidden' | 'TextInput' | 'NumberInput' | 'PasswordInput' | 'TagInput' | 'Select' | 'Checkbox' | 'MultiSelect' | 'Captcha' | 'DateInput' | 'TimeInput'

export interface RuleFormField {
  type: RuleFormFieldType
  name: string
  label?: string
  description?: string
  placeholder?: string
  value?: string
  imageData?: string
  width?: number
  height?: number
  disabled?: boolean
  options?: OptionModel<string>[]
  validations?: RuleFormValidation[]
}

export type RuleFormValidationType = 'Required' | 'MaxCharLength' | 'MinCharLength'

export interface RuleFormValidation {
  type: RuleFormValidationType
  overriddedErrorMessage?: string
  numberParam1?: number
}

export interface RuleDefinitionData {
  type: RuleDefinitionType
  formFields: BaseFormField[]
}

export interface RuleInstanceOptions {
  ruleGroups: RuleGroup[]
  ruleDefinitions: RuleDefinitionData[]
}