import React from 'react';
import v4 from 'uuid';

import { WordCategorisationModel } from '../../../../../../../../types/bank-types';
import { AutoForm } from '../../../../../../../shared';
import { requiredArray, numberGreaterThan, numberLessThanOrEqual } from '../../../../../../../shared/form/validators';

interface UpdateWordModelFormProps {
  onSubmit: (instance: WordCategorisationModel) => void
  onCancel: () => void
  className?: string
  value?: WordCategorisationModel
}

const UpdateWordModelForm: React.FC<UpdateWordModelFormProps> = (props) => {

  const { onSubmit, value, onCancel, className } = props;

  const initialValue: WordCategorisationModel = {
    id: value?.id ?? v4(),
    include: value?.include ?? [],
    exclude: value?.exclude ?? [],
    score: value?.score ?? 100,
    ignoreMatchIfHasNumbers: value?.ignoreMatchIfHasNumbers ?? false,
    matchOnIndividualWords: value?.matchOnIndividualWords ?? false,
    allIncludeWordsMustMatch: value?.allIncludeWordsMustMatch ?? false
  };

  return (
    <AutoForm<WordCategorisationModel>
      className={className}
      initialValues={initialValue}
      rows={[
        { columns: [{ field: { name: 'include', display: 'Words to Look For', type: 'TagInput', options: { validators: [requiredArray()] } } }] },
        { columns: [{ field: { name: 'exclude', display: 'Words to Exclude', type: 'TagInput', } }] },
        { columns: [{ field: { name: 'matchOnIndividualWords', display: 'Match on Individual Words', type: 'Checkbox', } }] },
        { columns: [{ field: { name: 'ignoreMatchIfHasNumbers', display: 'Ignore Match if has Numbers', type: 'Checkbox', } }] },
        { columns: [{ field: { name: 'allIncludeWordsMustMatch', display: 'All Include Words Must Match', type: 'Checkbox', } }] },
        { columns: [{ field: { name: 'score', display: 'Score on Match', type: 'NumberInput', options: { validators: [numberGreaterThan(0), numberLessThanOrEqual(100)] } } }] },
      ]}
      onSubmitPromise={(v) => Promise.resolve(v)}
      onSubmitSuccess={onSubmit}
      onBack={onCancel}
    />
  );
}

export default UpdateWordModelForm;
