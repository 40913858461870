import {
    AddBankingWordCategorisationInstanceCommand,
    AddBankingWordCategorisationInstanceGroupCommand
} from "../api/bank-categorisation/api-models";
import {Moment} from "moment";

export interface Bank {
    id: number;
    name: string;
    aliases: string[];
    providers: BankProvider[];
    obProviders: OpenBankingProviderInstitution[];
    inactive: boolean;
    openBankingSupported: boolean;
    createdAt: Date;
    createdBy: string;
    modifiedAt: Date;
    modifiedBy: string;
}

export interface ConsumerBank {
    id: number;
    name: string;
    aliases?: string[];
    openBankingSupported: boolean;
    providerIds: number[];
    obProviderIds: number[];
}

export interface BankProvider {
    id: number;
    type: BankProviderType;
    name: string;
    bankId?: number;
    bank?: Bank;
    bankProviderId: string;
    requiresPreload?: boolean;
    weighting: number;
    inactive?: boolean;
}

export interface OpenBankingProviderInstitution {
    id: number;
    type: ObBankProviderType;
    name: string;
    bankId?: number;
    bank?: Bank;
    bankProviderId: string;
    weighting: number;
    inactive?: boolean;
}

export type ObBankProviderType = "BasiqV3";

export type BankProviderType = "Proviso" | "Yodlee";
export const BankProviderTypes = ["Proviso", "Yodlee"];

export interface BankAccount {
    id: number;
    name?: string;
    bsb?: number;
    accountNumber?: number;
    accountHolder?: string;
    accountType?: string;
    currentBalance: number;
    availableBalance: number;
}

export interface BankStatementAccount {
    id: number;
    accountId: number;
    name: string;
    accountNumber: string;
    bsb: string;
    currentBalance: number;
    available?: number;
    accountHolder: string;
    accountAddress?: string;
    accountType?: string;
    openingBalance?: string;
    closingBalance?: string;
    transactions: BankStatementTransaction[];
}

export type BankTransactionType = "Debit" | "Credit";
export const BankTransactionTypes = ["Debit", "Credit"];

export interface BankRecategoriseAction {
    from: string
    to: string
    engineName: string
    score: number
    otherMatches: BankRecategoriseAction[]
}

export interface BankStatementTransaction {
    id: number;
    accountId: number;
    date: string;
    description?: string;
    rawCategory: string;
    category?: string;
    amount: number;
    balance?: number;
    type: BankTransactionType;
}

export interface CategorisedBankAccount {
    transactions: CategorisedBankTransaction[];
}

export interface CategorisedBankTransaction {
    id: number;
    date: Date;
    description: string;
    category: string;
    amount: number;
    type: string;
    lastTransactionOfTheDay: boolean;
    matchingInfo: MatchingInfo;
    balance: number;
    incomeAndExpenditureCategory: string;
    providerCategory: string;
}

export interface MatchingInfo {
    left: string
    right: string
    weight: number
}

export interface MatchingInfo {
    left: string
    right: string
    weight: number
}

export type IncomeAndExpenditureCategoryType =
    'EmploymentIncome'
    | 'CentrelinkIncome'
    | 'SelfEmploymentIncome'
    | 'OtherIncome'
    |
    'CashConvertersLoans'
    | 'Sacc'
    | 'Creditor'
    | 'Ewa'
    | 'CreditCard'
    | 'Accommodation'
    | 'BasicHousingAndPropertyExpensesIncUtils'
    |
    'Communications'
    | 'Groceries'
    | 'RecreationAndEntertainment'
    | 'ClothingAndPersonalCare'
    | 'MedicalAndHealth'
    | 'Transport'
    |
    'EducationAndChildcare'
    | 'Insurance'
    | 'LivingExpenses'
    | 'OtherExpense'
    | 'CashWithdrawals'
    | 'Dishonours'
    | 'BankingFees'
    |
    'LoanDisbursements'
    | 'Gambling'
    | 'Bnpl'
    | 'Liquor'
    | 'CrisisEmergencyPayments'
    | 'Transfers'
    | 'Uncategorised'

export const IncomeAndExpenditureCategoryTypes = ['EmploymentIncome', 'CentrelinkIncome', 'SelfEmploymentIncome', 'OtherIncome',
    'CashConvertersLoans', 'Sacc', 'Creditor', 'Ewa', 'CreditCard', 'Accommodation', 'BasicHousingAndPropertyExpensesIncUtils',
    'Communications', 'Groceries', 'RecreationAndEntertainment', 'ClothingAndPersonalCare', 'MedicalAndHealth', 'Transport',
    'EducationAndChildcare', 'Insurance', 'LivingExpenses', 'OtherExpense', 'CashWithdrawals', 'Dishonours', 'BankingFees',
    'LoanDisbursements', 'Gambling', 'Bnpl', 'Liquor', 'CrisisEmergencyPayments', 'Transfers', 'Uncategorised'].sort();

interface IncomeExpenditureClassificationMapsProps {
    income: IncomeAndExpenditureCategoryType[]
    expenditure: IncomeAndExpenditureCategoryType[]
    other: IncomeAndExpenditureCategoryType[]
}

export const IncomeExpenditureClassificationMaps: IncomeExpenditureClassificationMapsProps = {
    income: [
        'EmploymentIncome', 'CentrelinkIncome', 'SelfEmploymentIncome', 'OtherIncome'
    ],
    expenditure: [
        'Accommodation', 'BasicHousingAndPropertyExpensesIncUtils', 'Communications', 'Groceries',
        'RecreationAndEntertainment', 'ClothingAndPersonalCare', 'MedicalAndHealth', 'Transport', 'EducationAndChildcare',
        'Insurance', 'OtherExpense', 'Creditor', 'Ewa', 'CreditCard', 'Sacc', 'LivingExpenses', 'CashConvertersLoans', 'Liquor'
    ],
    other: [
        'Transfers', 'CashWithdrawals', 'Dishonours', 'LoanDisbursements', 'Uncategorised',
        'Gambling', 'Bnpl', 'CrisisEmergencyPayments', 'BankingFees'
    ]
}

export interface BankingCategorisationDataSnapshot {
    instances: AddBankingWordCategorisationInstanceCommand[]
    groups: AddBankingWordCategorisationInstanceGroupCommand[]
}


export interface BankingWordCategorisationInstanceGroup {
    id: number
    name: string
    order: number
}

export interface BankingWordCategorisationInstanceOptions {
    groups: BankingWordCategorisationInstanceGroup[]
}

export interface BankingWordCategorisationInstance {
    id: number
    groupId: number
    group?: BankingWordCategorisationInstanceGroup
    recategoriseTo: IncomeAndExpenditureCategoryType
    name: string
    transactionType?: BankTransactionType
    categoriesChecked?: IncomeAndExpenditureCategoryType[]
    shouldCategorisePredicateGroups: BankingTransactionPredicateModel[]
    wordsOrPhrases?: WordCategorisationModel[]
}

export interface WordCategorisationModel {
    id: string
    include: string[]
    exclude: string[]
    allIncludeWordsMustMatch?: boolean
    matchOnIndividualWords?: boolean
    ignoreMatchIfHasNumbers?: boolean
    score: number
}

export type BankingTransactionPredicateType =
    'IsDebit'
    | 'IsCredit'
    | 'DescriptionRegex'
    | 'DescriptionContains'
    | 'DescriptionDoesNotContain'
    |
    'DescriptionDoesNotContainArray'
    | 'DescriptionContainsArray'
    | 'AmountEquals'
    | 'AmountLessThan'
    | 'AmountGreaterThan'
    | 'AmountLessThanOrEqual'
    |
    'AmountGreaterThanOrEqual'
    | 'AmountBetween';

export const BankingTransactionPredicateTypes = ['IsDebit', 'IsCredit', 'DescriptionRegex', 'DescriptionContains', 'DescriptionDoesNotContain',
    'DescriptionDoesNotContainArray', 'DescriptionContainsArray', 'AmountEquals', 'AmountLessThan', 'AmountGreaterThan', 'AmountLessThanOrEqual',
    'AmountGreaterThanOrEqual', 'AmountBetween'];

export interface BankingTransactionPredicate {
    type: BankingTransactionPredicateType
    options: BankingTransactionPredicateOptions
}

export type BankingTransactionPredicateJoiner = 'AND' | 'OR'
export const BankingTransactionPredicateJoiners = ['AND', 'OR'];

export interface BankingTransactionPredicateModel {
    id: string
    predicates: BankingTransactionPredicate[]
    after: BankingTransactionPredicateJoiner
}

export interface BankingTransactionPredicate {
    type: BankingTransactionPredicateType
    options: BankingTransactionPredicateOptions
}

export interface BankingTransactionPredicateOptions {
    stringValue1?: string
    stringValue2?: string
    stringArrayValue1?: string[]
    stringArrayValue2?: string[]
    numberValue1?: number
    numberValue2?: number
    dateValue1?: string
    dateValue2?: string
}

export interface BankActivityStatistics {
    bankRetrieved: BankActivityModel[]
    loggedIn: BankActivityModel[]
    loginFailed: BankActivityModel[]
    mfaRequested: BankActivityModel[]
    statementDownloaded: BankActivityModel[]
    statementDownloadFailed: BankActivityModel[]
}

export interface BankStatisticDateGroup {
    bank: string
    data: BankStatisticDateModel[]
}

export interface BankStatisticDateModel {
    moments: Moment[]
    date: string
    amount: number
}


export interface BankActivityModel {
    data: BankEventData
    errorMessage?: string
}

export interface BankEventData {
    reference: string
    date: string
    bankId: number
    bankName: string
    bankProviderId: string
    bankProviderTypeId: string
    bankProviderType: string
}

export interface OpenBankingData {
    accounts: BankStatementAccount[]
}