import React from 'react';
import { useToasts } from 'react-toast-notifications';
import { AxiosResponse } from 'axios';
import * as CentrelinkConsumerApi from '../../../../../../api/centrelink-consumer';
import { LoginToCentrelinkRequest, LoginToCentrelinkResponse, CentrelinkServiceType } from '../../../../../../api/centrelink-consumer/api-models';
import { Form } from "../../../../../../types/form-types";
import { AutoForm, CardSimple } from '../../../../../shared';
import ToastHelper from '../../../../../../helpers/toast-helper';
import { Dictionary, ResultWrapper } from '../../../../../../types/shared-types';
import { getFormDto } from '../../../../../../helpers/form-helper';

interface MfaFormProps {
  form: Form
  token: string
  onBack: () => void
  type?: CentrelinkServiceType
  onSuccess: (response: LoginToCentrelinkResponse) => void
  formKeys: string[]
  reference?: string
  providerId?: number
}

const MfaForm: React.FC<MfaFormProps> = ({ type, form, token, onBack, onSuccess, formKeys, reference, providerId }) => {
  const toastHelper = new ToastHelper(useToasts());

  const handleSubmit = (formValues: any) => {
    const mappedFormValues: Dictionary<string> = {};

    Object.keys(formValues).forEach((key) => {
      if (formKeys.some(e => e === key)) {
        mappedFormValues[key] = formValues[key];
      }
    });

    const request: LoginToCentrelinkRequest = {
      type: type,
      reference: reference,
      formValues: mappedFormValues,
      token: token,
      providerId: providerId,
    };

      var result = CentrelinkConsumerApi.mfa(request);
      return result;
  };

  const handleSuccess = (response: AxiosResponse<ResultWrapper<LoginToCentrelinkResponse>>) => {
    if (response.data.success) {
      onSuccess(response.data.data!);
    } else {
      const error = response.data.error || 'An error occured'
      toastHelper.error(`${error} - Please login again`);
    }
  }

  const formDto = getFormDto(form);
  return (
    <CardSimple>
      <AutoForm
        variant='inside-card'
        submittingMessage='Logging in...'
        formId='centrelink-mfa'
        onSubmitPromise={handleSubmit}
        onSubmitSuccess={handleSuccess}
        submitButtonText="Login"
        rows={formDto.rows}
        initialValues={formDto.initialValues}
        header='Centrelink'
        headerCentre
        onBack={onBack}
      />
    </CardSimple>
  );
};

export default MfaForm;