import React from 'react';
import { ModalSimple } from '../../../../shared';
import { RuleGroup } from '../../../../../types/rule-types';
import UpdateRuleGroupForm from './update-rule-group-form'

interface UpdateRuleGroupModalProps {
  ruleGroup: RuleGroup
  allRuleGroups: RuleGroup[]
  onSubmitSuccess: (updateRuleGroup: RuleGroup) => void
  open: boolean
  onClose: () => void
}

const UpdateRuleGroupModal: React.FC<UpdateRuleGroupModalProps> = (props) => {

  const { open, ruleGroup, allRuleGroups, onClose, ...rest } = props;

  if (!open) return null;

  return (
    <ModalSimple
      open
      onClose={onClose}
      header={`Update Rule Group`}
    >
      <UpdateRuleGroupForm
        ruleGroup={ruleGroup}
        allRuleGroups={allRuleGroups}
        hideHeader
        {...rest}
        onBack={onClose}
      />
    </ModalSimple>
  );
}

export default UpdateRuleGroupModal;
