import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { faSync, faEdit, faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';
import DashboardPage from '../../../hoc/dashboard-page';
import { RuleGroup } from '../../../../types/rule-types';
import * as RuleApi from '../../../../api/rule';
import { DataTableAction } from '../../../shared/table/data-table-actions';
import { DataTable } from '../../../shared';
import AddRuleGroupModal from './add-rule-group/add-rule-group-modal'
import UpdateRuleGroupModal from './update-rule-group/update-rule-group-modal'
import WithApiHelper, { WithApiHelperProps } from '../../../hoc/with-api-helper';

interface RuleGroupPageModalProps {
  type: 'update' | 'delete' | 'add'
  open: boolean
  ruleGroup?: RuleGroup
}

interface RuleGroupState {
  ruleGroups: RuleGroup[]
  pageLoaded: boolean
  loadingMessage?: string
  modal: RuleGroupPageModalProps
}

interface RuleGroupProps extends RouteComponentProps, WithApiHelperProps {
}

const RuleGroups: React.FC<RuleGroupProps> = ({ apiHelper, toastHelper }) => {

  const reloadMessage = 'Loading Rule Groups...';

  const [state, setState] = useState<RuleGroupState>({
    ruleGroups: [],
    pageLoaded: false,
    loadingMessage: reloadMessage,
    modal: { type: 'add', open: false }
  });

  apiHelper.setDefaultOnErrorAction(() => setState(ps => ({ ...ps, loadingMessage: undefined })));

  //Reload actions
  const reload = () => {
    return apiHelper.makeCall(() => RuleApi.listRuleGroups(), { skipDefaultOnErrorAction: true })
      .then(response => Promise.resolve(response.data));
  }

  const reloadAndSetState = () => {
    setState((ps) => ({ ...ps, loadingMessage: reloadMessage }));
    reload().then(ruleGroups => setState((ps) => ({ ...ps, loadingMessage: undefined, ruleGroups: ruleGroups.results })))
  }

  //On Start
  useEffect(() => {
    reload()
      .then((e) => setState((ps) => ({ ...ps, pageLoaded: true, ruleGroups: e.results, loadingMessage: undefined })))
      .catch(() => setState((ps) => ({ ...ps, pageLoaded: true, loadingMessage: undefined })))
  }, []);

  const getRowActions = () => {
    const actions: DataTableAction<RuleGroup>[] =
      [{
        icon: faEdit,
        variant: 'transparent',
        onClick: (d) => setState((ps) => ({ ...ps, modal: { open: true, ruleGroup: d, type: 'update' } })),
        tooltip: 'Update'
      },
      {
        icon: faTrash,
        variant: 'transparent',
        onClick: (d) => handleDelete(d.id),
        tooltip: 'Delete',
        options: { isConfirm: true, modalHeader: 'Are You Sure You Want to Delete this Rule Group?', modalYesText: 'Delete', modalNoText: 'Cancel' }
      }];

    return actions;
  }

  const handleAddRuleGroupClick = () => {
    setState((ps) => ({ ...ps, modal: { open: true, type: 'add' } }));
  }

  const handleAddSuccess = (addRuleGroup: RuleGroup) => {
    toastHelper.success(`${addRuleGroup.name} successfully created`);
    setState(ps => {
      const newState = { ...ps };
      newState.modal.open = false;
      newState.ruleGroups.push(addRuleGroup);
      return newState;
    });
  }

  const handleDelete = (id: number) => {
    apiHelper.makeCall(() => RuleApi.deleteRuleGroup(id))
      .then(r => {
        toastHelper.success('Rule Group Deleted!');
        setState(ps => {
          const newState: RuleGroupState = { ...ps, loadingMessage: undefined };
          newState.modal.open = false;
          const index = newState.ruleGroups.findIndex(e => e.id === id);
          if (index >= 0) newState.ruleGroups.splice(index, 1);
          return newState;
        });
      });
  }

  const handleUpdateSuccess = (updatedRuleGroup: RuleGroup) => {
    toastHelper.success(`${updatedRuleGroup.name} successfully updated`);
    setState(ps => {
      const newState = { ...ps };
      newState.modal.open = false;
      const index = newState.ruleGroups.findIndex(e => e.id === updatedRuleGroup.id);
      if (index >= 0) newState.ruleGroups[index] = updatedRuleGroup;
      return newState;
    });
  }

  return (
    <React.Fragment>
      <DataTable
        loading={state.loadingMessage !== undefined}
        pageOptions={{ itemsPerPage: 10 }}
        toolbarProps={{
          actionButtons: [
            { key: 'reload', icon: faSync, toolTip: 'Reload', onClick: () => reloadAndSetState() },
            { key: 'add', icon: faPlus, toolTip: 'Add Rule Group', onClick: () => handleAddRuleGroupClick() }
          ]
        }}
        data={!state.ruleGroups.length ? [] : [...state.ruleGroups]}
        dataIdProperty='id'
        columns={[
          { key: 'id', header: 'Id' },
          { key: 'name', header: 'Name', searchable: true },
          { key: 'description', header: 'Description', searchable: true },
        ]}
        actionsFunc={getRowActions}
      />
      <AddRuleGroupModal
        allRuleGroups={state.ruleGroups}
        open={state.modal.type === 'add' && state.modal.open}
        onSubmitSuccess={handleAddSuccess}
        onClose={() => setState((ps) => ({ ...ps, modal: { ...ps.modal, open: false } }))}
      />
      <UpdateRuleGroupModal
        open={state.modal.type === 'update' && state.modal.open}
        onSubmitSuccess={handleUpdateSuccess}
        ruleGroup={state.modal.ruleGroup!}
        allRuleGroups={state.ruleGroups}
        onClose={() => setState((ps) => ({ ...ps, modal: { ...ps.modal, open: false } }))}
      />

    </React.Fragment>
  );
}

export default DashboardPage(WithApiHelper(RuleGroups));
