import React, { useContext, useState, useEffect } from 'react';
import { ToastProvider } from 'react-toast-notifications'
import AzureAD, { IAzureADFunctionProps, AuthenticationState, IAccountInfo } from 'react-aad-msal';
import isEqual from 'react-fast-compare'
import { Route, RouteComponentProps } from 'react-router';
import { Switch } from 'react-router-dom';
import { AuthError } from 'msal';
import { get } from 'js-cookie';

import { authProvider, authParams } from '../auth'
import Dashboard from './dashboard';
import ErrorPage from './error';
import { BlockUi, StandardPage, Button } from './shared';
import * as UserApi from '../api/user';
import { CurrentUser } from '../types/user-types';
import { cypressTestCookieName } from '../settings';

interface AppContextGetterProps {
  rawAccountInfo?: IAccountInfo
  user?: CurrentUser
  loadingMessage?: string
  cypressTest: boolean
}

export interface AppContextProps extends AppContextGetterProps {
  reloadUser: () => Promise<CurrentUser | undefined>
  logout: () => void
}

const AppContext = React.createContext<AppContextProps>({
  rawAccountInfo: undefined,
  user: undefined,
  loadingMessage: undefined,
  cypressTest: false,
  reloadUser: () => Promise.resolve({} as any),
  logout: () => { },
});

export const useAppContext = () => useContext(AppContext);

const App: React.FC<RouteComponentProps> = () => {

  const [state, setState] = useState<AppContextGetterProps>({
    user: undefined,
    loadingMessage: undefined,
    cypressTest: get(cypressTestCookieName) ? true : false
  });

  const updateRawAccountInfo = (rawAccountInfo?: IAccountInfo) => {

    if (!rawAccountInfo) {
      setState((ps) => ({ ...ps, rawAccountInfo: undefined, user: undefined }));
    } else {
      setState((ps) => ({ ...ps, rawAccountInfo: rawAccountInfo }));
    }
  }

  const handleError = (error: AuthError | null, authState: AuthenticationState) => {
    if (!error) return;
    console.log('error during authentication', error, authState); // eslint-disable-line no-console
    if (error.errorCode === 'multiple_matching_tokens') {
      authProvider.acquireTokenSilent(authParams);
    }
    if (error.errorCode === 'invalid_state_error') {
      //TODO FIND FIX??
    }
  }

  const silentlyReloadUser = () => {
    if (!state.rawAccountInfo) return Promise.resolve(undefined);
    return UserApi.me()
      .then((response) => {
        setState((ps) => ({ ...ps, user: response.data }));
        return response.data;
      });
  }

  const reloadUser = () => {
    setState((ps) => ({ ...ps, loadingMessage: 'Loading User...' }));
    return UserApi.me()
      .then((response) => {
        setState((ps) => ({ ...ps, user: response.data, loadingMessage: undefined }));
        return response.data;
      })
      .catch((error) => {
        setState((ps) => ({ ...ps, loadingMessage: undefined }));
        return undefined;
      });
  }

  useEffect(() => {
    if (state.rawAccountInfo) {
      reloadUser();
    }
  }, [state.rawAccountInfo]);

  useEffect(() => {
    if (state.cypressTest) {
      reloadUser();
    }
  }, []);


  return (
    <ToastProvider autoDismissTimeout={3000} placement='bottom-right'>
      <div id='app'>
        <div className='page'>
          <AzureAD forceLogin={false} provider={authProvider}>
            {
              ({ login, logout, authenticationState, error, accountInfo }: IAzureADFunctionProps) => {

                handleError(error, authenticationState);
                if (!isEqual(state.rawAccountInfo, accountInfo)) {
                  updateRawAccountInfo(accountInfo!);
                }

                const showLoading = state.loadingMessage ? true : false;
                const showAuthenticated = authenticationState === AuthenticationState.Authenticated || state.cypressTest;
                const showUnauthenticated = authenticationState === AuthenticationState.Unauthenticated && !state.cypressTest;
                const showInProgress = authenticationState === AuthenticationState.InProgress && !state.cypressTest;

                return (
                  <AppContext.Provider
                    value={{
                      user: state.user,
                      rawAccountInfo: state.rawAccountInfo,
                      logout: logout,
                      reloadUser: silentlyReloadUser,
                      loadingMessage: state.loadingMessage,
                      cypressTest: state.cypressTest
                    }}
                  >
                    <Route path={`/error`} component={ErrorPage} />
                    {showLoading && <BlockUi blocking text={state.loadingMessage} className='full-page-loading' />}
                    {!showLoading && (
                      <React.Fragment>
                        {showAuthenticated && (
                          <React.Fragment>
                            {!state.user && (
                              <StandardPage
                                image='Oops'
                                title={'Dashies'}
                                description={'An error occured getting user details'}
                              >
                                <Button onClick={() => login()}>Get User Details</Button>
                              </StandardPage>
                            )}
                            {state.user && (
                              <Switch>
                                <Route path='/dashboard' component={Dashboard} />
                                <Route path='/' component={Dashboard} exact />
                              </Switch>
                            )}
                          </React.Fragment>

                        )}
                        {showUnauthenticated && (
                          <StandardPage
                            image={error ? 'Oops' : 'Logo'}
                            title={'Dashies'}
                            description={error ? 'An error occured during authentication, please try again!' : undefined}
                          >
                            <Button onClick={() => login()}>Login</Button>
                          </StandardPage>
                        )}
                        {showInProgress && <BlockUi blocking className='full-page-loading' />}
                      </React.Fragment>
                    )}
                  </AppContext.Provider>
                )
              }
            }
          </AzureAD>
        </div>
      </div>
    </ToastProvider>
  );
}

export default App;
