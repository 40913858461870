import React from 'react';
import { ModalSimple } from '../../../../shared';
import { RuleInstance, RuleInstanceOptions } from '../../../../../types/rule-types';
import UpdateRuleInstanceForm from './update-rule-instance-form'

interface UpdateRuleGroupModalProps {
  ruleInstance: RuleInstance
  options: RuleInstanceOptions
  onSubmitSuccess: (updateRuleInstance: RuleInstance) => void
  open: boolean
  onClose: () => void
}

const UpdateRuleGroupModal: React.FC<UpdateRuleGroupModalProps> = (props) => {

  const { open, onClose, ...rest } = props;

  if (!open) return null;

  return (
    <ModalSimple
      open
      onClose={onClose}
      header={`Update Rule Instance`}
    >

      <UpdateRuleInstanceForm
        hideHeader
        onBack={onClose}
        {...rest}
      />
    </ModalSimple>
  );
}

export default UpdateRuleGroupModal;
