import React from 'react';
import { DataTable } from '../../shared';
import * as DateHelper from '../../../helpers/date-helper';
import { RebuildProjectionJob } from '../../../types/projection-types';
import * as StringHelper from '../../../helpers/string-helper';
import * as DisplayHelper from '../../../helpers/display-helper';
import RenderHelper from '../../shared/table/data-table/helpers/data-table-render-helper';

interface ProjectionRebuildJobTablerops {
  onAdd: () => void
  onReload: () => void
  reloading?: boolean
  blockingMessage?: string
  jobs: RebuildProjectionJob[]
}

const ProjectionRebuildJobTable: React.FC<ProjectionRebuildJobTablerops> = ({ onAdd, onReload, reloading, blockingMessage, jobs }) => {

  return (
    <DataTable
      loading={reloading}
      blocking={blockingMessage !== undefined}
      blockingMessage={blockingMessage}
      small
      toolbarProps={{ onAdd: onAdd, onReload: onReload }}
      dataIdProperty='id'
      data={jobs}
      columns={[
        { key: 'id', header: 'Id', searchable: true },
        { key: 'definition', header: 'Type', filters: [{ type: 'StringContains' }], searchable: true, compareValue: (e) => StringHelper.addSpacesOnCaps(e.definition.type), renderCell: (e) => StringHelper.addSpacesOnCaps(e.definition.type) },
        { key: 'complete', header: 'Complete', searchable: true, compareValue: (r) => r.complete ? true : false, renderCell: (r) => RenderHelper.booleanByValue(r.complete) },
        { key: 'success', header: 'Success', searchable: true, compareValue: (r) => r.success ? true : false, renderCell: (r) => !r.complete ? '-' : RenderHelper.booleanByValue(r.success) },
        { key: 'secondsRuntime', header: 'Runtime', renderCell: (r) => DisplayHelper.seconds(r.secondsRuntime) },
        { key: 'createdAt', header: 'Date Created', compareValue: (e) => DateHelper.tryParseToMoment(e.createdAt), renderCell: (e) => DateHelper.formatDate(e.createdAt) },
      ]}
    />
  );
}

export default ProjectionRebuildJobTable;
