import React from 'react';

import { SideDrawer, SideBar } from '../shared';
import { SideBarLinkProps } from '../shared/side-bar/side-nav-link';


export interface DashboardNavBarProps {
  path: string
  links: SideBarLinkProps[]
  open: boolean
  mobileOpen: boolean
  onToggle: () => void
}

const DashboardNavBar: React.FC<DashboardNavBarProps> = ({ path, links, open, mobileOpen, onToggle }) => {

  return (
    <React.Fragment>
      <SideBar
        className='main-nav-bar'
        isOpen={open}
        links={links}
        headingImage={<img alt='logo' className='img-responsive' style={{ width: '50px' }} src='/images/logo.png' />}
        variant='dark'
      />
      <SideDrawer
        className='mobile-nav-bar'
        overlayClassName='mobile-nav-bar-overlay'
        position='left'
        onLinkClick={onToggle}
        open={mobileOpen}
        header={(
          <img alt='logo' className='img-responsive' style={{ width: '50px' }} src='/images/logo.png' />
        )}
        links={links}
        onClose={onToggle}
      />
    </React.Fragment>
  );
}

export default DashboardNavBar;
