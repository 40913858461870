import React from 'react';
import { FormikProps } from 'formik';
import { get, sumBy } from 'lodash';

import { FormikInput } from '../../../../shared';
import { nameOf } from '../../../../../helpers/object-helper';
import { FormValidatorField } from '../../../../shared/form/form-validator';
import { ProviderWeightingModel, UpdateBankWeightingsApiModel } from '../../../../../api/bank/api-models';
import { required, number, numberGreaterThanOrEqual, numberLessThanOrEqual } from '../../../../shared/form/validators';

interface BankWeightingFormFieldsProps {
  index: number
  formikProps: FormikProps<any>
}

const getFieldName = (name: keyof ProviderWeightingModel, index: number) => `${nameOf<UpdateBankWeightingsApiModel>('weightings')}[${index}].${name}`;

const allWeightingsAddUpTo100 = (value: any, values: UpdateBankWeightingsApiModel) => {
  const weightings = sumBy(values.weightings, (e) => e.weighting);
  return weightings !== 100 ? 'Ensure all weightings equal 100' : null;
}

export const getValidators = (index: number) => {
  const validators: FormValidatorField<any, any>[] = [
    { name: getFieldName('type', index), validators: [required()] },
    { name: getFieldName('weighting', index), validators: [required(), number(), numberLessThanOrEqual(100), numberGreaterThanOrEqual(0), allWeightingsAddUpTo100] }
  ];
  return validators;
};

const BankWeightingFormFields: React.FC<BankWeightingFormFieldsProps> = ({ index, formikProps }) => {

  return (
    <React.Fragment>
      <FormikInput
        type='number'
        label={`${get(formikProps.values, getFieldName('type', index))} Weighting`}
        name={getFieldName('weighting', index)}
        formikProps={formikProps}
      />
    </React.Fragment>
  );
}

export default BankWeightingFormFields;
