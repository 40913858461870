import React, { useState, useEffect } from "react";
import { RouteComponentProps } from "react-router";

import * as RoleApi from '../../../api/role';
import { UserRole } from "../../../types/user-types";
import ApiHelper from "../../../helpers/api-helper";
import ToastHelper from "../../../helpers/toast-helper";
import { useToasts } from "react-toast-notifications";
import RoleEditList from "./role-edit-list";
import WithAdminUser, { withAdminUserProps } from "../../hoc/with-admin-user";
import DashboardPage from "../../hoc/dashboard-page";

interface AssignRolesPageState {
  loadingMessage?: string
  submitting: boolean
  roles: UserRole[]
  editingRole?: UserRole
  editMode: boolean
}

interface UserRoleSetupPageProps extends RouteComponentProps, withAdminUserProps { }

const UserRoleSetupPage: React.FC<UserRoleSetupPageProps> = (props) => {

  const reloadMessage = 'Loading roles...';

  const [state, setState] = useState<AssignRolesPageState>({
    loadingMessage: reloadMessage,
    roles: [],
    editMode: false,
    submitting: false,
    editingRole: undefined
  });

  const toastHelper = new ToastHelper(useToasts());
  const apiHelper = new ApiHelper(toastHelper, { defaultOnErrorAction: () => setState((ps) => ({ ...ps, loadingMessage: undefined })) });

  const reload = () => {
    setState((ps) => ({ ...ps, loadingMessage: reloadMessage }));
    apiHelper.makeCall(() => RoleApi.list())
      .then((r) => setState((ps) => ({ ...ps, loadingMessage: undefined, roles: r.data })));
  }

  useEffect(() => {
    reload();
  }, []);

  return (
    <RoleEditList
      loading={state.loadingMessage !== undefined}
      roles={state.roles}
      onReload={reload}
      onUpdateSuccess={(role) => setState((ps) => ({ ...ps, editMode: false, roles: ps.roles.map(e => e.name === role.name ? role : e) }))}
      onEditClick={(role: UserRole) => setState({ ...state, editMode: true, editingRole: role })}
      editMode={state.editMode}
      editingRole={state.editingRole}
      onCancelEdit={() => setState({ ...state, editMode: false })}
    />
  )
};

export default WithAdminUser(DashboardPage(UserRoleSetupPage));
