import React from 'react';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

import { List, Button } from '../../../../../../../shared'
import { WordCategorisationModel } from '../../../../../../../../types/bank-types';
import WordListItemDisplay from './word-list-item-display';

export interface WordListProps {
  model: WordCategorisationModel[]
  onAdd: () => void
  onUpdate: (wordModel: WordCategorisationModel) => void
  onDelete: (id: string) => void
  disabled?: boolean
}

const WordList: React.FC<WordListProps> = (props) => {

  const { model, onAdd, onDelete, onUpdate, disabled } = props;

  if (!model?.length) {
    return <Button disabled={disabled} className='w-100' bsVariant='secondary' text='Add Word Or Phrase' small onClick={onAdd} />
  }

  return (
    <List<WordCategorisationModel>
      listItemKey='id'
      disabled={disabled}
      listItemBorderLeft
      divider
      onAdd={onAdd}
      addTooltip={'Add New Word or Phrase'}
      items={model}
      valueFunc={m => <WordListItemDisplay item={m} />}
      listItemsActionsFunc={(m) => [
        { icon: faEdit, toolTip: 'Update', onClick: () => onUpdate(m), variant: 'transparent', small: true },
        { icon: faTrash, toolTip: 'Delete', onClick: () => onDelete(m.id), variant: 'transparent', small: true }
      ]}
    />
  );
};

export default WordList;
