import Axios from 'axios';

import getSettings from '../../settings';
import { RebuildProjectionJob } from '../../types/projection-types';
import { ResultSet } from '../../types/shared-types';
import { AddRebuildProjectionJobModel } from './api-models';

const settings = getSettings();

export const relativeUrls = {
	list: `/jobs?OrderBy=DateCreated&OrderByDirection=desc`,
	add: '/jobs'
};

const root = `${settings.baseApiUrl}/admin/projection`;

export const list = () => {
	return Axios.get<ResultSet<RebuildProjectionJob>>(`${root}${relativeUrls.list}`);
};

export const add = (request: AddRebuildProjectionJobModel) => {
	return Axios.post<RebuildProjectionJob>(`${root}${relativeUrls.add}`, request);
};