import { Client } from '@microsoft/microsoft-graph-client';
import { UserAgentApplication } from 'msal';
import getSettings from '../settings';

const settings = getSettings();

const userAgentApplication = new UserAgentApplication({
  auth: {
    clientId: settings.authClientId,
    redirectUri: settings.appUrl
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true
  }
});

async function getClient() {
  const account = userAgentApplication.getAllAccounts()[0];
  const accessTokenRequest = {
    scopes: ['User.Read', 'User.ReadBasic.All'],
    account: account,
    authority: settings.authAuthority,
  };
  let accessToken: string = '';
  await userAgentApplication.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
    accessToken = accessTokenResponse.accessToken;
  }).catch((error) => {
    userAgentApplication.acquireTokenPopup(accessTokenRequest).then(function (accessTokenResponse) {
      // Acquire token interactive success
      accessToken = accessTokenResponse.accessToken;
    }).catch(function (error) {
      // Acquire token interactive failure
      console.log(error);
    });
    console.log(error);
  });

  return Client.init({
    authProvider: (done) => {
      done(null, accessToken);
    }
  });
}

// GET me/
export async function getUserDetails() {
  const client = await getClient();
  const user = await client.api('/me').get();
  return user;
}

// GET users/
export async function getAllUsersDetails() {
  const client = await getClient();
  const allusers = await client.api('/users').get();
  return allusers;
}

// GET users by matching search string with firstName, surname, displayName or UPN
export async function searchForPeople(searchText: string) {
  const client = await getClient();
  return await client.api('/users')
    .filter(`startsWith(givenName,'${searchText}') or startsWith(surname,'${searchText}') or startsWith(displayName, '${searchText}') or startsWith(userPrincipalName, '${searchText}')`)
    .select('displayName,givenName,surname,mail,userPrincipalName,id')
    .top(20)
    .get();
}

// GET me/people?$search={searchText}
export async function smartSearchForPeople(searchText: string) {
  const client = await getClient();
  if (searchText) {
    return await client.api('/me/people')
      .select('displayName,givenName,surname,scoredEmailAddresses,userPrincipalName,id')
      .top(20)
      .get();
  }
  else {
    return await client.api('/me/people')
      .search(`${searchText}`)
      .select('displayName,givenName,surname,scoredEmailAddresses,userPrincipalName,id')
      .top(20)
      .get();
  }
}

// GET groups?$filter=startsWith(displayName, '${searchText}')
export async function searchForGroups(searchText: string) {
  const client = await getClient();
  return await client.api('/groups')
    .filter(`startsWith(displayName,'${searchText}')`)
    .select('displayName,mail,id')
    .top(20)
    .get();
}
