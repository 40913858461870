import React from 'react';
import { Button, ButtonConfirm, CardSimple, Alert } from '../../../shared';

interface StartAssessmentCardProps {
  onStartAssessment: () => void
  assessmentId?: string
}

const StartAssessmentCard: React.FC<StartAssessmentCardProps> = ({ onStartAssessment, assessmentId }) => {

  return (
    <CardSimple>
      {assessmentId && (
        <Alert center variant='info'><span>Current Assessment: <strong>{assessmentId}</strong></span></Alert>
      )}
      {!assessmentId && <Button text='Start Assessment' onClick={onStartAssessment} />}
      {assessmentId && (
        <ButtonConfirm
          onConfirm={onStartAssessment}
          modalHeader='Are You Sure?'
          modalBodyText='This will reset your current assessment.  Are you sure you want to do this?'
          modalYesText='Start New Assessment'
          modalNoText='Cancel'
          text='Start new Assessment'
        />
      )}
    </CardSimple>
  )
}

export default StartAssessmentCard;