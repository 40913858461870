import React from 'react';
import { useToasts } from 'react-toast-notifications';
import { AxiosResponse } from 'axios';

import * as BankConsumerApi from '../../../../../api/bank-consumer';
import { LoginToBankResponse, LoginToBankRequest } from '../../../../../api/bank-consumer/api-models';
import { ConsumerBank } from '../../../../../types/bank-types';
import { Form } from "../../../../../types/form-types";
import { AutoForm } from '../../../../shared';
import ToastHelper from '../../../../../helpers/toast-helper';

import { Dictionary, ResultWrapper } from '../../../../../types/shared-types';
import { getFormDto } from '../../../../../helpers/form-helper';

interface MfaFormProps {
  bank: ConsumerBank
  form: Form
  bankProviderId: number
  token: string
  secondaryToken?: number
  reference: string
  onBack: () => void
  onBackToLogin: () => void
  onSuccess: (response: LoginToBankResponse) => void
  formKeys: string[]
}



const MfaForm: React.FC<MfaFormProps> = ({ bank, form, bankProviderId, token, secondaryToken, reference, onBack, onSuccess, formKeys, onBackToLogin }) => {
  const toastHelper = new ToastHelper(useToasts());
  const restartLoginRequired: boolean = secondaryToken !== null ? true : false; // Used to know if it needs to restart login
  const handleSubmit = (formValues: any) => {
    const mappedFormValues: Dictionary<string> = {};

    Object.keys(formValues).forEach((key) => {
      if (formKeys.some(e => e === key)) {
        mappedFormValues[key] = formValues[key];
      }
    });

    const request: LoginToBankRequest = {
      bankProviderId: bankProviderId,
      formValues: mappedFormValues,
      token: token,
      secondaryToken: secondaryToken,
      reference: reference
    };

    return BankConsumerApi.mfa(bank.id, request);
  };

  const handleSuccess = (response: AxiosResponse<ResultWrapper<LoginToBankResponse>>) => {
    if (response.data.success) {
      onSuccess(response.data.data!);
    } else {
      const error = response.data.error || 'An error occured'
      toastHelper.error(`${error} - Please login again`);
      if (restartLoginRequired)
        onBackToLogin();
    }
  }

  const formDto = getFormDto(form);
  return (
    <AutoForm
      variant='inside-card'
      submittingMessage='Logging in...'
      formId='bank-mfa'
      onSubmitPromise={handleSubmit}
      onSubmitSuccess={handleSuccess}
      onSubmitError={() => toastHelper.error('An error occured')}
      submitButtonText="Login"
      rows={formDto.rows}
      initialValues={formDto.initialValues}
      header={bank.name}
      headerCentre
      onBack={onBack}
    />
  );
};

export default MfaForm;
