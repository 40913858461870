import Axios from 'axios';

import getSettings from '../../settings';
import { AssessmentDetail, AssessmentSummary } from '../../types/assessment-types';
import { ResultSet } from '../../types/shared-types';

const settings = getSettings();

export const relativeUrls = {
	list: `/?OrderBy=DateCreated&OrderByDirection=desc&Take=50`,
	get: (id: string) => `/${id}`
};

const root = `${settings.baseApiUrl}/assessment`;

export const list = () => {
	return Axios.get<ResultSet<AssessmentSummary>>(`${root}${relativeUrls.list}`);
};

export const get = (id: string) => {
	return Axios.get<AssessmentDetail>(`${root}${relativeUrls.get(id)}`)
		.then(r => {
			r.data.assessmentId = id;
			return r;
		});
};