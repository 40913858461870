import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { orderBy } from 'lodash';

import DashboardPage from '../../../../hoc/dashboard-page';
import WithApiHelper, { WithApiHelperProps } from '../../../../hoc/with-api-helper';
import * as BankCategorisationApi from '../../../../../api/bank-categorisation';
import { BankingWordCategorisationInstanceGroup } from '../../../../../types/bank-types';
import InstanceGroupTable from './instance-group-table';
import InstanceGroupUpdateModal from './instance-group-update-modal';
import { nameOf } from '../../../../../helpers/object-helper';

interface BankingCategorisationProps extends WithApiHelperProps, RouteComponentProps { }

interface ModalStateData {
  open: boolean
  type: 'addOrUpdate'
  data?: BankingWordCategorisationInstanceGroup
}

interface State {
  reloading: boolean
  blockingMessage?: string
  groups: BankingWordCategorisationInstanceGroup[]
  modal: ModalStateData
}

const BankingCategorisation: React.FC<BankingCategorisationProps> = ({ apiHelper, toastHelper }) => {

  const [state, setState] = useState<State>({
    reloading: false,
    blockingMessage: undefined,
    groups: [],
    modal: { open: false, type: 'addOrUpdate' }
  });

  apiHelper.setDefaultOnErrorAction(() => setState(ps => ({ ...ps, reloading: false, blockingMessage: undefined })));

  const reload = () => {
    setState((ps) => ({ ...ps, reloading: true }));
    apiHelper.makeCall(() => BankCategorisationApi.listWordInstanceGroups({ orderBy: nameOf<BankingWordCategorisationInstanceGroup>('order') }))
      .then(response => setState((ps) => ({ ...ps, reloading: false, groups: response.data.results })))
  };

  //On Init
  useEffect(() => reload(), []);

  const handleAddOrUpdateSuccess = (group: BankingWordCategorisationInstanceGroup) => {
    const isUpdate = state.modal.data ? true : false;
    toastHelper.success(isUpdate ? 'Keyword Instance Updated!' : 'Keyword Instance Added!');
    setState(ps => {
      const newState: State = { ...ps };
      newState.modal.open = false;
      if (!isUpdate) {
        newState.groups.push(group);
      } else {
        const index = newState.groups.findIndex(e => e.id === group.id);
        if (index >= 0) newState.groups[index] = group;
      }
      newState.groups = orderBy(newState.groups, e => e.order);
      return newState;
    });
  };

  const handleDelete = (id: number) => {
    setState(ps => ({ ...ps, blockingMessage: 'Deleting Keyword Instance Group...' }));
    apiHelper.makeCall(() => BankCategorisationApi.deleteWordInstanceGroup(id))
      .then(() => {
        toastHelper.success('Keyword Instance Deleted');
        setState((ps) => {
          const newState: State = { ...ps, blockingMessage: undefined };
          const index = newState.groups.findIndex(e => e.id === id);
          if (index >= 0) newState.groups.splice(index, 1);
          return newState;
        });
      });
  };

  return (
    <React.Fragment>
      <InstanceGroupTable
        groups={state.groups}
        loading={state.reloading}
        blockingMessage={state.blockingMessage}
        onAdd={() => setState(ps => ({ ...ps, modal: { open: true, type: 'addOrUpdate', data: undefined } }))}
        onDelete={handleDelete}
        onEdit={(i) => setState((ps) => ({ ...ps, modal: { type: 'addOrUpdate', data: i, open: true } }))}
        onReload={reload}
      />
      {state.modal.type === 'addOrUpdate' && (
        <InstanceGroupUpdateModal
          group={state.modal.data}
          onSubmitSuccess={handleAddOrUpdateSuccess}
          onClose={() => setState(ps => ({ ...ps, modal: { ...ps.modal, open: false } }))}
          open={state.modal.open}
        />
      )}
    </React.Fragment>
  );
}

export default DashboardPage(WithApiHelper(BankingCategorisation));
