import React from 'react';
import {useToasts} from 'react-toast-notifications';
import * as Helpers from './create-user-form-helpers';

import * as BankConsumerApi from '../../../../../api/bank-consumer';
import {
    CreateObUserRequest, ObAccountStatus
} from '../../../../../api/bank-consumer/api-models';
import {AutoForm} from '../../../../shared';
import ToastHelper from '../../../../../helpers/toast-helper';
import {Dictionary} from '../../../../../types/shared-types';
import {ConsumerBank, OpenBankingData} from "../../../../../types/bank-types";
import ApiHelper from "../../../../../helpers/api-helper";

interface OpenBankingCreateUserProps {
    fortunaId: number | undefined
    setFortunaId: (fortunaId: number) => void
    bank: ConsumerBank
    onSuccess: (response: OpenBankingData) => void
    onBack: () => void
}

const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

const OpenBankingFlow: React.FC<OpenBankingCreateUserProps> = ({fortunaId, setFortunaId, bank, onSuccess, onBack}) => {

    const toastHelper = new ToastHelper(useToasts());
    const apiHelper = new ApiHelper(toastHelper);
    const handleCreateObUser = async (formValues: any) => {

        const mappedFormValues: Dictionary<string> = {};
        Object.keys(formValues).forEach(key => {
            mappedFormValues[key] = formValues[key];
        });

        const request: CreateObUserRequest = {
            mobileNumber: mappedFormValues['mobileNumber'],
            email: mappedFormValues['email']
        };

        const openBankingData: OpenBankingData = {} as OpenBankingData

        if (fortunaId === undefined) {
            let createUserResponse = await apiHelper.makeCall(() => BankConsumerApi.createUser(request))

            if (!createUserResponse.data.success) {
                toastHelper.error('An error occurred creating the Open Banking user')
                return
            }

            fortunaId = createUserResponse.data.data!.fortunaUserId
            setFortunaId(fortunaId)
        }

        let getConsentUrlResponse = await apiHelper.makeCall(() => BankConsumerApi.getObConsentUrl(fortunaId as number, bank.id.toString()))

        if (!getConsentUrlResponse.data.success) {
            toastHelper.error('An error occurred retrieving a consent URL')
            return
        }

        if (window.confirm("You are about to be redirected to Basiq to complete the Open Banking consent process")) {
            const consentWindow = window.open(getConsentUrlResponse.data.data!.consentUrl)
            const hasPopupBlocker = !consentWindow || consentWindow.closed || typeof consentWindow.closed === 'undefined';
            if (hasPopupBlocker) {
                toastHelper.error('Please disable your popup blocker and try again')
                return
            }
        } else {
            toastHelper.error('You must complete the consent process to continue')
            return
        }

        let accountStatus = 'NotStarted' as ObAccountStatus;

        await sleep(15000)

        while (accountStatus === 'NotStarted' || accountStatus === 'InProgress') {
            await sleep(5000);
            let getAccountsResponse = await apiHelper.makeCall(() => BankConsumerApi.getObAccounts(fortunaId as number))
            accountStatus = getAccountsResponse.data.data!.status

            if (accountStatus === 'Failed') {
                toastHelper.error('An error occurred retrieving the accounts.')
                return
            }
        }
        let getAccountsResponse = await apiHelper.makeCall(() => BankConsumerApi.getObAccounts(fortunaId as number))

        openBankingData.accounts = getAccountsResponse.data.data!.bankAccounts

        return openBankingData
    };

    return (
        // Email address and mobile number form
        <AutoForm
            variant='inside-card'
            submittingMessage='Creating Open Banking User'
            formId='ob-user-form'
            // @ts-ignore
            onSubmitPromise={handleCreateObUser}
            onSubmitSuccess={onSuccess}
            onSubmitError={() => toastHelper.error('An error occurred creating the Open Banking user.')}
            submitButtonText={'Create User'}
            onBack={onBack}
            rows={Helpers.getAutoFormRows()}
            headerCentre
            header={'Create Open Banking User'}
        />
    );
}


export default OpenBankingFlow;