import axios from 'axios';
import getSettings from '../../settings';
import { ResultWrapper } from '../../types/shared-types';
import { LoginToCentrelinkRequest, LoginToCentrelinkResponse, GenerateCisPdfCommand } from './api-models';
import { downloadUrl } from '../../helpers/file-helper';

const settings = getSettings();

export const relativeUrls = {
	login: `/login`,
	mfa: '/mfa',
	statement: '/download-statement',
	generateCisPdf: `/generate-cis-pdf`,
};

const root = `${settings.baseApiUrl}/centrelink`;

export const login = (request: LoginToCentrelinkRequest) => {
	return axios.post<ResultWrapper<LoginToCentrelinkResponse>>(`${root}${relativeUrls.login}`, request);
};

export const mfa = (request: LoginToCentrelinkRequest) => {
	return axios.post<ResultWrapper<LoginToCentrelinkResponse>>(`${root}${relativeUrls.mfa}`, request);
};

export const downloadStatement = (request: LoginToCentrelinkRequest) => {
	return downloadUrl({
		type: 'POST',
		url: `${root}${relativeUrls.statement}`,
		postBody: request,
		suggestedFileName: 'statement.pdf'
    });
};

export const generateAndDownloadCisPdf = (request: GenerateCisPdfCommand) => {
	return downloadUrl({
		type: 'POST',
		url: `${root}${relativeUrls.generateCisPdf}`,
		postBody: request,
		suggestedFileName: 'CIS.pdf'
	});
};
