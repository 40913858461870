import React from 'react';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

import { DataTable } from '../../../../shared';
import { BankingWordCategorisationInstanceGroup } from '../../../../../types/bank-types';

interface InstanceGroupTableProps {
  groups: BankingWordCategorisationInstanceGroup[]
  onDelete: (id: number) => void
  onAdd: () => void
  onEdit: (instance: BankingWordCategorisationInstanceGroup) => void
  onReload: () => void
  loading: boolean
  blockingMessage?: string
}

const InstanceGroupTable: React.FC<InstanceGroupTableProps> = (props) => {

  const { groups, onDelete, onEdit, onReload, onAdd, loading, blockingMessage } = props;

  return (
    <DataTable
      small
      loading={loading}
      blocking={blockingMessage !== undefined}
      blockingMessage={blockingMessage}
      toolbarProps={{
        onAdd: onAdd,
        onReload: onReload,
      }}
      data={!groups ? [] : [...groups]}
      dataIdProperty='id'
      columns={[
        { key: 'name', header: 'Name', searchable: true, noOrderBy: true },
        { key: 'order', header: 'Order', noOrderBy: true },
      ]}
      actions={[
        {
          icon: faEdit,
          variant: 'transparent',
          onClick: onEdit,
          tooltip: 'Update'
        },
        {
          icon: faTrash,
          variant: 'transparent',
          onClick: (i) => onDelete(i.id),
          tooltip: 'Delete',
          options: { isConfirm: true, modalHeader: 'Are You Sure?', modalBodyText: 'Are you sure you want to delete this instance group?', modalYesText: 'Delete', modalNoText: 'Cancel' }
        }
      ]}
    />
  );
}

export default InstanceGroupTable;
