import React, { useState } from 'react';
import * as CentrelinkConsumerApi from '../../../../../../api/centrelink-consumer';

import { LoginToCentrelinkRequest, LoginToCentrelinkResponse, CentrelinkServiceTypes, CentrelinkServiceType } from '../../../../../../api/centrelink-consumer/api-models';
import { FormSelect, FormValidator, Form, BlockUi, Button, FormikSideEffects, FormikSelect, FormikInput } from '../../../../../shared';
import { toOptionModels, nameOf } from '../../../../../../helpers/object-helper';
import { required } from '../../../../../shared/form/validators';
import { Formik, FormikProps } from 'formik';
import WithApiHelper, { WithApiHelperProps } from '../../../../../hoc/with-api-helper';

interface CentrelinkLoginModel {
  username: string
  password: string
  type: CentrelinkServiceType
}

interface TestLoginOption {
  name: string
  username: string
  password: string
}

const testLoginOptions: TestLoginOption[] = [
  { name: 'Normal Login', username: '555555555Z', password: 'ExampleMyGovAccount' },
  { name: 'No Income Statement', username: '444444444U', password: 'ExampleNoIncomeStatement' },
  { name: 'No Payment History', username: '999999999V', password: 'ExampleNoPaymentHistoryAccount' },
  { name: 'Nominee Account', username: '777777777X', password: 'ExampleCentrelinkNomineeAccount' }
];

interface LoginFormProps extends WithApiHelperProps {
  onSuccess: (response: LoginToCentrelinkResponse) => void
}

interface State {
  submitting: boolean
  showTestOptions: boolean
}

const LoginForm: React.FC<LoginFormProps> = (props) => {
  const { onSuccess, apiHelper, toastHelper } = props;

  const [state, setState] = useState<State>({
    submitting: false,
    showTestOptions: true
  });

  apiHelper.setDefaultOnErrorAction(() => setState(ps => ({ ...ps, submitting: false })));

  const handleSubmit = (values: CentrelinkLoginModel) => {
    const request: LoginToCentrelinkRequest = {
      formValues: {
        [nameOf<CentrelinkLoginModel>('username')]: values.username,
        [nameOf<CentrelinkLoginModel>('password')]: values.password
      },
        type: values.type,
      };

    setState(ps => ({ ...ps, submitting: true }));
    apiHelper.makeCall(() => CentrelinkConsumerApi.login(request))
      .then((r) => {
        if (!r.data.success) {
          toastHelper.error(r.data.error || 'An error occured.  Please try again');
        } else {
          onSuccess(r.data.data!);
          }
          setState(ps => ({ ...ps, submitting: false, providerId: r.data.data?.providerId }));
      });
  }

  const handleTestChoice = (choice: string, formikProps: FormikProps<CentrelinkLoginModel>) => {
    if (!choice) return;
    const foundChoice = testLoginOptions.find(e => e.name === choice);
    if (!foundChoice) return;
    formikProps.setValues({ ...formikProps.values, username: foundChoice.username, password: foundChoice.password });
  }

  const validator = new FormValidator<CentrelinkLoginModel, LoginFormProps>({
    fields: [
      { name: 'type', validators: [required()] },
      { name: 'username', validators: [required()] },
      { name: 'password', validators: [required()] },
    ]
  });

  return (
    <Formik<CentrelinkLoginModel>
      initialValues={validator.getInitial({ type: 'Test' })}
      validate={(values) => validator.validate(values, props)}
      onSubmit={handleSubmit}
      render={(formikProps) => {
        return (
          <BlockUi blocking={state.submitting} text='Logging in...'>
            <Form variant='inside-card' id='centrelink-login' onSubmit={formikProps.handleSubmit}>
              <div className='d-flex justify-content-between mb-3'>
                <span className='h5'>Centrelink</span>
                {state.showTestOptions && (
                  <FormSelect
                    className='mb-0'
                    name='test-options'
                    includeEmptyOption
                    onChange={(e) => handleTestChoice(e.currentTarget.value, formikProps)}
                    options={toOptionModels(testLoginOptions.map(e => e.name), true)}
                  />
                )}
              </div>
              <FormikSelect<CentrelinkLoginModel>
                formikProps={formikProps}
                label='Type'
                name='type'
                options={toOptionModels(CentrelinkServiceTypes)}
                onChange={(e) => setState(ps => ({ ...ps, showTestOptions: e.currentTarget.value as CentrelinkServiceType === 'Test' }))}
              />
              <FormikInput<CentrelinkLoginModel> formikProps={formikProps} name='username' label='Username' />
              <FormikInput<CentrelinkLoginModel> formikProps={formikProps} name='password' label='Password' type='password' />
              <div>
                <Button className='w-100 mb-2' type='submit' bsVariant='primary'>Login</Button>
              </div>
            </Form>
            <FormikSideEffects focusInputOnSubmitFail />
          </BlockUi>
        )
      }}
    >
    </Formik>
  );
}

export default WithApiHelper(LoginForm);
