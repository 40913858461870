import React, { useState } from 'react';
import { Button, CardSimple, Alert, BlockUi } from '../../../../shared';
import { RouteComponentProps } from 'react-router-dom';
import { Container } from 'reactstrap';
import * as CentrelinkApi from '../../../../../api/centrelink-consumer';
import { GenerateCisPdfCommand } from '../../../../../api/centrelink-consumer/api-models';
import { CcCentrelinkStatementTransaction } from '../../../../../types/centrelink-types';
import WithApiHelper, { WithApiHelperProps } from '../../../../hoc/with-api-helper';

interface GenerateCisPdfProps extends RouteComponentProps, WithApiHelperProps {
}

interface State {
  loadingMessage?: string
}

const GenerateCisPdf: React.FC<GenerateCisPdfProps> = ({ apiHelper, toastHelper }) => {

  const [state, setState] = useState<State>({ loadingMessage: undefined });
  apiHelper.setDefaultOnErrorAction(() => setState(ps => ({ ...ps, loadingMessage: undefined })));

  const futureRegularBenefits: CcCentrelinkStatementTransaction[] = [
    { description: "Family Tax Benefit Part A", amount: 121.24, date: "2020-04-10T02:33:30.3317919Z" },
    { description: "Energy Supplement Part A", amount: 2.66, date: "2020-04-10T02:33:30.3317921Z" }
  ];

  const previousRegularBenefits: CcCentrelinkStatementTransaction[] = [
    { description: "Energy Supplement Part A", amount: 2.66, date: "2020-03-21T02:33:30.3315858Z" },
    { description: "Family Tax Benefit Part A", amount: 121.24, date: "2020-03-21T02:33:30.3317735Z" }
  ]

  const previousIrregularBenefits: CcCentrelinkStatementTransaction[] = [
    { description: "A one time payment of Schoolkids Bonus", amount: 215, date: "2019-02-15T02:33:30.3317838Z" }
  ]

  const dummyCommand: GenerateCisPdfCommand = {
    accountHolder: "Testy Tester",
    data: {
      crn: "111 222 333",
      extractionDate: "2020-04-10T02:33:30.3318897Z",
      futureEntitlements: {
        deductions: [],
        regularBenefits: futureRegularBenefits,
        irregularBenefits: []
      },
      previousEntitlements: {
        deductions: [],
        regularBenefits: previousRegularBenefits,
        irregularBenefits: previousIrregularBenefits
      }
    },
  };
  const handleDownload = () => {
    setState(ps => ({ ...ps, loadingMessage: 'Downloading Pdf...' }));
    apiHelper.makeCall(() => CentrelinkApi.generateAndDownloadCisPdf(dummyCommand))
      .then(e => {
        setState(ps => ({ ...ps, loadingMessage: undefined }));
        toastHelper.success('Pdf downloaded Successfully!');
      });
  }

  return (
    <Container>
      <BlockUi blocking={state.loadingMessage ? true : false} text={state.loadingMessage}>
        <CardSimple>
          <Alert message='Testing Generate Pdf Api using dummy Data' center variant='info' />
          <Button text='Generate Pdf' onClick={handleDownload} />
        </CardSimple>
      </BlockUi>
    </Container>

  )
}

export default WithApiHelper(GenerateCisPdf);