import React from 'react';

interface CashiesSpinnerProps extends React.HTMLProps<HTMLDivElement> {
  text?: string
};

const CashiesSpinner: React.FC<CashiesSpinnerProps> = ({ text = 'Please wait...' }) => {
  return (
    <div className="cashies-spinner-wrapper" key="loader">
      <div className="cashies-spinner">
        <img src='/images/cashies-spinner.svg' alt="Loading" />
        <div className="cashies-spinner-text">{text}</div>
      </div>
    </div>
  );
}


export default CashiesSpinner;