import React from 'react';
import { AutoForm } from '../../../../shared';
import { Bank } from '../../../../../types/bank-types';
import { removeDuplicatesMultiple } from '../../../../../helpers/array-helper';
import { OptionModel } from '../../../../../types/shared-types';
import * as BankApi from '../../../../../api/bank';
import { MergeBanksApiModel } from '../../../../../api/bank/api-models';

interface MergeBanksFormProps {
  bank1: Bank
  bank2: Bank
  onSubmitSuccess: (mergedBank: Bank, removedBankId: number) => void
  onBack: () => void
  hideHeader?: boolean
}

const MergeBanksForm: React.FC<MergeBanksFormProps> = (props) => {

  const { bank1, bank2, onSubmitSuccess, hideHeader, onBack } = props;

  const initialValues: MergeBanksApiModel = {
    bankId: bank1.id,
    bankIdToRemove: bank2.id,
    name: bank1.name,
    aliases: removeDuplicatesMultiple(bank1.aliases, bank2.aliases),
    providerIds: removeDuplicatesMultiple(bank1.providers.map(e => e.id), bank2.providers.map(e => e.id)),
    obProviderIds: removeDuplicatesMultiple(bank1.obProviders.map(e => e.id), bank2.obProviders.map(e => e.id)),
  };

  const getBankDisplay = (bank: Bank) => {
    const parts: string[] = [bank.name];
    if (bank.providers && bank.providers.length) parts.push(bank.providers.map(e => e.type).join(', '));
    if (bank.obProviders && bank.obProviders.length) parts.push(bank.obProviders.map(e => e.type).join(', '));
    return parts.join(' - ');
  }

  const getNameOptions = () => {
    const names: OptionModel<string>[] = [
      { label: bank1.name, value: bank1.name },
      { label: bank2.name, value: bank2.name },
    ];
    return names;
  }

  return (
    <AutoForm
      header={!hideHeader ? undefined : 'Merge Banks'}
      initialValues={initialValues}
      onSubmitPromise={(v) => BankApi.merge(v)}
      onSubmitSuccess={(r) => onSubmitSuccess(r.data, bank2.id)}
      onBack={onBack}
      rows={[
        {
          columns: [
            { extraSmallSize: 12, smallSize: 6, field: { type: 'Display', name: 'Bank1Display' as any, options: { defaultValue: () => getBankDisplay(bank1) } } },
            { extraSmallSize: 12, smallSize: 6, field: { type: 'Display', name: 'Bank2Display' as any, options: { defaultValue: () => getBankDisplay(bank2) } } },
          ]
        },
        { columns: [{ field: { name: 'name', display: 'Name', type: 'Select', options: { options: getNameOptions() } } }] },
        { columns: [{ field: { name: 'aliases', display: 'Aliases', type: 'TagInput', } }] },
      ]}
    />
  );
}

export default MergeBanksForm;
