import React from 'react';
import { ModalSimple } from '../../../../shared';
import { RuleGroup } from '../../../../../types/rule-types';
import AddRuleGroupForm from './add-rule-group-form'

interface AddRuleGroupModalProps {
  allRuleGroups: RuleGroup[]
  onSubmitSuccess: (newRuleGroup: RuleGroup) => void
  open: boolean
  onClose: () => void
}

const AddRuleGroupModal: React.FC<AddRuleGroupModalProps> = (props) => {

  const { allRuleGroups, open, onClose, ...rest } = props;

  if (!open) return null;

  return (
    <ModalSimple
      open
      onClose={onClose}
      header={`Add Rule Group`}
    >
      <AddRuleGroupForm
        allRuleGroups={allRuleGroups}
        hideHeader
        {...rest}
        onBack={onClose}
      />
    </ModalSimple>
  );
}

export default AddRuleGroupModal;
