import {
    AutoFormRow,
    AutoFormColumn,
} from "../../../../shared/form/auto-form/auto-form-types";

export const getAutoFormRows = () => {
    const rows: AutoFormRow[] = [];
    const mobileColumn: AutoFormColumn[] = [];
    const emailColumn: AutoFormColumn[] = [];

    mobileColumn.push({
        field: {
            name: 'mobileNumber',
            display: 'Mobile Number',
            type: 'TextInput',
            options: {
                validators: undefined, // Add regex mobile number validation (must have international code)
                options: undefined,
                imageData: undefined
            }
        }
    });

    emailColumn.push({
        field: {
            name: 'email',
            display: 'Email',
            type: 'TextInput',
            options: {
                validators: undefined, // Add regex email validation
                options: undefined,
                imageData: undefined
            }
        }
    });

    rows.push({columns: mobileColumn});
    rows.push({columns: emailColumn});

    return rows;
};