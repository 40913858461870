import React, {useState} from 'react';
import {v4} from 'uuid';

import SearchForm from './search-form';
import LoginForm from './login-form';
import MfaForm from './mfa-form';
import BankAccountList from './bank-account-list';
import BankStatements from './bank-statements';

import {ConsumerBank, BankAccount, BankStatementAccount, CategorisedBankAccount} from '../../../../types/bank-types';
import {Form} from "../../../../types/form-types";
import {
    DownloadBankStatementsRequest,
    LoginToBankResponse,
    CategoriseAndAnalyseTransactionsApiCommand
} from '../../../../api/bank-consumer/api-models';
import WithApiHelper, {WithApiHelperProps} from '../../../hoc/with-api-helper';
import BlockUi from '../../../shared/block-ui';
import * as BankApi from '../../../../api/bank-consumer';
import DashboardPage from '../../../hoc/dashboard-page';
import {CardSimple} from '../../../shared';
import OpenBankingFlow from "./open-banking-flow";
import axios from "axios";
import {Alert, Button} from "reactstrap";

interface BankingProps extends WithApiHelperProps {
}

interface SelectedBankData {
    bank: ConsumerBank | null
    providerId: number | null
    accounts: BankAccount[] | null
    userToken: string | null
    rawStatements: BankStatementAccount[] | null
    categorisedStatements: CategorisedBankAccount[] | null
    mfa: Form | null,
    secondaryToken: number | null
}

interface BankLoginState {
    selectedBankData: SelectedBankData
    search: string
    loadingMessage?: string
    mfaFormKeys: string[]
    reference: string,
    fortunaId: number | undefined
}

const getEmptySelectedBankData = () => {
    const empty: SelectedBankData = {
        bank: null, mfa: null, providerId: null, accounts: null, userToken: null,
        rawStatements: null, secondaryToken: null, categorisedStatements: null
    };
    return empty;
};

function isUsingV3() {
    return axios.defaults.headers.common['api-version'] === '3.0';
}

const Banking: React.FC<BankingProps> = ({apiHelper, toastHelper}) => {

    const [state, setState] = useState<BankLoginState>({
        loadingMessage: undefined,
        search: '',
        selectedBankData: {...getEmptySelectedBankData()},
        mfaFormKeys: [],
        reference: v4(),
        fortunaId: undefined
    });

    apiHelper.setDefaultOnErrorAction(() => setState((ps) => ({...ps, loadingMessage: undefined})));

    const handleBack = () => setState((ps) => ({...ps, search: '', selectedBankData: {...getEmptySelectedBankData()}}));
    const setFortunaId = (fortunaId: number) => setState((ps) => ({...ps, fortunaId: fortunaId}));

    const handleDownloadStatements = () => {
        if (!state.selectedBankData.bank || !state.selectedBankData.accounts) return;
        setState((ps) => ({...ps, loadingMessage: 'Downloading Statements...'}));

        const request: DownloadBankStatementsRequest = {
            bankProviderId: state.selectedBankData.providerId!,
            numberOfDays: 90,
            accounts: state.selectedBankData.accounts.map(a => a.id),
            token: state.selectedBankData.userToken!,
            reference: state.reference
        };

        apiHelper.makeCall(() => BankApi.downloadStatements(state.selectedBankData?.bank?.id!, request))
            .then((response) => {
                if (response.data.success) {
                    setState((ps) => ({
                        ...ps,
                        loadingMessage: undefined,
                        selectedBankData: {...ps.selectedBankData, rawStatements: response.data.data!.accounts}
                    }));
                } else {
                    toastHelper.error(response.data.error || 'An error occured.  Please try again');
                    setState((ps) => ({...ps, loadingMessage: undefined}));
                }
            });
    };

    const handleAnalyseStatements = () => {
        if (!state.selectedBankData.rawStatements) return;
        setState((ps) => ({...ps, loadingMessage: 'Analysing Statements...'}));
        const request: CategoriseAndAnalyseTransactionsApiCommand = {
            reference: state.reference,
            bankAccounts: state.selectedBankData.rawStatements
        };
        apiHelper.makeCall(() => BankApi.analyseBankStatements(request))
            .then(r => setState((ps) => ({
                ...ps,
                loadingMessage: undefined,
                selectedBankData: {...ps.selectedBankData, categorisedStatements: r.data.accounts}
            })));
    }

    const handleLoginSuccess = (r: LoginToBankResponse) => {
        setState((ps) => ({
            ...ps,
            mfaFormKeys: getFormKeys(r.mfa!),
            selectedBankData: {
                ...ps.selectedBankData,
                accounts: r?.accounts ?? [],
                mfa: r.mfa!,
                userToken: r.token,
                providerId: r.bankProviderId,
                secondaryToken: r.secondaryToken,
            }
        }));
    }

    const getFormKeys = (form: Form) => {
        const keys: string[] = [];
        if (!form?.rows) return keys;
        form.rows.forEach((r) => {
            if (r.columns) {
                r.columns.forEach(c => {
                    if (c?.field) {
                        if (c.field.name && c.field.name.length > 0 && c.field.type !== 'Display') {
                            keys.push(c.field.name);
                        }
                        if (c.field.subElements) {
                            c.field.subElements.forEach((sf) => {
                                keys.push(sf.name);
                            });
                        }
                    }
                });
            }
        });
        return keys;
    }

    const shouldRenderLogin = () => !!(state.selectedBankData.bank && !state.selectedBankData.mfa && !state.selectedBankData.accounts && !state.selectedBankData.bank.openBankingSupported);
    const shouldStartOpenBankingFlow = () => !!(isUsingV3() && state.selectedBankData.bank && !state.selectedBankData.rawStatements && state.selectedBankData.bank.openBankingSupported);
    const shouldShowIncorrectApiVersion = () => !!(!isUsingV3() && state.selectedBankData.bank && state.selectedBankData.bank.openBankingSupported && !state.selectedBankData.bank.providerIds);
    const shouldRenderMfa = () => !!(state.selectedBankData.bank && state.selectedBankData.mfa && state.selectedBankData.userToken && state.selectedBankData.providerId);
    const shouldRenderAccounts = () => !!(state.selectedBankData.bank && state.selectedBankData.accounts && !state.selectedBankData.mfa);
    const shouldRenderStatements = () => !!state.selectedBankData.rawStatements;

    return (
        <CardSimple container className='bank-login-container' extraPadding>
            <BlockUi blocking={!!state.loadingMessage} text={state.loadingMessage}>
                <SearchForm
                    bank={state.selectedBankData.bank}
                    searchValue={state.search}
                    onSearchValueChange={(v) => setState((ps) => ({...ps, search: v}))}
                    onSelect={(bank) => setState((ps) => ({
                        ...ps,
                        selectedBankData: {...ps.selectedBankData, bank: bank}
                    }))}
                    disabled={state.selectedBankData.bank !== null}
                />
                {shouldStartOpenBankingFlow() && (
                    <React.Fragment>
                        <hr/>
                        <OpenBankingFlow
                            fortunaId={state.fortunaId}
                            setFortunaId={setFortunaId}
                            bank={state.selectedBankData.bank!}
                            onSuccess={(response) => {
                                setState((ps) => ({
                                    ...ps,
                                    fortunaId: state.fortunaId,
                                    selectedBankData: {
                                        ...ps.selectedBankData,
                                        rawStatements: response.accounts != [] ? response.accounts : null
                                    }
                                }))
                            }}
                            onBack={handleBack}
                        />
                    </React.Fragment>
                )}
                {shouldShowIncorrectApiVersion() && (
                    <React.Fragment>
                        <Alert color='warning'> This bank requires the V3 API version. Change the API Version at the top
                            right of the screen and retry.</Alert>
                        <Button onClick={handleBack}>Back</Button>
                    </React.Fragment>
                )}
                {shouldRenderLogin() && (
                    <React.Fragment>
                        <hr/>
                        <LoginForm
                            bank={state.selectedBankData.bank!}
                            reference={state.reference!}
                            onBack={handleBack}
                            onSuccess={handleLoginSuccess}
                        />
                    </React.Fragment>
                )}
                {shouldRenderMfa() && (
                    <React.Fragment>
                        <hr/>
                        <MfaForm
                            formKeys={state.mfaFormKeys}
                            bank={state.selectedBankData.bank!}
                            token={state.selectedBankData.userToken!}
                            bankProviderId={state.selectedBankData.providerId!}
                            secondaryToken={state.selectedBankData.secondaryToken!}
                            reference={state.reference!}
                            form={state.selectedBankData.mfa!}
                            onBack={handleBack}
                            onSuccess={handleLoginSuccess}
                            onBackToLogin={() => setState(ps => ({
                                ...ps,
                                selectedBankData: {
                                    ...ps.selectedBankData,
                                    mfa: null,
                                    userToken: null,
                                    secondaryToken: null,
                                    accounts: null
                                }
                            }))}
                        />
                    </React.Fragment>
                )}
                {shouldRenderAccounts() && (
                    <React.Fragment>
                        <hr/>
                        <BankAccountList
                            bank={state.selectedBankData.bank!}
                            className='mt-3'
                            accounts={state.selectedBankData.accounts!}
                            onDownloadStatements={handleDownloadStatements}
                            onBack={handleBack}
                            downloadStatementsSuccess={shouldRenderStatements()}
                        />
                    </React.Fragment>
                )}
                {shouldRenderStatements() && (
                    <React.Fragment>
                        <hr/>
                        <BankStatements
                            rawAccounts={state.selectedBankData.rawStatements!}
                            categorisedAccounts={state.selectedBankData.categorisedStatements}
                            onBack={handleBack}
                            onCategoriseStatements={handleAnalyseStatements}
                        />
                    </React.Fragment>
                )}
            </BlockUi>
        </CardSimple>
    );
}

export default DashboardPage(WithApiHelper(Banking));