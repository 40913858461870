import React from 'react';

import { ModalSimple } from '../../../../../../../shared';
import UpdateForm from './update-form';
import { WordCategorisationModel } from '../../../../../../../../types/bank-types';

interface FormikKeywordInstancePredicateBuilderUpdateProps {
  onSubmit: (instance: WordCategorisationModel) => void
  onCancel: () => void
  data?: WordCategorisationModel
  open: boolean
}

const WordControlUpdateModal: React.FC<FormikKeywordInstancePredicateBuilderUpdateProps> = (props) => {

  const { data, onSubmit, onCancel, open } = props;

  if (!open) return null;

  return (
    <ModalSimple
      onClose={onCancel}
      open
      header={!data ? 'Add New Word Model' : 'Update Word Model'}
    >
      <UpdateForm
        onSubmit={onSubmit}
        onCancel={onCancel}
        value={data}
      />
    </ModalSimple>

  );
}

export default WordControlUpdateModal;
