import React from 'react';

import { ModalSimple } from '../../../shared';
import Form from './add-rebuild-job-form';
import { RebuildProjectionJob } from '../../../../types/projection-types';

interface UpdateBankModalProps {
  submitting?: boolean
  onSubmitSuccess: (bank: RebuildProjectionJob) => void
  open: boolean
  onClose: () => void
}

const UpdateBankModal: React.FC<UpdateBankModalProps> = (props) => {

  const { open, onClose, ...rest } = props;

  if (!open) return null;

  return (
    <ModalSimple open onClose={onClose} header='Add Rebuild Projection Job'>
      <Form
        hideHeader
        {...rest}
        onBack={onClose}
      />
    </ModalSimple>
  );
}

export default UpdateBankModal;
